module.exports = {
    name: 'data',
    path: '/data',
    component: () => import('@data/Data'),
    meta: { module: "data" },
    children: [
        {
            path: '/',
            name: 'data_home',
            component: () => import ('@data/views/DataHome'),
            meta: { module: 'data' },
            children: []
        },
        {
            path: '/data/:exchangeId/report',
            name: 'data_report',
            component: () => import ('@data/views/DataReport'),
            children: []
        }
    ]
};
