module.exports = {
    name: 'rail',
    path: '/rail',
    component: () => import('@rail/Rail.vue'),
    meta: { module: "rail" },
    children: [
        {
            path: '',
            name: 'rail_home',
            component: () => import ('@rail/views/Home.vue'),
            meta: { module: 'rail' }
        },
        {
            path: ':projectId',
            name: 'project',
            component: () => import ('@rail/views/ProjectPage.vue'),
            meta: { module: 'rail' },
            children: [
                {
                    path: 'view/:scenarioId',
                    name: 'project_view',
                    meta: { module: 'rail' }
                },
                {
                    path: 'edit/:scenarioId',
                    name: 'project_edit_economic',
                    meta: { module: 'rail' }
                },
                {
                    path: 'edit/:scenarioId',
                    name: 'project_edit_wear_choice',
                    meta: { module: 'rail' }
                }
            ]
        },
        {
            path: ':projectId/batch',
            name: 'batch_project',
            component: () => import ('@rail/views/BatchPage.vue'),
            meta: { module: 'rail' },
            children: []
        },
        {
            path: ':projectId/new/batch',
            name: 'new_project_batch',
            component: () => import ('@rail/views/NewBatchProject.vue'),
            meta: { module: 'rail' }
        },
        {
            path: ':projectId/new/economic',
            name: 'new_project_economic',
            component: () => import ('@rail/views/NewProjectEconomic.vue'),
            meta: { module: 'rail' }
        },
        {
            path: ':projectId/new/wear',
            name: 'new_project_wear',
            component: () => import ('@rail/views/NewProjectWear.vue'),
            meta: { module: 'rail' }
        },
        {
            path: ':projectId/add/economic',
            name: 'new_scenario_economic',
            component: () => import ('@rail/views/NewScenarioEconomic.vue'),
            meta: { module: 'rail' }
        },
        {
            path: ':projectId/add/wear',
            name: 'new_scenario_wear',
            component: () => import ('@rail/views/NewScenarioWear.vue'),
            meta: { module: 'rail' }
        },
        {
            path: 'admin/calculations',
            name: 'admin_calculations',
            component: () => import ('@rail/views/admin/CalculationLookup.vue'),
            meta: { module: 'rail' }
        }
    ]
};
