export default {
    state: {
        customerExchanges: [],
    },
    mutations: {
        setCustomerExchanges(state, customerExchanges) {
            if(customerExchanges) {
                state.customerExchanges = customerExchanges
            }
            else {
                state.customerExchanges = [];
            }
        },
    },
    getters: {
        getCustomerExchanges: state => {
            return state.customerExchanges
        },
    },
    actions: {
        /**
         * POST
         *  exchangeId String Unique Identifier for this upload
         *  digitalTwinId String Unique Identifier for the Virtual Rail asset this data is for
         *  sourceName String Origin the platform recieved the data (ex. vr_upload)
         *  dataType String Data type (ex Rail Profile)
         *  fileNames String List of files included in this upload
         * 
         * @returns {Object} { defaults {Array}, scenarios {Array} }
         */
        async createNewDataExchange(context, payload) {
            const requiredProps = ['platformMetadata','sourceName', 'fileNames']
            if (requiredProps.every(prop => prop in payload)) {
                const {platformMetadata, sourceName, fileNames} = payload;
                try {
                    const api = context.rootState.exchangeService.exchangeApi;
                    const {data = {}} = await api.post(`/exchange/`,
                        {platformMetadata, sourceName, fileNames}
                    );
                    return data;
                }
                catch(err) {
                    console.log('Data Infrastructure API Error: Failed createNewDataExchange', err);
                    throw new Error(err);
                }
            }
            else {
                console.log('Data Infrastructure Error: Missing required parameters.');
            }
        },
        /**
         * POST
         *  fileId Unique ID pre signed URL gave the file
         *  fileName Name of file that was uploaded
         * 
         * @returns {Object} { defaults {Array}, scenarios {Array} }
         */
        async createNewExchangeFile(context, payload) {
            const requiredProps = ['exchangeId', 'fileId', 'fileName']
            if (requiredProps.every(prop => prop in payload)) {
                const {exchangeId, fileId, fileName} = payload;
                try {
                    const api = context.rootState.exchangeService.exchangeApi;
                    const {data = {}} = await api.post(`/exchange/${exchangeId}/file`,
                        {fileId, fileName}
                    );
                    return data;
                }
                catch(err) {
                    console.log('Data Infrastructure API Error: Failed createNewExchangeFile', err);
                    throw new Error(err);
                }
            }
            else {
                console.log('Data Infrastructure Error: Missing required parameters.');
            }
        },
        /**
         * POST
         *  fileId Unique ID pre signed URL gave the file
         *  fileName Name of file that was uploaded
         * 
         * @returns {Object} { defaults {Array}, scenarios {Array} }
         */
        async getBanFile(context, payload) {
            const requiredProps = ["scac","line","track","mile","side", "version", "inspectionDate"]
                if (requiredProps.every(prop => prop in payload)) {
                const {scac,line,track,mile,side,version,inspectionDate} = payload;
                try {
                    const api = context.rootState.exchangeService.dataShareApi;
                    const {data = {}} = await api.post(`/rail/profile/ban/`,
                        {scac,line,track,mile,side,version,inspectionDate}
                    );
                    return data;
                }
                catch(err) {
                    console.log('Data Infrastructure API Error: Failed getBanFile', err);
                    throw new Error(err);
                }
            }
            else {
                console.log('Data Infrastructure Error: Missing required parameters.');
            }
        },
        /**
         * GET
         *  dataId Unique Identifier of the owner of the data
         * 
         * @returns {Object} { defaults {Array}, scenarios {Array} }
         */
        async getExchanges(context) {
            try {
                const api = context.rootState.exchangeService.exchangeApi;
                const customerId = context.rootGetters.getCurrentCustomerId;
                const {data = {}} = await api.get(`/exchange/${customerId}/`);
                context.commit('setCustomerExchanges', data);
            }
            catch(err) {
                console.log('Data Infrastructure API Error: Failed getExchanges', err);
                throw new Error(err);
            }
        },
    }
} 
