// Local Imports
import exchangeData from './exchangeData';
import exchangeIdentifier from './exchangeIdentifier';

export default {
    modules: {
        exchangeData,
        exchangeIdentifier
    },
    state: {
        exchangeApi: null,
        dataShareApi: null,
        customerSettings: {}
    },
    mutations: {
        exchangeApi(state, api) {
            state.exchangeApi = api;
        },
        dataShareApi(state, api) {
            state.dataShareApi = api;
        },
        setSettings(state, customerSettings) {
            state.customerSettings = customerSettings;
        }
    },
    getters: {
        getSettingFile: state => {
            return state.customerSettings?.file || []
        }
    },
    actions: {
        /**
         * Configures Axios API for Rail Service 
        */
        async initDataExchangeAgreementService(context) {
            // Create Axios API
            const baseURL =  process.env.VUE_APP_EXCHANGE_BASE || 'http://localhost:8001';
            const axiosApi = await context.dispatch('makeAPI', baseURL, {root: true});
            context.commit('exchangeApi', axiosApi);
        },
        /**
         * Configures Axios API for Rail Service 
        */
        async initDataShareService(context) {
            // Create Axios API
            const baseURL =  process.env.VUE_APP_DATASHARE_BASE || 'http://localhost:8001';
            const axiosApi = await context.dispatch('makeAPI', baseURL, {root: true});
            context.commit('dataShareApi', axiosApi);
        },
        /**
         * GET Configures Axios API for Rail Service 
        */
        async initCustomerSettings(context) {
            try {
                const api = context.rootState.exchangeService.exchangeApi;
                const customerId = context.rootGetters.getCurrentCustomerId;
                const {data = {}} = await api.get(`/customer/${customerId}/settings`);
                context.commit('setSettings', data);
            }
            catch(err) {
                console.log('Data Infrastructure API Error: Failed to get pre-signed url for wear upload', err);
                throw new Error(err);
            }
        },
        /**
         * GET Configures Axios API for Rail Service 
        */
        async updateCustomerSettings(context, payload) {
            const requiredProps = ['settingType','updateKey','updateValue']
            try {
                if (requiredProps.every(prop => prop in payload)) {
                    const {settingType, updateKey, updateValue} = payload;
                    const api = context.rootState.exchangeService.exchangeApi;
                    const customerId = context.rootGetters.getCurrentCustomerId;
                    const {data = {}} = await api.put(`/customer/${customerId}/settings`,
                        {settingType, updateKey, updateValue}
                    );
                    return data;
                }
                else {
                    throw new Error('Missing Required Parameters')
                }
            }
            catch(err) {
                console.log('Data Infrastructure API Error: Failed to update customer settings!', err);
                throw new Error(err);
            }
        },
        /**
         * POST 
         * @returns {Object} { defaults {Array}, scenarios {Array} }
         */
        async getExchangePreSignedURL(context, payload) {
            const requiredProps = ['contentType','fileName', 'exchangeId', 'platformMetadata']
            try {
                if (requiredProps.every(prop => prop in payload)) {
                    const {contentType, fileName, exchangeId, platformMetadata} = payload;
                    const {scac, line, track, mile, railWeight} = platformMetadata;
                    const api = context.rootState.exchangeService.exchangeApi;
                    const customerId = context.rootGetters.getCurrentCustomerId;
                    const {data = {}} = await api.post(`/exchange/preSignedURL`,
                        {contentType, fileName, customerId, exchangeId, scac, line, track, mile, railWeight}
                    );
                    return data;
                }
                else {
                    throw new Error('Missing Required Parameters')
                }
            }
            catch(err) {
                console.log('Data Infrastructure API Error: Failed to get pre-signed url for wear upload', err);
                throw new Error(err);
            }
        }
    }
}