export default {
    state: {},
    mutations: {},
    getters: {},
    actions: {
        /**
         * GET Comparison
         * @param {Object} payload
         *      {
         *          projectId {String} 
         *          comparisonId {String}
         *      } 
         * @returns {Object} comparison meta data
         */
        async getComparison(context, payload = {}) {
            const {projectId = null, comparisonId = null} = payload;
            try {
                if(!projectId || !comparisonId) throw new Error('Missing required parameters');

                const customerId = context.rootGetters.getCurrentCustomerId;
                const api = context.rootState.railService.railApi;
                const {data = {}} = await api.get(`customers/${customerId}/projects/${projectId}/comparison/${comparisonId}`);
                return data;
            }
            catch(err) {
                console.log('Rail Service API Error: Failed to get comparison', err);
                throw new Error(err);
            }
        },
        /**
         * GET Project Comparisons
         * @param {String} projectId
         * @returns {Array} list of comparisons associated with the projectId
         */
        async getComparisons(context, projectId = null) {
            try {
                if(!projectId) throw new Error('Missing required projectId');

                const customerId = context.rootGetters.getCurrentCustomerId;
                const api = context.rootState.railService.railApi;
                const {data = {}} = await api.get(
                    `customers/${customerId}/projects/${projectId}/comparison`
                );
                return data;
            }
            catch(err) {
                console.log('Rail Service API Error: Failed to get project comparisons', err);
                throw new Error(err);
            }
        },
        /**
         * PUT Update comparison's settings
         * @param {Object} payload
         *      {
         *          projectId {String} 
         *          currentComparisonId {String} 
         *          comparisonSettings {Object} 
         *      }
         * @returns {String} message
         */
            async updateComparisonSettings(context, payload = {}) {
            const {projectId = null, currentComparisonId = null, comparisonSettings = null} = payload;
            try {
                if(!projectId || !currentComparisonId || !comparisonSettings) throw new Error('Missing required parameters');

                const customerId = context.rootGetters.getCurrentCustomerId;
                const api = context.rootState.railService.railApi;
                const {data = {}} = await api.put(
                    `/customers/${customerId}/projects/${projectId}/comparison/${currentComparisonId}/settings`,
                    {settings: {wearWatchList: comparisonSettings}}
                );
                return data;
            }
            catch(err) {
                console.log('Rail Service API Error: Failed to update comparison settings', err);
                throw new Error(err);
            }
        },
        /**
         * POST Creates a new comparison from two existing scenarios
         * @param {Object} payload
         *      {
         *          projectId {String}
         *          scenarios {Array} 
         *          name {String} 
         *          (optional) settings {Object} 
         *      }
         * @returns {String} comparisonId
         */
            async createComparison(context, payload = {}) {
            const {projectId = null, scenarios = null, name = null, settings = {}} = payload;
            try {
                if(!projectId || !scenarios || !name) throw new Error('Missing required parameters');

                const customerId = context.rootGetters.getCurrentCustomerId;
                const api = context.rootState.railService.railApi;
                const {data = {}} = await api.post(
                    `customers/${customerId}/projects/${projectId}/comparison`,
                    {scenarios, name, settings}
                );
                return data;
            }
            catch(err) {
                console.log('Rail Service API Error: Failed to create comparison', err);
                throw new Error(err);
            }
        },
        /**
         * DELETE Comparison
         * @param {Object} payload
         *      {
         *          projectId {String}
         *          comparisonId {String}
         *      }
         * @returns {String} message
         */
        async deleteComparison(context, payload = {}) {
            const {projectId = null, comparisonId = null} = payload;
            try {
                if(!projectId || !comparisonId) throw new Error('Missing required parameters');

                const customerId = context.rootGetters.getCurrentCustomerId;
                const api = context.rootState.railService.railApi;
                const {data = {}} = await api.delete(
                    `customers/${customerId}/projects/${projectId}/comparison/${comparisonId}`
                );
                return data;
            }
            catch(err) {
                console.log('Rail Service API Error: Failed to delete comparison', err);
                throw new Error(err);
            }
        },
    }
}