import jwtDecode from 'jwt-decode';

/**
 * Helper functions used when authenticating user via vue-router
 */
export default {
    /**
     * Parses the cognitoUser token for auth modules
     * @param {Object} cognitoUser an authenticated cognitoUser
     * @returns {Array} list of modules user has access to
     */
    getUserModules(cognitoUser) {
        const overrides = ["dash", "profile"];
        let userModules = [];
        if(cognitoUser && cognitoUser.signInUserSession) {
            const {idToken: {payload = null}} = cognitoUser.signInUserSession;
            if(payload) {
                const {modules = []} = JSON.parse(payload['custom:auth']);
                userModules = modules;
            }
        }
        return [...overrides, ...userModules];
    },
    /**
     * Parses the cognitoUser token for auth landing page
     * @param {Object} cognitoUser an authenticated cognitoUser
     * @returns {String} landing page selection
     */
    getUserLandingPage(cognitoUser) {
        if(cognitoUser && cognitoUser.signInUserSession) {
            const {idToken: {payload = null}} = cognitoUser.signInUserSession;
            if(payload) {
                const {landingPageModuleKey = null} = JSON.parse(payload['custom:auth']);
                return (landingPageModuleKey === 'dash') ? null : landingPageModuleKey;
            }
        }
        return null;
    },
    /**
     * Gets auth tokens from local storage
     * @returns {Object} jwt tokens
     */
    getTokens() {
        const tokens = {};
        Object.keys(localStorage).forEach(k => {
            let keyArray = k.split('.');
            let lastIdx = keyArray.length - 1;
            let re = RegExp('.*Token');
            let match = re.exec(keyArray[lastIdx]);
            if (match) {
                tokens[match[0]] = localStorage[k];
            }
        });
        return tokens;
    },
    /**
     * Returns clientId from Access Token
     * @param {Object} tokens key/value list of jwt tokens
     * @returns {String} client id
     * @returns {Boolean} unable to find client id in token
     */
    getClientFromToken(tokens) {
        for(let token in tokens) {
            let tokenObj = null;
            if(token === 'accessToken') {
                tokenObj = jwtDecode(tokens[token]);
                if(tokenObj.client_id) {
                    return tokenObj.client_id;
                }
            }
        }
        return false;
    },
    /**
     * Returns clientId from Access Token
     * @param {Object} tokens key/value list of jwt tokens
     * @returns {String} client id
     * @returns {Boolean} unable to find client id in token
     */
    getAuthFromToken(tokens) {
        for(let token in tokens) {
            let tokenObj = null;
            if(token === 'idToken') {
                tokenObj = jwtDecode(tokens[token]);
                if(tokenObj['custom:auth']) {
                    return JSON.parse(tokenObj['custom:auth']);
                }
            }
        }
        return false;
    },
    /**
     * Checks router object for code id in query
     * @param {Object} router vue-router to parse query for code
     * @returns {Boolean} True if code found, False if not
     */
    routerHasCode(router) {
        if(router && router.query) {
            let {code = null} = router.query;
            if(code) {
                return true;
            }
            return false;
        }
        
        return false;
    },
    /**
     * Returns current environment and domain
     * @returns {Object} of current domain and environment
     */
    getLoginEnvironment() {
        let domain, env;
        const subDomain = process.env.VUE_APP_SUB_DOMAIN;
        switch(process.env.VUE_APP_ENV_NAME.toLowerCase()) {
            case 'local':
                domain = 'http://localhost:8080';
                env = 'dev';
                break;
            case 'dev':
                domain = `https://${subDomain}.loram.com`;
                env = 'dev';
                break;
            case 'qa':
                domain = `https://${subDomain}.loram.com`;
                env = 'stg';
                break;
            case 'prod':
                domain = `https://${subDomain}.loram.com`;
                env = 'prod';
                break;
        }
        return {domain, env};
    },
    /**
     * Returns config object for Amplify
     * @param {Boolean} isSSO determines what type of configuration to use
     * @param {Object} environment domain and env to use for configuration
     * @returns {Object} for Amplify configuration 
     */
    getAmplifyConfig(isSSO = false, {domain = 'http://localhost:8080', env = 'dev'}) {
        if (isSSO) {
            return {
                Auth: {
                    region:  process.env.VUE_APP_REGION,
                    userPoolId: process.env.VUE_APP_SAML_USER_POOL,
                    userPoolWebClientId: process.env.VUE_APP_SAML_CLIENT_ID,
                    oauth: {
                        domain: `rail-sso-${env}.auth.us-east-1.amazoncognito.com`.toLowerCase(),
                        scope: ['openid'],
                        redirectSignIn: `${domain}/login/`.toLowerCase(),
                        redirectSignOut: `${domain}/`.toLowerCase(),
                        responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
                    }
                }
            }
        } else {
            return {
                Auth: {
                    region: process.env.VUE_APP_REGION,
                    userPoolId: process.env.VUE_APP_USER_POOL_ID,
                    userPoolWebClientId: process.env.VUE_APP_USER_POOL_WEB_CLIENT_ID,
                    //identityPoolId: process.env.VUE_APP_IDENTITY_POOL_ID
                }
            };
        }
    },
}