import Vue from 'vue';

export default {
    state: {
        railAssetIdentifiers: {
            scac: [],
            line: [],
            track: []
        },
        railInspectionData: [],
        railInspections: {
            scac: [],
            line: [],
            track: [],
            mile: []
        }
    },
    mutations: {
        setSCAC(state, customerSCACs) {
            if (customerSCACs.length > 0) {
                let match = state.railAssetIdentifiers.scac.filter(scac => {
                    let unique = customerSCACs.filter(cs => cs == scac.id)
                    return {
                        "unique": (unique && unique.length > 0) ? false : true,
                        "scacId": scac
                    }
                })

                if(match.length) {
                    match.forEach(scacRes => {
                        if(scacRes.unique) {
                            state.railAssetIdentifiers.scac.push({
                                id: scacRes.scacId,
                                line: new Set()
                            })
                        }
                    })
                }
                else {
                    customerSCACs.forEach(scac => {
                        state.railAssetIdentifiers.scac.push({
                            id: scac,
                            line: new Set()
                        })
                    })
                }
            }
        },
        setLine(state, {parent: parentSCAC, lines: customerLines}) {
            if (customerLines.length) {
                let matches = customerLines.filter(cs => {
                    let match = state.railAssetIdentifiers.line.filter(sub => sub.id == cs)
                    if(match.length == 0) {
                        return match
                    }
                    else {
                        return null
                    }
                })

                // True, new unique items to add to array
                if(matches.length) {
                    matches.forEach(lineId => {
                        Object.values(state.railAssetIdentifiers.scac).forEach(scac => {
                            if(scac.id == parentSCAC) {
                                scac.line.add(lineId)
                            }
                        })
                    })

                    matches.map(lineId => {
                        state.railAssetIdentifiers.line.push({
                            id: lineId,
                            track: new Set()
                        })
                    })       
                }
            }
        },
        setTrack(state, {parent: parentLine, tracks: customerTracks}) {
            if (customerTracks.length) {
                let matches = customerTracks.filter(ct => {
                    let match = state.railAssetIdentifiers.track.filter(track => track.id == ct)
                    if(match.length == 0) {
                        return match
                    }
                    else {
                        return null
                    }
                })

                // True, new unique items to add to array
                if(matches.length) {
                    matches.forEach(trackId => {
                        Object.values(state.railAssetIdentifiers.line).forEach(line => {
                            if(line.id == parentLine) {
                                line.track.add(trackId)
                            }
                        })
                    })

                    matches.map(trackId => {
                        state.railAssetIdentifiers.track.push({
                            id: trackId,
                            mile: new Set()
                        })
                    })       
                }
            }
        },
        setMile(state, {parent: parentTrack, miles: customerMiles}) {
            if (customerMiles.length > 0) {
                const parent = state.railAssetIdentifiers.track.filter(t => t.id == parentTrack)
                if(parent && parent.mile) {
                    Object.values(state.railAssetIdentifiers.track).forEach(track => {
                        if(track.id == parentTrack) {
                            customerMiles.forEach(mile => track.mile.add(mile));
                        }
                    })
                }
                else {
                    Object.values(state.railAssetIdentifiers.track).forEach(track => {
                        if(track.id == parentTrack) {
                            customerMiles.forEach(mile => track.mile.add(mile));
                        }
                    })
                }
            }
        },
        setRailInspections(state, inspectionData = null) {
            if(inspectionData && inspectionData.length) {
                state.railInspectionData = inspectionData;
                const uniqueValues = {};
                inspectionData.forEach(obj => {
                    Object.keys(obj).forEach(key => {
                        if (!uniqueValues.hasOwnProperty(key)) {
                            uniqueValues[key] = [];
                        }
                        if (!uniqueValues[key].includes(obj[key])) {
                            uniqueValues[key].push(obj[key]);
                        }
                    });
                });

                Vue.set(state.railInspections, 'scac', uniqueValues['scac'] || []);
                Vue.set(state.railInspections, 'line', uniqueValues['line'] || []);
                Vue.set(state.railInspections, 'track', uniqueValues['track'] || []);
                Vue.set(state.railInspections, 'mile', uniqueValues['mile'] || []);
            }
            else {
                state.railInspectionData = [];
                state.railInspections = {
                    scac: [],
                    line: [],
                    track: [],
                    mile: []
                }
            }
        }
    },
    getters: {
        getAssetIdentifiers: state => {
            return state.railAssetIdentifiers
        },
        getLines: state => scac => { 
            let response = []
            let scacObj = state.railAssetIdentifiers.scac.filter(ra => ra.id == scac);
            if(scacObj.length) {
                let selectedScac = scacObj[0]
                response = [...selectedScac.line.values()]
            }
            return response
        },
        getTracks: state => line => { 
            let response = []
            let lineObj = state.railAssetIdentifiers.line.filter(ra => ra.id == line);
            if(lineObj.length) {
                let selectedLine = lineObj[0]
                response = [...selectedLine.track.values()]
            }
            return response
        },
        getMiles: state => track => { 
            let response = []
            let trackObj = state.railAssetIdentifiers.track.filter(ra => ra.id == track);
            if(trackObj.length) {
                let selectedTrack = trackObj[0]
                response = [...selectedTrack.mile.values()]
            }
            return response
        },
        getRailInspections(state) {
            return state.railInspections;
        },
        getRailInspectionData(state) {
            return state.railInspectionData;
        }
    },
    actions: {
        /**
         * POST Retrieves all customer rail inspections 
         * 
         * @returns {Array} list of rail_inspection_version records
         */
        async updateRailInspections(context, payload = {}) {
            try {
                const {scac = null, line = null, track = null, mile = null} = payload;
                const api = context.rootState.exchangeService.exchangeApi;
                const customerId = context.rootGetters.getCurrentCustomerId;
                const requestData = {scac: scac, line: line, track: track, mile: mile}
                const {data = []} = await api.post(`/identifier/${customerId}/inspections`, requestData);
                await context.commit('setRailInspections', data);
            }
            catch(err) {
                console.log('Data Exchange API Error: Failed to get customer SCAC', err);
                throw new Error(err);
            }
        },
        /**
         * GET Customer SCAC Assets
         * 
         * @returns {Array} list of scac id's associated with customerId
         */
        async getCustomerSCAC(context, payload) {
            try {
                const api = context.rootState.exchangeService.exchangeApi;
                const customerId = context.rootGetters.getCurrentCustomerId;
                const {data = []} = await api.get(`/identifier/${customerId}/scac`);
                context.commit('setSCAC', data);

                return data;
            }
            catch(err) {
                console.log('Data Exchange API Error: Failed to get customer SCAC', err);
                throw new Error(err);
            }
        },
        /**
         * GET Customer line Assets
         * 
         * @returns {Object} { scac {Array} }
         */
        async getCustomerLines(context, scac) {
            try {
                if(scac) {
                    const api = context.rootState.exchangeService.exchangeApi;
                    const customerId = context.rootGetters.getCurrentCustomerId;
                    const {data = []} = await api.get(`/identifier/${customerId}/scac/${scac}/line`);
                    context.commit('setLine', {parent: scac, lines: data});

                    return data;
                }
                else {
                    throw new Error('Missing Required Parameters')
                }
            }
            catch(err) {
                console.log('Data Exchange API Error: Failed to get customer lines', err);
                throw new Error(err);
            }
        },
        /**
         * GET Customer Track Assets
         * 
         * @returns {Object} { scac {Array} }
         */
        async getCustomerTracks(context, payload) {
            const requiredProps = ['scac','line']
            try {
                if (requiredProps.every(prop => prop in payload)) {
                    const {scac, line} = payload;
                    const api = context.rootState.exchangeService.exchangeApi;
                    const customerId = context.rootGetters.getCurrentCustomerId;
                    const {data = []} = await api.get(`/identifier/${customerId}/scac/${scac}/line/${line}/track`);
                    context.commit('setTrack', {parent: line, tracks: data});

                    return data;
                }
                else {
                    throw new Error('Missing Required Parameters')
                }
            }
            catch(err) {
                console.log('Data Exchange API Error: Failed to get customer tracks', err);
                throw new Error(err);
            }
        },
        /**
         * GET Customer Mile Markers for Scac/Sub/Track Assets
         * 
         * @returns {Object} { scac {Array} }
         */
        async getCustomerMiles(context, payload) {
            const requiredProps = ['scac','line','track']
            try {
                if (requiredProps.every(prop => prop in payload)) {
                    const {scac, line, track} = payload;
                    const api = context.rootState.exchangeService.exchangeApi;
                    const customerId = context.rootGetters.getCurrentCustomerId;
                    const {data = []} = await api.get(`/identifier/${customerId}/scac/${scac}/line/${line}/track/${track}/mile`);
                    context.commit('setMile', {parent: track, miles: data});

                    return data;
                }
                else {
                    throw new Error('Missing Required Parameters')
                }
            }
            catch(err) {
                console.log('Data Exchange API Error: Failed to get customer tracks', err);
                throw new Error(err);
            }
        },
    }
}