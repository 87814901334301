export default {
    state: {},
    mutations: {},
    getters: {},
    actions: {
        /**
         * GET Customer Default Inputs/Baseline
         * @returns {Object} { defaults {Array}, scenarios {Array} }
         */
         async getDefaultInputs(context) {
            try {
                const customerId = context.rootGetters.getCurrentCustomerId;
                const api = context.rootState.railService.railApi;
                const {data = {}} = await api.get(`/customers/${customerId}/baseline`);
                return data;
            }
            catch(err) {
                console.log('Rail Service API Error: Failed to get customers Default Inputs', err);
                throw new Error(err);
            }
        },
        /**
         * GET Baseline Input
         * @param {String} baselineId id of baseline to get
         * @returns {String} baseline
         */
         async getBaseline(context, baselineId = null) {
            try {
                if(!baselineId) throw new Error('Missing required parameter baselineId');

                const customerId = context.rootGetters.getCurrentCustomerId;
                const api = context.rootState.railService.railApi;
                const {data = {}} = await api.get(
                    `/customers/${customerId}/baseline/${baselineId}`
                );
                return data;
            }
            catch(err) {
                console.log('Rail Service API Error: Failed to get baseline', err);
                throw new Error(err);
            }
        },
        /**
         * GET Customer Settings
         * @returns {Object} Settings
         */
         async getCustomerSettings(context) {
            try {
                const customerId = context.rootGetters.getCurrentCustomerId;
                const api = context.rootState.railService.railApi;
                const {data = {}} = await api.get(`/customers/${customerId}/settings`);
                return data;
            }
            catch(err) {
                console.log('Rail Service API Error: Failed to get customer settings', err);
                throw new Error(err);
            }
        },
        /** 
         * POST Create a new customer record
         * @returns {Object} {customerId, vtiSimulations }
         */
         async createCustomerRecord(context) {
            try {
                const customerId = context.rootGetters.getCurrentCustomerId;
                const api = context.rootState.railService.railApi;
                const {data = {}} = await api.post(`/customers/${customerId}/newCustomerRecord`);
                return data;
            }
            catch(err) {
                console.log('Rail Service API Error: Failed to create new customer record.', err);
                throw new Error(err);
            }
        },
    }
}