import Vue from "vue";
import VueRouter from "vue-router";
import Auth from "@aws-amplify/auth";
import Amplify, { Hub } from '@aws-amplify/core';
// TODO: Refactor into User Management Package
import userUtil from "./../util/user.js";

Vue.use(VueRouter);

let req = require.context("@/modules/", true, /^\.\/[^/]+\/router\.js$/);

let moduleRoutes = req.keys().map(key => req(key));
const routes = [
	{
		path: "/",
		component: () => import("@dash/components/Layout/Construct"),
		meta: { requiresAuth: true },
		children: moduleRoutes
	},
	{
		path: "/login",
		component: () => import("@dash/components/Layout/Login"),
		beforeEnter: (to, from, next) => {
			if ((Object.values(Amplify.configure())).length) {
				Auth.currentAuthenticatedUser()
					.then(user => { next("/"); })
					.catch(err => { next(); });
			} else {
				next();
			}
		},
		children: [
			{
				path: "",
				component: () => import("@dash/components/Layout/Login/Credentials")
			},
			{
				path: "signup",
				component: () => import("@dash/components/Layout/Login/SignUp")
			},
			{
				path: "forgot",
				component: () => import("@dash/components/Layout/Login/ForgotPass")
			},
			{
				path: "singleSignOn",
				component: () => import("@dash/components/Layout/Login/SingleSignOn")
			},
			{
				path: "reset",
				component: () => import("@dash/components/Layout/Login/ResetPass")
			}
		]
	},
	{
		path: "/logout",
		beforeEnter: (to, from, next) => {
			Auth.signOut()
				.then(() => next("/login"))
				.catch(err => console.error(err));
		}
	},
	{
		path: "*",
		name: "notfound",
		component: () => import("@dash/components/Layout/NotFound"),
		meta: { requiresAuth: true }
	}
];

const router = new VueRouter({
	mode: "history",
	base: '/',
	routes
});

function bindHubListener() {
	// Used for SSO authentication, refreshes page once authenticated by oauth
	Hub.listen("auth", ({ payload: { event, data } }) => {
		switch (event) {
			case "signIn":
				Auth.currentAuthenticatedUser()
					.then(user => {
						const landingPageModuleKey = userUtil.getUserLandingPage(user);
						if(landingPageModuleKey) {							
							router.push({ path: `/${landingPageModuleKey}`})
						}
						else {
							router.push({ name: 'emptyPage' });
						}
					})
					.catch(err => {
						console.error(err)
					});
				break;
		}
	});
}

router.beforeEach((to, from, next) => {
	try {
		const tokens = userUtil.getTokens();
		let ampConfigured = (Object.values(Amplify.configure())).length > 0;

		// Amplify is NOT configured and user HAS jwt tokens
		if (!ampConfigured && Object.values(tokens).length) {
			// Determine if JWT is SSO then Configure Amplify 
			let clientId = userUtil.getClientFromToken(tokens);
			let isSSO = (clientId === process.env.VUE_APP_SAML_CLIENT_ID);
			let config = userUtil.getAmplifyConfig(isSSO, userUtil.getLoginEnvironment());
			Amplify.configure(config);
			ampConfigured = (Object.values(Amplify.configure())).length > 0;
		}
		// User is NOT logged in, but attempting to reset password
		else if (to.path.includes('forgot') || to.path.includes('reset')) {
			var config = userUtil.getAmplifyConfig(false, userUtil.getLoginEnvironment());
			Amplify.configure(config);
			ampConfigured = (Object.values(Amplify.configure())).length > 0;
		}
		// User HAS code in URL query parameters
		else if (userUtil.routerHasCode(to)) {
			var config = userUtil.getAmplifyConfig(true, userUtil.getLoginEnvironment());
			Amplify.configure(config);
			bindHubListener();
		}

		// Route requires authentication
		if (to.matched.some(record => record.meta.requiresAuth)) {
			// True if Amplify has been configured
			if (ampConfigured) {
				Auth.currentAuthenticatedUser().then(user => {
					// Check to make sure the user has permissions to this module
					const module = to.matched.find(record => record.meta.module);
					const userModules = userUtil.getUserModules(user);
					// Validate user has access to module
					if (module && userModules.indexOf(module.meta.module) === -1) {
						next('/');
					}
					else {
						next();
					}
				}).catch(err => {
					console.error(err);
					next("/login");
				});
			}
			// User is NOT logged in
			else {
				next("/login");
			}
		}
		// Route to does NOT require authentication
		else {
			next();
		}
	} catch (err) { console.log('Error logging in', err); }
});

export default router;
