import axios from "axios";

export default {
    state: {},
    mutations: {},
    getters: {},
    actions: {
        /**
         * (Loram Users ONLY) GET list of Loram users 
         * @returns {Object} List of users 
         */
         async getUsersLoram(context) {
            try {
                const api = context.rootState.userManagement.userApi;
                let {data = {}} = await api.get('/users');
                return data;
            }
            catch(err) {
                console.log('User Management API Error: Failed to get Loram user list', err);
                throw new Error(err);
            }
        },
        /**
         * (Loram Users ONLY) POST Create a new user within Cognito
         * @param {Object} payload 
         *      {
         *          email {String} user email address
         *          firstName {String} given name
         *          lastName {String} family name
         *          landingPageModuleKey {String} landing page user is redirected to upon login
         *          title {String} user title
         *          country {String} country user resides in
         *      }
         * @returns {Object} Created cognitoUser object
         */
         async createUserLoram(context, payload) {
            const {email = null, firstName = null, lastName = null,
                landingPageModuleKey = null, title = null, country = null} = payload;
            try {
                const api = context.rootState.userManagement.userApi;
                let {data = {}} = await api.post('/users',{
                    email,
                    firstName,
                    lastName,
                    auth: { role: 'loram', landingPageModuleKey },
                    meta: { title, country }
                });
                return data;
            }
            catch(err) {
                console.log('User Management API Error: Failed to create Loram user', err);
                throw new Error(err);
            }
        },
        /**
         * (Loram Users ONLY) PUT Updates user meta information
         * @param {Object} payload 
         *      {
         *          email {String} user email address
         *          firstName {String} given name
         *          lastName {String} family name
         *          landingPageModuleKey {String} landing page user is redirected to upon login
         *          title {String} user title
         *          country {String} country user resides in
         *      }
         * @returns {Boolean} True if success, throws error otherwise
         */
         async editUserLoram(context, payload) {
            const {email = null, firstName = null, lastName = null,
                landingPageModuleKey = null, title = null, country = null} = payload;
            try {
                const {username: userId = null} = context.rootGetters.getCognitoUser;
                const api = context.rootState.userManagement.userApi;
                await api.put(`/users/${userId}/`,{
                    email,
                    firstName,
                    lastName,
                    auth: { role: 'loram', landingPageModuleKey },
                    meta: { title, country }
                });
                return true;
            }
            catch(err) {
                console.log('User Management API Error: Failed to create user', err);
                throw new Error(err);
            }
        },
        /**
         * (Loram Users ONLY) POST Resend User Password
         * @param {String} email email address
         * @returns {Boolean} True if success, throws error otherwise
         */
         async resendInvite(context, email) {
            try {
                const api = context.rootState.userManagement.userApi;
                await api.post(`users/resend`, { email });
                return true;
            }
            catch(err) {
                console.log('User Management API Error: Failed resend invite email', err);
                throw new Error(err);
            }
        },
        /**
        * (un-authenticated) GET External Provider Names
        * @returns {Array} provider list
        */
        async getExternalProviders() {
            let options = [];
            try {
                const baseUrl = process.env.VUE_APP_UM_API_BASE || 'http://localhost:8080';
                const {data: { externalProviders = [] }} = await axios.get(`${baseUrl}/singleSignOn/externalProviders`);
                externalProviders.forEach(provider => {
                    options.push({value: provider, text: provider});
                });
                return options;
            } catch(err) {
                console.err(err);
                throw new Error('User Management API Error: fetching externalProviders')
            }
        },
        /**
        * GET Customers
        * @returns {Object} customer list
        */
        async getCustomers(context) {
            try {
                const api = context.rootState.userManagement.userApi;
                let {data = {}} = await api.get('/customers');
                return data;
            }
            catch(err) {
                console.log('User Management API Error: Failed to get customer list', err);
                throw new Error(err);
            }
        },
        /**
         * GET list of users
         * @returns {Object} List of users 
         */
         async getUsers(context) {
            try {
                const customerId = context.rootGetters.getCurrentCustomerId;
                const api = context.rootState.userManagement.userApi;
                let {data = {}} = await api.get(`/customers/${customerId}/users`);
                return data;
            }
            catch(err) {
                console.log('User Management API Error: Failed to get user list', err);
                throw new Error(err);
            }
        },
        /**
         * GET Users preferred MFA
         * @returns {Object} List of users 
         */
         async getMFAStatus(context, userId) {
            try {
                const customerId = context.rootGetters.getCurrentCustomerId;
                const api = context.rootState.userManagement.userApi;
                let {data: {UserMFASettingList = []}} = await api.get(`/customers/${customerId}/users/${userId}`);
                return UserMFASettingList;
            }
            catch(err) {
                console.log('User Management API Error: Failed to get user mfa status', err);
                throw new Error(err);
            }
        },
        /**
         * GET customer nist compliance state
         * @returns {Boolean} True if requires nist compliance confirmation, false otherwise
         */
         async getNistCompliance(context) {
            try {
                let customerId = null;
                let isLoram = false;
                const {attributes = null} = context.rootGetters.getCognitoUser;
                if(attributes) {
                    const auth = JSON.parse(attributes['custom:auth']);
                    customerId = auth.customerId || null;
                    isLoram = auth.role || 'loram';
                }

                if(customerId) {
                    const api = context.rootState.userManagement.userApi;
                    let {data: {showNistWarning = false}} = await api.get(`customers/${customerId}/nistComplianceState`);
                    return showNistWarning;
                }
                else if(isLoram) {
                    return true;
                }
                else {
                    return false;   
                }                
            }
            catch(err) {
                console.log('User Management API Error: Failed to get nist compliance', err);
                throw new Error(err);
            }
        },
        /**
         * PUT customer record
         * @param {Object} payload parameters to update within customer record
         *      {
         *          modules {Array}, 
         *          landingPage {String}, 
         *          nistWarning {Boolean}
         *       }
         * @returns {Boolean} True if successful, throws error otherwise
         */
         async editCustomer(context, payload) {
            const {modules = [], landingPage = null, nistWarning = false } = payload;
            try {
                const customerId = context.rootGetters.getCurrentCustomerId;
                const api = context.rootState.userManagement.userApi;
                let {data = {}} = await api.put(`/customers/${customerId}`,{
                    modules: modules,
                    landingPageModuleKey: landingPage,
                    showNistWarning: nistWarning
                });
                return data;
            }
            catch(err) {
                console.log('User Management API Error: Failed to edit customer', err);
                throw new Error(err);
            }
        },
        /**
         * PUT Updates user meta information
         * @param {Object} payload 
         *      {
         *          userId {String} cognito user id
         *          email {String} user email address
         *          firstName {String} given name
         *          lastName {String} family name
         *          role {String} user roles include: (admin || basic)
         *          title {String} user title
         *          country {String} country user resides in
         *      }
         * @returns {Boolean} True if successful, throws error otherwise
         */
        async editUser(context, payload) {
            const {userId = null, email = null, firstName = null, lastName = null, 
                role = 'basic', title = null, country = null} = payload;
            try {
                const customerId = context.rootGetters.getCurrentCustomerId;
                const api = context.rootState.userManagement.userApi;
                await api.put(`/customers/${customerId}/users/${userId}`,{
                    email,
                    customerId,
                    firstName,
                    lastName,
                    auth: { role, customerId },
                    meta: { title, country }
                });
                return true;
            }
            catch(err) {
                console.log('User Management API Error: Failed to update user', err);
                throw new Error(err);
            }
        },
        /**
         * PUT Disables user MFA
         * @param {String} userId cognito user id
         * @returns {Boolean} true upon success, throws error if failure
         */
        async disableMFA(context, userId) {
            try {
                const customerId = context.rootGetters.getCurrentCustomerId;
                const api = context.rootState.userManagement.userApi;
                await api.put(`/customers/${customerId}/users/${userId}/mfa`);
                await context.dispatch('refreshCognitoUser');
                return true;
            }
            catch(err) {
                console.log('User Management API Error: Failed to disable mfa', err);
                throw new Error(err);
            }
        },
        /**
        * POST Create new customer
        * @param {Object} payload default settings for new customer
        *     {
         *          name {String} cognito user id
         *          modules {Array} ids of modules customer has access to
         *          landingPage {String} id of module to use a landing page
         *          nistWarning {Boolean} if users should be acknowledge NIST warning when signing in
        *     }
        * @returns {Boolean} True, if successful, False on failure
        */
         async createCustomer(context, payload) {
            const {name = null, modules = [], landingPage = null, nistWarning = false} = payload;
            try {
                const api = context.rootState.userManagement.userApi;
                let response = await api.post('/customers',{
                    name,
                    modules,
                    landingPageModuleKey: landingPage,
                    showNistWarning: nistWarning
                });
                if(response.status === 201) {
                    return true;
                }
                else {
                    return false;
                }
            }
            catch(err) {
                console.log('User Management API Error: Failed to create new customer', err);
                throw new Error(err);
            }
        },
        /**
         * POST reset user password
         * @param {String} userId cognito user id
         * @returns {Boolean} True upon successful reset
         */
        async resetUserPassword(context, userId) {
            if(!userId) throw new Error('missing user id');
            try {
                const customerId = context.rootGetters.getCurrentCustomerId;
                const api = context.rootState.userManagement.userApi;
                await api.post(`/customers/${customerId}/users/${userId}/reset`, {});
                return true;
            }
            catch(err) {
                console.log('User Management API Error: Failed to reset user password', err);
                throw new Error(err);
            }
        },
        /**
         * POST Create a new user
         * @param {Object} payload 
         *      {
         *          email {String} user email address
         *          firstName {String} given name
         *          lastName {String} family name
         *          role {String} user roles include: loram | admin | basic
         *          landingPageModuleKey {String} landing page user is redirected to upon login
         *          title {String} user title
         *          country {String} country user resides in
         *      }
         * @returns {Object} True if successful, throws error otherwise
         */
         async createUser(context, payload) {
            const {email = null, firstName = null, lastName = null, role = 'basic',
                landingPageModuleKey = null, title = null, country = null} = payload;
            try {
                const customerId = context.rootGetters.getCurrentCustomerId;
                const api = context.rootState.userManagement.userApi;
                await api.post(`/customers/${customerId}/users`, {
                    email,
                    customerId,
                    firstName,
                    lastName,
                    auth: { role, landingPageModuleKey, customerId },
                    meta: { title, country }
                });
                return true;
            }
            catch(err) {
                console.log('User Management API Error: Failed to create user', err);
                throw new Error(err);
            }
        },
        /**
         * DELETE a user
         * @param {String} userId cognito user to delete
         * @returns {Boolean} True if user has been deleted
         */
         async deleteUser(context, userId = null) {
            try {
                const customerId = context.rootGetters.getCurrentCustomerId;
                const api = context.rootState.userManagement.userApi;
                await api.delete(`/customers/${customerId}/users/${userId}/`);
                return true;
            }
            catch(err) {
                console.log('User Management API Error: Failed to delete user', err);
                throw new Error(err);
            }
        },
    }
}