export const MODULE_INFO = {
  dash: {
    name: "Dashboard",
    icon: "tachometer-alt",
    url: "/"
  },
  rail: {
    name: 'Rail',
    icon: 'train',
    url: '/rail'
  },
  data: {
    name: 'Data Exchange',
    icon: 'database',
    url: '/data'
  },
};

export const MOBILE_MAX_WIDTH = 767;
