/* Logic to calculate baseline/scenario life using scenario data 
   (PLAT-94) wearLife & rcfLife is the “life (years)” for the high rail and low rail in the simulation added together and divided by 2 (the average life of both rails).
*/
import BudgetField from '@rail/util/budgetFields';
import Vue from "vue";

var debug = false;

function _calculateLife(shouldCalculate, scenario) {
    let type = 'wear';
    let value = null;
    let wearLife = null

    if(debug) console.log('[Rail Service] (calculate life) should calculate | ScenarioId | Annual MGT | Scenario Life :', shouldCalculate, scenario.scenarioId, scenario.economicData?.annualMgt, scenario.economicData?.scenarioLife );

    try {
        wearLife = scenario['economicData']['wearLife'];
    } catch(err) {
        wearLife = null
    }

    if(shouldCalculate == false) {
        return { type: type, value: (wearLife) ? wearLife.toFixed(2) : '--' };
    }

    let rcfValue = -999
    try {
        if( Object.keys(scenario).includes('wearMetaLeft') ) {
            let leftRCF = scenario.wearMetaLeft['rcf life']['value'] || -999
            let rightRCF = scenario.wearMetaRight['rcf life']['value'] || -999
            rcfValue = ((leftRCF + rightRCF) / 2); 
        }
    } catch(err) {
        if(debug) console.warn('Failed getting rcf life', err);
    }

    if(rcfValue < 0) {
        value = wearLife
    }
    else if(wearLife && wearLife < rcfValue) {
        value = wearLife
    }
    else {
        type = 'rcf'
        value = rcfValue
    }

    return { type: type, value: (value) ? value.toFixed(2) : 0.0 };
}

/* Main logic that generates economic input data and its expected structure for component use */
function getScenarioEconomicData(scenarioData, baselineScenario = null) {
    let data = {
        name: scenarioData.scenarioName || '',
        inputs: Object.assign({}, scenarioData.economicData) || {},
        budgetFields: null,
        toggle: null
    };

    if(debug) console.log('[Rail Service] (calculate budget field) scenario id | baseline id:', scenarioData?.scenarioId, baselineScenario?.scenarioId);

    const settings = scenarioData.settings || {};
    // Calculates Inputs from saved input data
    let budgetInputs = null;

    /*
    TODO: Determine if the "savedInputs" are ever used (this might be causing a miss calculation!)
    if(baselineScenario) {
        budgetInputs = new BudgetField({defaultInputs: baselineScenario.economicData, savedInputs: data.inputs, options: settings.economicOptions || {}});
    }
    else {
        budgetInputs = new BudgetField({defaultInputs: data.inputs, options: settings.economicOptions || {}});        
    } 
    //*/
    budgetInputs = new BudgetField({defaultInputs: data.inputs, options: settings.economicOptions || {}});

    // This populates the tabbed input's used with form
    data.budgetFields = budgetInputs.getTabs();

    if(data.inputs.straightLineDepreciation == 'Y') {
        data.toggle = 'expenses'
    } else {
        data.toggle = 'freecashflow'
    }

    return data;
}

export default {
    state: {
        projectId: null,
        scenarioId: null,
        scenarioData: [],
        baselineData: null,
        calculateLife: false,
        calculateBaselineLife: false,
        selectedEconomicModel: 'expenses',
        baselineLife: {
            type: 'wear',
            value: null
        },
        scenarioLife: {
            type: 'wear',
            value: null
        },
        count: 0
    },
    getters: {
        getEconomicModelType: state => {
            return state.selectedEconomicModel;
        },
        getEconomicData: state => {
            let newEconomicData = {}
            if(state.scenarioData.length) {
                //const [baselineScenario] = state.scenarioData.filter(s => s.baseline)
                state.scenarioData.forEach(sd => {
                    let currentScenario = Object.assign({}, sd);
                    let economicData = null;
                    economicData = getScenarioEconomicData(currentScenario);
                    economicData['settings'] = currentScenario['settings'];
                    newEconomicData[sd.scenarioId] = economicData
                });
                if (debug) console.log('[Rail Service] (Get Economic Data)', newEconomicData );
                return newEconomicData;
            }
            else {
                return newEconomicData;
            }
        },
        getEconomicDataSelected: state => {
            if (debug) console.log('[Rail Service] (Get Economic Selected)');
            if(state.scenarioData.length) {
                let dataValue = null;
                state.scenarioData.map(sd => {
                    if( state?.scenarioId && state.scenarioId === sd.scenarioId ) {
                        let currentScenario = Object.assign({}, sd);
                        let economicData = getScenarioEconomicData(currentScenario);
                        economicData['settings'] = currentScenario['settings'];
                        dataValue = economicData
                    }
                });

                return dataValue;
            }
            else {
                return null;
            }
        },
        getBaselineEconomicInputs: state => {
            let [baseline] = state.scenarioData.filter(sdata => sdata.baseline)
            let economicData = getScenarioEconomicData(baseline);
            if (debug) console.log('[Rail Service] (Get Economic Baseline)', economicData);
            return economicData;
        },
        getProjectId: state => {
            return state.projectId;
        },
        getScenarioId: state => {
            return state.scenarioId;
        },
        getScenarioData: state => {
            if (debug) console.log('[Rail Service] (Get Scenario Data) Baseline: ', state.scenarioData);

            let baseline = state.scenarioData.filter(data => data.baseline)
            let scenarios = state.scenarioData.filter(data => !data.baseline)
            return [...baseline, ...scenarios];
        },
        getBaselineData: state => {
            if(state.baselineData) {
                return {
                    id: state.baselineData.scenarioId,
                    inputs: state.baselineData.economicData
                }
            }
            else { 
                return {
                    id: null,
                    inputs: null
                }
            }
        },
        getScenarioLife: state => {
            return state.scenarioLife;
        },
        getBaselineLife: state => {
            return state.baselineLife;
        },
        getShouldCalculateLife: state => {
            return state.calculateLife;
        },
        getShouldCalculateBaselineLife: state => {
            return state.calculateBaselineLife;
        }
    },
    mutations: {
        setEconomicModelType(state, newModelState) {
            state.selectedEconomicModel = newModelState;
        },
        setCalculateLife(state, shouldCalculate) {
            state.calculateLife = shouldCalculate
        },
        setCalculateBaselineLife(state, shouldCalculate) {
            state.calculateBaselineLife = shouldCalculate
        },
        overwriteEconomicData(state, economicData) {
            if(Object.keys(economicData).length) {
                Object.keys(economicData).forEach(ecoKey => {
                    Vue.set( state.economicData, ecoKey, economicData[ecoKey] )
                })
            }
            else {
                state.economicData = {};
            }
        },
        setProjectId(state, projectId) {
            state.projectId = projectId;
        },
        setScenarioId(state, scenarioId) {
            state.scenarioId = scenarioId;
        },
        async setScenarioData(state, scenarioData) {
            if(scenarioData && scenarioData.length) {
                const newScenarioData = []
                const [baselineFirst] = scenarioData.filter(s => s.baseline)
                const sortedScenarioData = scenarioData.filter(s => !s.baseline)
                sortedScenarioData.splice(0, 0, baselineFirst)

                if (debug) console.log('[Rail Service] (Set Scenario Data) For each scenario calculate life & init economic data.');
                await Promise.all(sortedScenarioData.map(async data => {
                    let dataCopy = Object.assign({}, data)
                    const isBaseline = dataCopy.baseline;
                    let calcLife = false;
                    if(dataCopy.settings && 'lifeToggle' in dataCopy.settings) {
                        calcLife = dataCopy.settings.lifeToggle;
                    }
                    else {
                        calcLife = isBaseline ? state.calculateBaselineLife : state.calculateLife;
                    }

                    const newLife = _calculateLife(calcLife, dataCopy);
                    if (isBaseline) {
                        dataCopy['economicData']['scenarioLife'] = parseFloat(newLife?.value);
                        dataCopy['economicData']['baselineLife'] = parseFloat(newLife?.value);
                        state.baselineLife = newLife;
                    }
                    else {
                        dataCopy['economicData']['scenarioLife'] = parseFloat(newLife?.value);
                        dataCopy['economicData']['baselineLife'] = parseFloat(state.baselineLife?.value);
                        if(state.scenarioId === dataCopy.scenarioId) {
                            state.scenarioLife = newLife;
                        }
                    }
                    newScenarioData.push(dataCopy)
                }));

                const [baselineData] = newScenarioData.filter(s => s.baseline)
                state.baselineData = baselineData;
                state.scenarioData = newScenarioData;
                if (debug) console.log('[Rail Service] (Set Scenario Data) Finished');
            }
            else {
                state.baselineData = null;
                state.scenarioData = []
            }
        },
        async updateScenarioData(state, {scenarioId, scenarioData}) {
            let idxToUpdate = null;

            state.scenarioData.forEach((scenario, idx) => {
                if(scenario.scenarioId === scenarioId) {
                    idxToUpdate = idx;
                }
            });

            if (debug) console.log('[Rail Service] (Update Scenario Data) Found index | scenarioId:', idxToUpdate, scenarioId);

            if(idxToUpdate != null) {
                Vue.set(state.scenarioData, idxToUpdate, scenarioData)
            }
        },
        async refreshScenarioLife(state) {            
            if(state.scenarioData.length > 0) {
                if (debug) console.log('[Rail Service] (Refresh Scenario Life) For each scenario calculate life & init economic data.');

                await Promise.all(state.scenarioData.map(async (scenario, idx) => {
                    let calcLife = false;
                    let scenarioToRefresh = scenario;
                    if('lifeToggle' in scenarioToRefresh.settings) {
                        calcLife = scenarioToRefresh.settings.lifeToggle;
                    }
                    else {
                        calcLife = scenarioToRefresh.isBaseline ? state.calculateBaselineLife : state.calculateLife;
                    }
                    let newLife = _calculateLife(calcLife, scenarioToRefresh)

                    if (scenarioToRefresh.baseline) {
                        scenarioToRefresh['economicData']['scenarioLife'] = parseFloat(newLife?.value);
                        scenarioToRefresh['economicData']['baselineLife'] = parseFloat(newLife?.value);
                        state.baselineLife = newLife;
                    }
                    else {
                        scenarioToRefresh['economicData']['scenarioLife'] = parseFloat(newLife?.value);
                        scenarioToRefresh['economicData']['baselineLife'] = parseFloat(state.baselineLife?.value);
                        if(scenarioToRefresh.scenarioId === state.scenarioId) {
                            state.scenarioLife = newLife;
                        }
                    }

                    Vue.set( state.scenarioData, idx, scenarioToRefresh )
                }));
            }
            else {
                state.scenarioData = []
                state.baselineLife = {'type': 'wear', 'value': null };
                state.scenarioLife = {'type': 'wear', 'value': null };
            }

            if (debug) console.log('[Rail Service] (Refresh Scenario Life) Finished');
        },
        /* 
         * Updates a scenarios inputs based changed active inputs
         */
        modifyScenarioInput(state, {scenarioId, modifiedInputs}) {
            let scenarioIdx = -1
            state.scenarioData.map(async (scenario, idx) => {
                if(scenario.scenarioId === scenarioId) {
                    scenarioIdx = idx
                    scenario['economicData'] = {...scenario['economicData'], ...{[modifiedInputs['id']]: modifiedInputs['value']}}
                }
            });
        },
    },
    actions: {
        toggleEconomicModel(context, newModelState) {
            if (debug) console.log('[Rail Service] (Toggle Economic Model Type)', newModelState)
            context.commit('setEconomicModelType', newModelState)
        },
        /* 
         * Determines if baseline/scenario life should be calculated or not
        */
        toggleCalculateLife(context, shouldCalculate) {
            if (context.getters.getScenarioId) {
                if (debug) console.log('[Rail Service] (Toggle Calculate Life)', shouldCalculate);

                let currentScenarioData = context.getters.getScenarioData.filter(s => s.scenarioId == context.getters.getScenarioId)
                if(currentScenarioData && currentScenarioData.length) {
                    if(currentScenarioData[0].baseline) {
                        context.commit('setCalculateBaselineLife', shouldCalculate)
                    }
                    else {
                        context.commit('setCalculateLife', shouldCalculate)
                    }
                }
                context.commit('refreshScenarioLife', {})
            }
        },
        /* 
         * Sets the current scenarioId and Updates life
         */
        updateScenarioId(context, scenarioId = null) {
            if (debug) console.log('[Rail Service] (Update Scenario ID)', scenarioId);

            if(scenarioId) {
                context.commit('setScenarioId', scenarioId)
                context.commit('refreshScenarioLife')
            }
            else {
                context.commit('setScenarioId', null)
            }
        },
        /**
         * Sets the project Id, used to determine when a user navigates to a diffent project
         */
        initProjectId(context, projectId = null) {
            if (debug) console.log('[Rail Service] (Init Project ID)', projectId);

            if(projectId) {
                context.commit('setProjectId', projectId);
            }
        },
        /**
         * Saves the the settings data in store
         */
        setConfigData(context, {scenarioId = null, data = {}} = payload) {
            if (debug) console.log('[Rail Service] (Set Config Data)', payload);
            if(scenarioId && 'settings' in data) {
                context.commit('updateScenarioData', {scenarioId, scenarioData: data})
            }
        },
        /**
         * Saves the the economic data in store
         */
        overwriteEconomicData(context, economicData) {
            if (debug) console.log('[Rail Service] (Overwrite Economic Data)', payload);
            if(Object.values(economicData).length) {
                context.commit('overwriteEconomicData', economicData);
            }
        },
        /**
         * Saves the the economic data in store
         */
        setEconomicData(context, {scenarioId = null, data = {}} = payload) {
            if (debug) console.log('[Rail Service] (Set Economic Data)', payload);
            if(scenarioId) {
                context.commit('modifyScenarioInput', {scenarioId, modifiedInputs: data});
            }
        },
        /* 
         * Refreshes a scenario's data by updating state        
        */
        async updateSavedScenarioData(context, scenarioId) {
            if(scenarioId) {
                let [newData] = await context.dispatch('getScenario', {projectId: context.getters.getProjectId, scenarioId})
                context.commit('updateScenarioData', {scenarioId, scenarioData: newData});
            }
        },
        /* 
         * Updates EconomicData without invoking a change to the data
        */
        async updateScenarioInput(context, payload) {
            try {
                let {scenarioId = null, modifiedInputs = null} = payload
                if(!scenarioId || !modifiedInputs) throw new Error('Missing required parameters');

                if (debug) console.log('[Rail Service] (Update Scenario Input)', payload);

                let modifiedScenario = null;
                context.getters.getScenarioData.forEach(scenario => {
                    if(scenario.scenarioId == scenarioId) {
                        modifiedScenario = Object.assign({}, scenario);
                        modifiedScenario['economicData'] = {...scenario.economicData, ...{[modifiedInputs.id]: modifiedInputs.value}}
                    }
                });

                if (debug) console.log('[Rail Service] (Updating Input for Modified Scenario)', modifiedScenario);
                context.commit('updateScenarioData', {scenarioId, scenarioData: modifiedScenario});
            } catch(err) {
                console.error(`Unable to update Scenario with error: ${err?.message}`)
            }
        },
        async clearProjectData(context) {
            await context.commit('setProjectId', null);
            await context.commit('setScenarioId', null);
        },
        // ================= API Methods Below ===================
        /**
         * GET Scenario
         * @param {Object} payload
         *      {
         *          projectId {String}
         *          scenarioId {String}
         *      }
         * @returns {Object} scenario meta data
         */
         async getScenario(context, payload = {}) {
            const {projectId = null, scenarioId = null} = payload;
            try {
                if(!projectId || !scenarioId) throw new Error('Missing required parameter');

                const customerId = context.rootGetters.getCurrentCustomerId;
                const api = context.rootState.railService.railApi;
                const {data = {}} = await api.get(`/customers/${customerId}/projects/${projectId}/scenarios/${scenarioId}`);
                return data;
            }
            catch(err) {
                console.warn('Rail Service API Error: Failed to get scenario', err);
                throw new Error(err);
            }
        },
        /**
         * GET a list of scenarios for a project
         * @param {String} projectId 
         * @returns {Array} list of scenarios associated with the project
         */
         async getScenarios(context, projectId = null) {
            try {
                if(!projectId) throw new Error('Missing required parameter');

                if (debug) console.log('[Rail Service] (Get Scenarios)', projectId);

                const customerId = context.rootGetters.getCurrentCustomerId;
                const api = context.rootState.railService.railApi;
                const {data = {}} = await api.get(`/customers/${customerId}/projects/${projectId}/scenarios`);
                await context.commit('setScenarioData', data);
                return data;
            }
            catch(err) {
                console.warn('Rail Service API Error: Failed to get project scenarios', err);
                throw new Error(err);
            }
        },
        /**
         * GET scenario settings
         * @param {Object} payload
         *      {
         *          projectId {String}
         *          scenarioId {String}
         *      }
         * @returns {Object} settings for given scenario
         */
         async getScenarioSettings(context, payload = {}) {
            const {projectId = null, scenarioId = null} = payload;
            try {
                if(!projectId || !scenarioId) throw new Error('Missing required parameters');

                const customerId = context.rootGetters.getCurrentCustomerId;
                const api = context.rootState.railService.railApi;
                const {data = {}} = await api.get(
                    `customers/${customerId}/projects/${projectId}/scenarios/${scenarioId}/settings`
                );
                return data;
            }
            catch(err) {
                console.warn('Rail Service API Error: Failed to get scenarios settings', err);
                throw new Error(err);
            }
        },
        /**
         * GET Project Economic Model
         * @param {Object} payload the query parameters to get a project's economic data
         *     {
         *         projectId {String}
         *         scenarioId {String}
         *     }
         * @returns {Object} Economic data object
         */
         async getEconomicModel(context, payload = {}) {
            const {projectId = null, scenarioId = null} = payload;
            try {
                if(!projectId || !scenarioId) throw new Error('Missing required parameters');

                const customerId = context.rootGetters.getCurrentCustomerId;
                const api = context.rootState.railService.railApi;
                const {data = {}} = await api.get(`/customers/${customerId}/projects/${projectId}/scenarios/${scenarioId}/economic`);
                return data;
            }
            catch(err) {
                console.warn('Rail Service API Error: Failed to get economic model', err);
                throw new Error(err);
            }
        },
        /**
         * PUT Add/Update Economic Data
         * @param {Object} payload
         *      {
         *          projectId {String} 
         *          scenarioId {String} 
         *          economicData {Object} 
         *      }
         * @returns {String} economicData
         */
         async updateEconomicData(context, payload = {}) {
            const {projectId = null, scenarioId = null, economicData = null} = payload;
            try {
                if(!projectId || !scenarioId || !economicData) throw new Error('Missing required parameters');

                if (debug) console.log('[Rail Service] (Update Economic Data)', payload);

                const customerId = context.rootGetters.getCurrentCustomerId;
                const api = context.rootState.railService.railApi;
                const {data = {}} = await api.put(
                    `/customers/${customerId}/projects/${projectId}/scenarios/${scenarioId}/economic`,
                    { economicData }
                );
                return data;
            }
            catch(err) {
                const {response: {data = null}} = err;
                console.warn('Rail Service API Error: Failed to add/update economic data');
                if(data && data.message) {
                    const errors = new Error('Unable to add/update data');
                    errors['errors'] = data.message;
                    throw errors;
                }
                
                throw new Error(err);
            }
        },
        /**
         * PUT Update Scenario settings
         * @param {Object} payload
         *      {
         *          projectId {String} 
         *          scenarioId {String} 
         *          settings {Object}
         *      }
         * @returns {String} success message
         */
         async updateScenarioSettings(context, payload = {}) {
            const {projectId = null, scenarioId = null, settings = null} = payload;
            try {
                if(!projectId || !scenarioId || !settings) throw new Error('Missing required parameters');

                if (debug) console.log('[Rail Service] (Update Scenario Settings)', payload);

                const newSettings = settings['settings']
                const customerId = context.rootGetters.getCurrentCustomerId;
                const api = context.rootState.railService.railApi;
                const {data: {message = null}} = await api.put(
                    `/customers/${customerId}/projects/${projectId}/scenarios/${scenarioId}/settings`,
                    { settings: newSettings }
                );

                let modifiedScenario = null;
                context.getters.getScenarioData.forEach(scenario => {
                    if(scenario.scenarioId == scenarioId) {
                        modifiedScenario = Object.assign({}, scenario);
                        modifiedScenario['settings'] = {...scenario.settings, ...newSettings}
                    }
                });

                if(modifiedScenario) {
                    context.commit('updateScenarioData', {scenarioId, scenarioData: modifiedScenario})
                }
                if (debug) console.log('[Rail Service] (1) Finished Updating Scenario Data');
                
                if(newSettings && 'lifeToggle' in newSettings) {
                    context.commit('refreshScenarioLife')
                    if (debug) console.log('[Rail Service] (2) Finished Refreshing Life');
                }

                return message;
            } 
            catch(err) {
                console.warn('[Rail Service] (Update Scenario Settings) API Error:', err);
                throw new Error(err);
            }
        },
        /**
         * PUT Update Scenario settings
         * @param {Object} payload
         *      {
         *          projectId {String} 
         *          scenarioId {String} 
         *          settings {Object}
         *      }
         * @returns {String} success message
         */
         async updateNewSettings(context, payload = {}) {
            const {projectId = null, scenarioId = null, settings = null} = payload;
            try {
                if(!projectId || !scenarioId || !settings) throw new Error('Missing required parameters');

                const newSettings = settings['settings']
                const customerId = context.rootGetters.getCurrentCustomerId;
                const api = context.rootState.railService.railApi;
                const {data: {message = null}} = await api.put(
                    `/customers/${customerId}/projects/${projectId}/scenarios/${scenarioId}/settings`,
                    { settings: newSettings }
                );

                return message;
            }
            catch(err) {
                console.warn('Rail Service API Error: Failed to update scenario settings', err);
                throw new Error(err);
            }
        },
        /**
         * GET Batch Process Invoke
         * @param {Object} payload
         *      {
         *          projectId {String} 
         *          scenarioId {String} 
         *          settings {Object}
         *      }
         * @returns {String} success message
         */
         async processBatchConfig(context, payload = {}) {
            const {projectId = null, scenarioId = null, fileId = null} = payload;
            try {
                if(!projectId || !scenarioId || !fileId) throw new Error('Missing required parameters');

                const customerId = context.rootGetters.getCurrentCustomerId;
                const api = context.rootState.railService.railApi;
                const {data} = await api.post(
                    `/customers/${customerId}/projects/${projectId}/batch`,
                    {scenarioId, fileId}
                );
                return data;
            }
            catch(err) {
                console.warn('Rail Service API Error: Failed to invoke batch process', err);
                throw new Error(err);
            }
        },
        /**
         * PUT Update scenario's meta data
         * @param {Object} payload
         *      {
         *          projectId {String} 
         *          scenarioId {String} 
         *          (Optional) scenarioName {String} 
         *          (Optional) isBaseline {Boolean} 
         *          (Optional) isPreferred {Boolean}
         *          (Optional) incomplete {Boolean}
         *      }
         * @returns {Object} Updated data
         */
         async updateScenario(context, payload = {}) {
            const {projectId = null, scenarioId = null} = payload;
            const {scenarioName = null, isBaseline = null, isPreferred = null, incomplete = null} = payload;
            let dataToUpdate = {};
            try {
                if(!projectId || !scenarioId) throw new Error('Missing required parameters');

                if(scenarioName != null) {
                    dataToUpdate['scenarioName'] = scenarioName;
                }
                if(isBaseline !== null) {
                    dataToUpdate['isBaseline'] = isBaseline;
                }
                if(isPreferred !== null) {
                    dataToUpdate['isPreferred'] = isPreferred;
                }
                if(incomplete !== null) {
                    dataToUpdate['incomplete'] = incomplete;
                }
                if(Object.keys(dataToUpdate).length === 0) throw new Error('Missing one optional parameter');

                const customerId = context.rootGetters.getCurrentCustomerId;
                const api = context.rootState.railService.railApi;
                const {data = {}} = await api.put(
                    `/customers/${customerId}/projects/${projectId}/scenarios/${scenarioId}`,
                    dataToUpdate
                );
                return data;
            }
            catch(err) {
                console.warn('Rail Service API Error: Failed to update scenario', err);
                throw new Error(err);
            }
        },
        /**
         * POST Create Scenario
         * @param {Object} payload
         *      {
         *          projectId {String}
         *          scenarioName {String} 
         *          (Optional) isBaseline {Boolean} 
         *          (Optional) isPreferred {Boolean}
         *          (Optional) incomplete {Boolean}
         *      }
         * @returns {Object} {projectId, scenarioId}
         */
         async createScenario(context, payload = {}) {
            const {projectId = null, scenarioName = null} = payload;
            const {isBaseline = null, isPreferred = null, incomplete = null} = payload;
            const scenarioConfig = {};
            try {
                if(!projectId || !scenarioName) throw new Error('Missing required parameters');
                scenarioConfig['scenarioName'] = scenarioName;

                if(isBaseline !== null) {
                    scenarioConfig['isBaseline'] = isBaseline;
                }
                if(isPreferred !== null) {
                    scenarioConfig['isPreferred'] = isPreferred;
                }
                if(incomplete !== null) {
                    scenarioConfig['incomplete'] = incomplete;
                }

                const customerId = context.rootGetters.getCurrentCustomerId;
                const api = context.rootState.railService.railApi;
                const {data = {}} = await api.post(
                    `/customers/${customerId}/projects/${projectId}/scenarios`,
                    scenarioConfig
                );
                return data;
            }
            catch(err) {
                console.warn('Rail Service API Error: Failed to create new scenario', err);
                throw new Error(err);
            }
        },
        /**
         * POST Copy Scenario
         * @param {Object} payload 
         *      { 
         *          projectId {String}
         *          scenarioId {String}
         *          scenarioName {String}
         *      }
         * @returns {String} scenarioId
         */
         async copyScenario(context, payload = {}) {
            const {projectId = null, scenarioId = null, scenarioName = null} = payload;

            try {
                if(!projectId || !scenarioId || !scenarioName) throw new Error('Missing required parameters');

                const customerId = context.rootGetters.getCurrentCustomerId;
                const api = context.rootState.railService.railApi;
                const {data = {}} = await api.post(
                    `customers/${customerId}/projects/${projectId}/scenarios/${scenarioId}/copy`,
                    {scenarioName}
                );
                return data;
            }
            catch(err) {
                console.warn('Rail Service API Error: Failed to copy scenario', err);
                throw new Error(err);
            }
        },
        /**
         * POST Get Presigned URL For a Batch Project
         * @param {Object} payload 
         *      { 
         *          projectId {String}
         *          scenarioId {String}
         *          scenarioName {String}
         *      }
         * @returns {String} scenarioId
         */
         async getBatchPreSignedURL(context, payload = {}) {
            const {contentType = null, projectId = null} = payload;

            try {
                if(!contentType || !projectId) throw new Error('Missing required parameters');

                const customerId = context.rootGetters.getCurrentCustomerId;
                const api = context.rootState.railService.railApi;
                const {data = {}} = await api.post(
                    `customers/${customerId}/projects/batch`,
                    {contentType, projectId}
                );
                return data;
            }
            catch(err) {
                console.warn('Rail Service API Error: Failed to copy scenario', err);
                throw new Error(err);
            }
        },
        /**
         * DELETE Permanently deletes a scenario
         * @param {Object} payload 
         *      { 
         *          projectId {String}
         *          scenarioId {String}
         *      }
         * @returns {String} deletedScenario
         */
         async scenarioDeleteHard(context, payload = {}) {
            const {projectId = null, scenarioId = null} = payload;

            try {
                if(!projectId || !scenarioId) throw new Error('Missing required parameter');

                const customerId = context.rootGetters.getCurrentCustomerId;
                const api = context.rootState.railService.railApi;
                const {data = {}} = await api.delete(
                    `/customers/${customerId}/projects/${projectId}/scenarios/${scenarioId}/hard`
                );
                return data;
            }
            catch(err) {
                console.warn('Rail Service API Error: Failed to hard delete scenario', err);
                throw new Error(err);
            }
        },
        /**
         * DELETE Mark Scenario for deletion and hard delete associated comparisons
         * @param {Object} payload 
         *      { 
         *          projectId {String}
         *          scenarioId {String}
         *      }
         * @returns {String} deletedScenario
         */
         async deleteScenarioSoft(context, payload = {}) {
            const {projectId = null, scenarioId = null} = payload;

            try {
                if(!projectId || !scenarioId) throw new Error('Missing required parameter');

                const customerId = context.rootGetters.getCurrentCustomerId;
                const api = context.rootState.railService.railApi;
                const {data = {}} = await api.delete(
                    `/customers/${customerId}/projects/${projectId}/scenarios/${scenarioId}/soft`
                );
                return data;
            }
            catch(err) {
                console.warn('Rail Service API Error: Failed to soft delete scenario', err);
                throw new Error(err);
            }
        },
    }
}