import axios from "axios";

// Local Imports
import railApi from './railServiceApi';
import railApiProjects from './railServiceApiProjects';
import railApiScenarios from './railServiceApiScenarios';
import railApiScenariosWear from './railServiceApiScenariosWear';
import railApiSimulations from './railServiceApiSimulations';
import railApiComparison from './railServiceApiComparison';

export default {
    modules: {
        railApi,
        railApiProjects,
        railApiScenarios,
        railApiScenariosWear,
        railApiSimulations,
        railApiComparison
    },
    state: {
        railApi: null,
    },
    mutations: {
        railApi(state, api) {
            state.railApi = api;
        }
    },
    getters: {},
    actions: {
        /**
         * Configures Axios API for Rail Service 
        */
        async initRailService(context) {
            // Create Axios API
            const baseURL =  process.env.VUE_APP_RAIL_API_BASE || 'http://localhost:8001';
            const axiosApi = await context.dispatch('makeAPI', baseURL, {root: true});
            context.commit('railApi', axiosApi);
        },
        /**
         * PUT Uploads file to S3 using a pre-signed URL
         * @param {Object} payload
         * {
         *      preSignedUrl {String} 
         *      file {Object}
         *      fileType {String}
         * } 
         * @returns {Object} HTTP Response
         */
         async preSignedUpload(context, payload = {}) {
            const {preSignedUrl = null, file = null, fileType = null } = payload;
            try {
                if(!preSignedUrl || !file || !fileType) throw new Error('Missing required parameters');

                // Upload Wear File to S3
                const response = await axios.put(
                    preSignedUrl,
                    file,
                    {headers: {
                        'Content-Type': fileType,
                        Accept: 'application/json'
                    }}
                );
                return response;
            }
            catch(err) {
                console.log('Rail Service API Error: Failed to upload pre-signed url', err);
                throw new Error(err);
            }
        },
    }
}