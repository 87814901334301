export default {
    state: {},
    mutations: {},
    getters: {},
    actions: {
        /**
         * GET a Project
         * @param {String} projectId id of project to get
         * @returns {Object} Project data
         */
         async getProject(context, projectId = null) {
            try {
                if(!projectId) throw new Error('Missing required parameters');

                const customerId = context.rootGetters.getCurrentCustomerId;
                const api = context.rootState.railService.railApi;
                const {data = {}} = await api.get(`/customers/${customerId}/projects/${projectId}`);
                return data;
            }
            catch(err) {
                console.log('Rail Service API Error: Failed to get project', err);
                throw new Error(err);
            }
        },
        /**
         * GET a Batch Project
         * @param {String} projectId id of project to get
         * @returns {Object} Project data
         */
         async getBatchProject(context, projectId = null) {
            try {
                if(!projectId) throw new Error('Missing required parameters');

                const customerId = context.rootGetters.getCurrentCustomerId;
                const api = context.rootState.railService.railApi;
                const {data = {}} = await api.get(`/customers/${customerId}/projects/${projectId}/batch`);
                return data;
            }
            catch(err) {
                console.log('Rail Service API Error: Failed to get batch project', err);
                throw new Error(err);
            }
        },
        /**
         * GET All Projects
         * @returns {Array} list of all projects
         */
         async getProjects(context) {
            try {
                const customerId = context.rootGetters.getCurrentCustomerId;
                const api = context.rootState.railService.railApi;
                const {data = {}} = await api.get(`/customers/${customerId}/projects`);
                return data;
            }
            catch(err) {
                console.log('Rail Service API Error: Failed to get customer projects', err);
                throw new Error(err);
            }
        },
        /**
         * PUT Update Project
         * @param {Object} payload
         *      {
         *          projectId {String} 
         *          (optional) projectName {String}
         *          (optional) selectedBaseline {String} ID of Default Input or Baseline Project ID
         *          (optional) projectBaseline {String} ID of scenario for project baseline
         *      } 
         * @returns {Object} project meta data
         */
         async updateProject(context, payload = {}) {
            const {projectId = null} = payload;
            const {projectName = null, selectedBaseline = null, projectBaseline = null} = payload;
            let projectConfig = {};
            try {
                if(!projectId) throw new Error('Missing required parameters');

                if(projectName !== null) {
                    projectConfig['projectName'] = projectName;
                }
                if(selectedBaseline !== null) {
                    projectConfig['selectedBaseline'] = selectedBaseline;
                }
                if(projectBaseline !== null) {
                    projectConfig['projectBaseline'] = projectBaseline;
                }

                if(Object.keys(projectConfig).length === 0) throw new Error('Missing one optional parameter');

                const customerId = context.rootGetters.getCurrentCustomerId;
                const api = context.rootState.railService.railApi;
                const {data = {}} = await api.put(
                    `/customers/${customerId}/projects/${projectId}`,
                    projectConfig
                );
                return data;
            }
            catch(err) {
                console.log('Rail Service API Error: Failed to update project', err);
                throw new Error(err);
            }
        },
        /**
         * POST Create new Project
         * @param {Object} payload project configuration settings
         *     {
         *          projectName {String}
         *          selectedBaseline {String} ID of Default Input or Baseline Project ID
         *          (optional) projectBaseline {String} ID of scenario for project baseline
         *     }
         * @returns {Object} projectId 
         */
         async createNewProject(context, payload = {}) {
            const {projectName = null, selectedBaseline = null, projectBaseline = null} = payload;
            try {
                if(!projectName || !selectedBaseline) throw new Error('Missing required parameters');
                let params = {projectName, selectedBaseline};
                if(projectBaseline) {
                    params['projectBaseline'] = projectBaseline;
                }

                const customerId = context.rootGetters.getCurrentCustomerId;
                const api = context.rootState.railService.railApi;
                const {data = {}} = await api.post(`/customers/${customerId}/projects`, params);
                return data;
            }
            catch(err) {
                console.log('Rail Service API Error: Failed to create a new project', err);
                throw new Error(err);
            }
        },
        /**
         * POST Copy Project
         * @param {Object} payload
         *      {
         *          projectId {String}
         *          projectName {String}
         *      }
         * @returns {String} id of new project
         */
         async copyProject(context, payload = {}) {
            const {projectId = null, projectName = null} = payload;
            try {
                if(!projectId || !projectName) throw new Error('Missing required parameters');

                const customerId = context.rootGetters.getCurrentCustomerId;
                const api = context.rootState.railService.railApi;
                const {data = {}} = await api.post(
                    `customers/${customerId}/projects/${projectId}/copy`,
                    { projectName }
                );
                return data;
            }
            catch(err) {
                console.log('Rail Service API Error: Failed to copy project', err);
                throw new Error(err);
            }
        },
        /**
         * DELETE Flags a project and associated scenarios for deletion. Hard deletes all associated comparisons.
         * @param {String} projectId of the project to delete
         * @returns {String} projectId of deleted scenario
         */
         async projectDeleteSoft(context, projectId = null) {
            try {
                if(!projectId) throw new Error('Missing required projectId');

                const customerId = context.rootGetters.getCurrentCustomerId;
                const api = context.rootState.railService.railApi;
                const {data = {}} = await api.delete(
                    `customers/${customerId}/projects/${projectId}/soft`
                );
                return data;
            }
            catch(err) {
                console.log('Rail Service API Error: Failed to soft delete a project', err);
                throw new Error(err);
            }
        },
        /**
         * DELETE Permanently deletes a project
         * @param {String} projectId 
         * @returns {Array} [partitionKey, sortKey]
         */
         async projectDeleteHard(context, projectId = null) {
            try {
                if(!projectId) throw new Error('Missing required parameter');

                const customerId = context.rootGetters.getCurrentCustomerId;
                const api = context.rootState.railService.railApi;
                const {data = {}} = await api.delete(`/customers/${customerId}/projects/${projectId}/hard`);
                return data;
            }
            catch(err) {
                console.log('Rail Service API Error: Failed to hard delete project', err);
                throw new Error(err);
            }
        },
    }
}