export default {
    state: {},
    mutations: {},
    getters: {},
    actions: {
        /**
         * GET Simulation 
         * @param {Object} payload
         *      {
         *          projectId {String}
         *          scenarioId {String}
         *          simulationId {String}
         *      }
         * @returns {Object} simulation status, id, and configuration
         */
         async getSimulation(context, payload = {}) {
            const {projectId = null, scenarioId = null, simulationId = null} = payload;
            try {
                if(!projectId || !scenarioId || !simulationId) throw new Error('Missing required parameters');
                const customerId = context.rootGetters.getCurrentCustomerId;
                const api = context.rootState.railService.railApi;
                const {data = {}} = await api.get(
                    `customers/${customerId}/projects/${projectId}/scenarios/${scenarioId}/simulations/${simulationId}`
                );
                return data;
            }
            catch(err) {
                console.log('Rail Service API Error: Failed to get simulation', err);
                throw new Error(err);
            }
        },
        /**
         * GET a customer's VTI files
         * @returns {Array} vtiSimulation
         */
         async getVtiSimulationFiles(context) {
            try {
                const api = context.rootState.railService.railApi;
                const customerId = context.rootGetters.getCurrentCustomerId;
                const {data = {}} = await api.get(`/customers/${customerId}/vti/vtiSimulationFiles`);
                return data;
            }
            catch(err) {
                console.log('Rail Service API Error: Failed to get VTI Simulations', err);
                throw new Error(err);
            }
        },
        /**
         * GET simulation logs
         * @param {Object} payload
         *      {
         *          projectId {String}
         *          scenarioId {String}
         *          simulationId {String}
         *          optionString {String}
         *      }
         * @returns {Object} simulation logs
         */
         async getSimulationLogs(context, payload = {}) {
            const {projectId = null, scenarioId = null, simulationId = null, optionString = null} = payload;
            try {
                if(!projectId || !scenarioId || !simulationId || !optionString) throw new Error('Missing required parameters');

                const customerId = context.rootGetters.getCurrentCustomerId;
                const api = context.rootState.railService.railApi;
                const logs = await api.get(
                    `/customers/${customerId}/projects/${projectId}/scenarios/${scenarioId}/simulations/${simulationId}/logs${optionString}`
                );
                return logs;
            }
            catch(err) {
                console.log('Rail Service API Error: ', err);
                throw new Error(err);
            }
        },
        /**
         * GET a customer's max/min cut depth for rail and max grinding passes.
         * @returns {Number} depthOfCut
         */
         async getVTIDepthOfCutValues(context) {
            try {
                const customerId = context.rootGetters.getCurrentCustomerId;
                const api = context.rootState.railService.railApi;
                const {data = {}} = await api.get(`/customers/${customerId}/depthOfCutValues`);
                return data;
            }
            catch(err) {
                console.log('Rail Service API Error: Failed to get VTI depth of cut values', err);
                throw new Error(err);
            }
        },
        /**
         * GET Customer max job count
         * @returns {Number} max job count
         */
         async getMaxJobCount(context) {
            try {
                const customerId = context.rootGetters.getCurrentCustomerId;
                const api = context.rootState.railService.railApi;
                const {data: { maxJobs = null}} = await api.get(`customers/${customerId}/maxJobCount`);
                return maxJobs;
            }
            catch(err) {
                console.log('Rail Service API Error: Failed to get max job count', err);
                throw new Error(err);
            }
        },
        /**
         * GET Customer Running Jobs
         * @returns {Number} running job count
         */
         async getRunningJobCount(context) {
            try {
                const customerId = context.rootGetters.getCurrentCustomerId;
                const api = context.rootState.railService.railApi;
                const {data: { runningJobs = null}} = await api.get(`customers/${customerId}/runningJobCount`);
                return runningJobs;
            }
            catch(err) {
                console.log('Rail Service API Error: Failed to get max job count', err);
                throw new Error(err);
            }
        },
        /**
         * GET Simulation Outputs
         * @param {Object} payload
         *      {
         *          projectId {String}
         *          scenarioId {String}
         *          simulationId {String}
         *      }
         * @returns {String} pre-signed url to download
         */
         async getSimulationOutputs(context, payload = {}) {
            const {projectId = null, scenarioId = null, simulationId = null} = payload;
            try {
                if(!projectId || !scenarioId || !simulationId) throw new Error('Missing required parameters');

                const customerId = context.rootGetters.getCurrentCustomerId;
                const api = context.rootState.railService.railApi;
                const {data: {url = null}} = await api.get(
                    `/customers/${customerId}/projects/${projectId}/scenarios/${scenarioId}/simulations/${simulationId}/output`
                );

                if(!url) throw new Error('Failed to get pre-signed url');
                return url;
            }
            catch(err) {
                console.log('Rail Service API Error: Failed to get simulation outputs', err);
                throw new Error(err);
            }
        },
        /**
         * PUT Update Simulation
         * @param {Object} payload 
         *      { 
         *          projectId {String}
         *          scenarioId {String}
         *          simulationId {String}
         *          (optional) simulationConfig {Object}
         *          (optional) simulationStatus {String}
         *      }
         * @returns {Object} {simulationConfig,simulationId,simulationStatus}
         */
         async updateSimulation(context, payload = {}) {
            const {projectId = null, scenarioId = null, simulationId = null} = payload;
            const {simulationConfig = null, simulationStatus = null} = payload;
            let dataToUpdate = {};
            try {
                if(!projectId || !scenarioId || !simulationId) throw new Error('Missing required parameters');

                if(!simulationConfig) {
                    dataToUpdate['simulationConfig'] = simulationConfig;
                }
                if(!simulationStatus) {
                    dataToUpdate['simulationStatus'] = simulationStatus;
                }
                if(Object.values(dataToUpdate).length === 0) throw new Error('Missing an optional parameter.');

                const customerId = context.rootGetters.getCurrentCustomerId;
                const api = context.rootState.railService.railApi;
                const {data = {}} = await api.put(
                    `/customers/${customerId}/projects/${projectId}/scenarios/${scenarioId}/simulations/${simulationId}`,
                    dataToUpdate
                );
                return data;
            }
            catch(err) {
                console.log('Rail Service API Error: Failed to update simulation', err);
                throw new Error(err);
            }
        },
        /**
         * POST Send contact support email for a failed simulation.
         * @param {Object} payload 
         *      { 
         *          projectId {String}
         *          scenarioId {String}
         *          simulationId {String}
         *          customerMsg {String}
         *      }
         * @returns {Object} simulation identifiers
         */
         async sendSimulationSupportEmail(context, payload = {}) {
            const {projectId = null, scenarioId = null, simulationId = null, customerMsg = null} = payload;
            try {
                if(!projectId || !scenarioId || !simulationId || !customerMsg) throw new Error('Missing required parameters');

                const customerId = context.rootGetters.getCurrentCustomerId;
                const api = context.rootState.railService.railApi;
                const {data = {}} = await api.post(
                    `/customers/${customerId}/projects/${projectId}/scenarios/${scenarioId}/simulations/${simulationId}/supportEmail`,
                    { customerMsg }
                );
                return data;
            }
            catch(err) {
                console.log('Rail Service API Error: Failed to send support email for failed simulation', err);
                throw new Error(err);
            }
        },
        /**
         * POST Set a customer's max/min cut depth for rail and max grinding passes.
         * @param {Object} depthOfCutValues values to update
         * @returns {Object} saved depthOfCutValues
         */
         async setSimulationDepthOfCutValues(context, depthOfCutValues = null) {
            try {
                if(!depthOfCutValues) throw new Error('Missing required parameter');

                const customerId = context.rootGetters.getCurrentCustomerId;
                const api = context.rootState.railService.railApi;
                const {data = {}} = await api.post(
                    `/customers/${customerId}/depthOfCutValues`,
                    {depthOfCutValues}
                );
                return data;
            }
            catch(err) {
                console.log('Rail Service API Error: Failed to update VTI depth of cut values', err);
                throw new Error(err);
            }
        },
        /**
         * POST Creates a new wear simulation job with the configuration inputs
         * @param {Object} payload 
         *      { 
         *          projectId {String}
         *          scenarioId {String}
         *          simulationConfig {Object}
         *      }
         * @returns {Boolean} True if successful status of 200, false otherwise
         */
         async createNewSimulation(context, payload = {}) {
            const {projectId = null, scenarioId = null, simulationConfig = null} = payload;
            try {
                if(!projectId || !scenarioId || !simulationConfig) throw new Error('Missing required parameters');

                const customerId = context.rootGetters.getCurrentCustomerId;
                const api = context.rootState.railService.railApi;
                const response = await api.post(
                    `/customers/${customerId}/projects/${projectId}/scenarios/${scenarioId}/simulations`,
                    { simulationConfig }
                );
                return (response.status === 200);
            }
            catch(err) {
                console.log('Rail Service API Error: ', err);
                throw new Error(err);
            }
        },
        /**
         * POST Cancel Simulation
         * @param {Object} payload 
         *      { 
         *          projectId {String}
         *          scenarioId {String}
         *          simulationId {String}
         *      }
         * @returns {Object} {success, message, simulationStatus, simulationId}
         */
         async cancelSimulation(context, payload = {}) {
            const {projectId = null, scenarioId = null, simulationId = null} = payload;
            try {
                if(!projectId || !scenarioId || !simulationId) throw new Error('Missing required parameters');

                const customerId = context.rootGetters.getCurrentCustomerId;
                const api = context.rootState.railService.railApi;
                const {data = {}} = await api.post(
                    `/customers/${customerId}/projects/${projectId}/scenarios/${scenarioId}/simulations/${simulationId}/stop`
                );
                return data;
            }
            catch(err) {
                console.log('Rail Service API Error: Failed to cancel simulation', err);
                throw new Error(err);
            }
        },
        /**
         * POST Upload VTI File to S3
         * @param {Object} payload 
         *      { 
         *          contentType {String}
         *          filename {String} Appends ".zip" to name
         *      }
         * @returns {Object} { preSignedUrl, filename }
         */
        async getVTIPreSignedURL(context, payload = {}) {
            const {contentType = null, filename = null} = payload;
            try {
                if(!contentType || !filename) throw new Error('Missing required parameters');

                const customerId = context.rootGetters.getCurrentCustomerId;
                const api = context.rootState.railService.railApi;
                const {data = {}} = await api.post(
                    `/customers/${customerId}/vti/presignedUrlVti`,
                    {contentType, filename: `${filename}.zip`}
                );
                return data;
            }
            catch(err) {
                console.log('Rail Service API Error: Failed to get VTI Pre Signed URL', err);
                throw new Error(err);
            }
        },
    }
}