module.exports = {
  name: 'dashboard',
  path: "/",
  component: () => import("@dash/Dashboard"),
  children: [
    {
      path: "/",
      name: "emptyPage",
      component: () => import("@dash/components/Layout/Construct/EmptyPage"),
      meta: { module: "dash" }
    },
    {
      path: "/profile",
      name: "profile",
      component: () => import("@dash/components/Profile/UserInfo")
    },
    {
      path: "/settings",
      component: () => import("@dash/components/Layout/Construct/Settings"),
      meta: { module: "dash" },
      children: [
        {
          name: "users",
          path: "users",
          alias: "",
          component: () =>
            import("@dash/components/Layout/Construct/Settings/Users")
        },
        {
          name: "customers",
          path: "customers",
          alias: "",
          component: () =>
            import("@dash/components/Layout/Construct/Settings/Customers")
        },
        {
          name: "sentientusers",
          path: "sentientusers",
          alias: "",
          component: () =>
            import("@dash/components/Layout/Construct/Settings/SentientUsers")
        }
      ]
    }
  ]
};
