import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "vue-awesome/icons";
import debounce from "v-debounce";
import BootstrapVue from "bootstrap-vue";
import Icon from "vue-awesome/components/Icon";
import lodash from "lodash";
import Datepicker from "vuejs-datepicker";
import VueLazyLoad from "vue-lazyload";
import VueMoment from "vue-moment";
import Vue2Filters from "vue2-filters";
import Toasted from "vue-toasted";

Vue.config.productionTip = false;
Vue.component("icon", Icon);
Vue.component("Datepicker", Datepicker);
Vue.directive("debounce", debounce);
Vue.use(VueMoment);
Vue.use(router);
Vue.use(BootstrapVue);
Vue.use(Vue2Filters);
Vue.use(VueLazyLoad);
Vue.use(lodash);
Vue.use(Toasted, {
  position: "bottom-right",
  duration: 5000,
  containerClass: "dc-toast",
  iconPack: "fontawesome"
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
