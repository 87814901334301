import inputsCalculator from '@loram/economic_calculations-package';
/* field.views enumeration:
    comparison, calculation, baseline
*/

export default class BudgetField {
    constructor({defaultInputs = null, savedInputs = null, options = {}}) {
        const calculationsInputs = (savedInputs)
            ? inputsCalculator.getCalculation({defaultInputs, savedInputs, options})
            : inputsCalculator.getCalculation({defaultInputs, options});
        this.inputs = calculationsInputs;
        //window.inputs = this.inputs;
    }
    getFieldInfo(id) {
        let fields = this.getTabs();
        let info = {};
        fields.tabs.forEach(tab => {    
            Object.keys(tab.sections).forEach((key, idx) => {
                Object.keys(tab.sections[idx].fields).forEach(key => {
                    if(tab.sections[idx].fields[key].id == id) {
                        info.tab = tab.title;
                        info.section = tab.sections[idx].title;
                        info.label = tab.sections[idx].fields[key].label;
                        info.type = tab.sections[idx].fields[key].type;
                    }
                })
            })
        });
        return info;
    }
    getTabs() {
        return {
            tabs: [
                {
                    title: 'General Assumptions',
                    hidden: false,
                    sections: [
                        {
                            title: 'Track Characteristics',
                            fields: [
                                {
                                    id: 'routeMiles',
                                    label: 'Track Miles',
                                    title: 'The total number of miles of track that are used during operations. One mile of single track would be an input of 1. One mile of double track would be an input of 2.',
                                    value: this.inputs.routeMiles,
                                    disabled: false,
                                    type: 'int'
                                },
                                {
                                    id: 'linearTrackMiles',
                                    label: 'Linear Rail Miles',
                                    title: 'The total track miles multiplied by 2.  Each mile of single track has two miles of rail.  If you have double track, include that as additional track miles.',
                                    value: this.inputs.linearTrackMiles,
                                    disabled: true,
                                    type: 'int'
                                },
                                {
                                    id: 'tangentTrackPercentage',
                                    label: 'Tangent Track - Ratio of Network',
                                    title: 'What ratio of your total track miles are tangent (or straight) track? ',
                                    value: this.inputs.tangentTrackPercentage,
                                    disabled: false,
                                    type: 'int'
                                },
                                {
                                    id: 'tangentLinearTrackMiles',
                                    label: 'Tangent Track - Linear Rail Miles',
                                    title: 'The tangent track miles multiplied by 2.  Each mile of single track has two miles of rail.  If you have double track, include that as additional track miles.',
                                    value: this.inputs.tangentLinearTrackMiles,
                                    disabled: true,
                                    type: 'int'
                                },
                                {
                                    id: 'mildCurvesPercentage',
                                    label: 'Mild Curve Track - Ratio of Network',
                                    title: 'What ratio of your total track miles are considered "mild curves"?  The definition of what level of curvature defines a mild curve will change between each railroad.  We recommend discussing this question with your track engineering teams to understand if there is a consensus of definition.  The definition is not important, but it is important to keep the definition consistent between economic models if you will compare them.',
                                    value: this.inputs.mildCurvesPercentage,
                                    disabled: false,
                                    type: 'int'
                                },
                                {
                                    id: 'mildCurveLinearTrackMiles',
                                    label: 'Mild Curve Track - Linear Rail Miles',
                                    title: 'The mild curve track miles multiplied by 2.  Each mile of single track has two miles of rail.  If you have double track, include that as additional track miles.',
                                    value: this.inputs
                                        .mildCurveLinearTrackMiles,
                                    disabled: true,
                                    type: 'int'
                                },
                                {
                                    id: 'mediumCurvesPercentage',
                                    label: 'Medium Curve Track - Ratio of Network',
                                    title: 'What ratio of your total track miles are considered "medium curves"?  The definition of what level of curvature defines a medium curve will change between each railroad.  We recommend discussing this question with your track engineering teams to understand if there is a consensus of definition. Some railroads do not have a definition for medium curve.  The definition is not important, but it is important to keep the definition consistent between economic models if you will compare them.',
                                    value: this.inputs.mediumCurvesPercentage,
                                    disabled: false,
                                    type: 'int'
                                },
                                {
                                    id: 'mediumCurveLinearTrackMiles',
                                    label: 'Medium Curve Track - Linear Rail Miles',
                                    title: 'The medium curve track miles multiplied by 2.  Each mile of single track has two miles of rail.  If you have double track, include that as additional track miles.',
                                    value: this.inputs
                                        .mediumCurveLinearTrackMiles,
                                    disabled: true,
                                    type: 'int'
                                },
                                {
                                    id: 'severeCurvesPercentage',
                                    label: 'Severe Curve Track - Ratio of Network',
                                    title: 'What ratio of your total track miles are considered "severe curves"?  The definition of what level of curvature defines a severe curve will change between each railroad.  We recommend discussing this question with your track engineering teams to understand if there is a consensus of definition. Some railroads do not have a definition for medium curve.  The definition is not important, but it is important to keep the definition consistent between economic models if you will compare them.',
                                    value: this.inputs.severeCurvesPercentage,
                                    disabled: false,
                                    type: 'int'
                                },
                                {
                                    id: 'severeCurveLinearTrackMiles',
                                    label: 'Severe Curve Track - Linear Rail Miles',
                                    title: 'The severe curve track miles multiplied by 2.  Each mile of single track has two miles of rail.  If you have double track, include that as additional track miles.',
                                    value: this.inputs
                                        .severeCurveLinearTrackMiles,
                                    disabled: true,
                                    type: 'int'
                                },
                                {
                                    id: 'totalCrossings',
                                    label: 'Crossings in Network',
                                    title: 'What is the total number of crossings in your network?',
                                    value: this.inputs.totalCrossings,
                                    disabled: false,
                                    type: 'int'
                                },
                                {
                                    id: 'totalTurnouts',
                                    label: 'Turnouts in Network',
                                    title: 'What is the total number of turnouts (or another special track work) in your network?  You can define whether this number only includes turnouts or includes all types of special track work that are not tangent or curves.  However, this definition of "Turnout" will need to be consistent with the data input for Turnouts (New) Replaced in the Capital tab.',
                                    value: this.inputs.totalTurnouts,
                                    disabled: false,
                                    type: 'int'
                                }
                            ]
                        },
                        {
                            title: 'Property Accounting Strategy',
                            fields: [
                                {
                                    id: 'straightLineDepreciation',
                                    label: 'Does Network Depreciate Track Assets?',
                                    title: 'Does your railroad include the cost of depreciation in its financial reports or records?  All Class 1 railroads use depreciation in their public accounting and reporting, and the approach is described in the annual reports.  Many transit systems do not use depreciation in their accounting per guidance from the Governmental Accounting Standards Board (GASB), but some railroads do.  We\'d recommend checking any public financial disclosures to see if depreciation is included in the costs/expenses or asking your property accounting team.  If you don\'t have a property accounting or similar team, you likely don\'t use depreciation.',
                                    value: this.inputs.straightLineDepreciation,
                                    disabled: false,
                                    type: 'bool'
                                },
                                {
                                    id: 'mainRouteTrafficPercentage',
                                    label: 'What Ratio of Network is Depreciated?',
                                    title: 'This input determines how much of your total rail network (from inputs from Track Characteristics) should have the depreciation rate applied to it.  Class 1 railroads have different depreciation rates for main line track with >20 MGT of traffic per year vs. yards/sidings that get fewer MGT of traffic and therefore have a longer life.  For Class 1 railroads, the recommended input is what ratio of your network is designated as "Density 1" based on annual R1 reports or estimate of how much of your scope has >20MGT of traffic per year.  For transit agencies, this input is usually 100% if you use depreciation. ',
                                    value: this.inputs.mainRouteTrafficPercentage,
                                    disabled: false,
                                    type: 'int'
                                },
                                {
                                    id: 'mainRouteTrafficMiles',
                                    label: 'Depreciated Assets - Linear Rail Miles',
                                    title: 'The Linear Rail Miles multiplied by the ratio of your network that is depreciated.',
                                    value: this.inputs.mainRouteTrafficMiles,
                                    disabled: true,
                                    type: 'int'
                                }
                            ]
                        },
                        {
                            title: 'Labor Cost',
                            fields: [
                                {
                                    id: 'laborCost',
                                    label: 'Cost per Hour of Track Worker',
                                    title: 'This input is the average cost per hour of teams that work on your tracks.  If they are salaried employees, one method to assume the hourly cost is to divide the total salary by 52 weeks of year and 40 hours per week.  ',
                                    value: this.inputs.laborCost,
                                    disabled: false,
                                    type: 'currency',
                                },
                                {
                                    id: 'laborOverhead',
                                    label: 'Overhead Cost Ratio',
                                    title: 'This input is to calculate the other costs required to support and retain your track workers.  Overhead costs may include medical benefits, cost of office for overhead, and is highly dependent on the railroad.  The input is a ratio, which is multiplied by the cost per hour of track worker, to determine the incremental cost of overhead.  For example, if you have a Cost per Hour of Track Worker of $100 and an overhead cost ratio of 50%,, the total overhead cost per hour of track time would be $50.  ($100 * 50%).  Therefore, the \'real\' cost per hour of work is $150 per hour (Cost per Hour of Track Worker + Overhead Cost of Track Worker).',
                                    value: this.inputs.laborOverhead,
                                    disabled: false,
                                    type: 'int'
                                },
                                {
                                    id: 'overtimeRate',
                                    label: 'Overtime Cost Ratio Multiplier',
                                    title: 'How much extra does a worker get paid for overtime?  If your overtime benefit is "time and a half" this input would be 150%.  If your overtime benefit is double, this input would be 200%.',
                                    value: this.inputs.overtimeRate,
                                    disabled: false,
                                    type: 'int'
                                },
                                {
                                    id: 'laborShift',
                                    label: 'Hours of Labor per Shift',
                                    title: 'What is the average length of labor shift before overtime is allocated?  Usually this is 8h, but some railroads may have higher average hours per shift.',
                                    value: this.inputs.laborShift,
                                    disabled: false,
                                    type: 'int'
                                }
                            ]
                        },
                        {
                            title: 'Track Usage Cost',
                            fields: [
                                {
                                    id: 'delayCostPerHour',
                                    label: 'Track Access Cost per Hour',
                                    title: 'How much cost is assigned per hour of track time used?',
                                    value: this.inputs.delayCostPerHour,
                                    disabled: false,
                                    type: 'currency'
                                },
                                {
                                    id: 'totalExpensesTrackAccess',
                                    label: 'Total Track Access Cost',
                                    title: 'How much does it cost your railroad for track access to perform all maintenance activities?',
                                    value: this.inputs.totalExpensesTrackAccess,
                                    disabled: true,
                                    type: 'currency'
                                }
                            ]
                        }
                    ]
                },
                {
                    title: 'Capital',
                    hidden: false,
                    sections: [
                        {
                            title: 'Summary of Total Capital Costs',
                            fields: [
                                {
                                    id: 'totalCapitalCost',
                                    label: 'Total Cost of Capital Replacements',
                                    title: 'The total planned spend to replace all your rail',
                                    value: this.inputs.totalCapitalCost,
                                    disabled: true,
                                    type: 'currency'
                                },
                                {
                                    id: 'capitalCostDueToSurfaceFatigue',
                                    label: 'Total Cost of Capital Replacements due to Surface Fatigue',
                                    title: 'The total amount of your capital spend driven by this failure mode.',
                                    value: this.inputs.capitalCostDueToSurfaceFatigue,
                                    disabled: true,
                                    type: 'currency'
                                },
                                {
                                    id: 'capitalCostDueToSubsurfaceFatigue',
                                    label: 'Total Cost of Capital Replacements due to Subsurface Fatigue',
                                    title: 'The total amount of your capital spend driven by this failure mode.',
                                    value: this.inputs.capitalCostDueToSubsurfaceFatigue,
                                    disabled: true,
                                    type: 'currency'
                                },
                                {
                                    id: 'capitalCostDueToWeldFailures',
                                    label: 'Total Cost of Capital Replacements due to Weld Damage',
                                    title: 'The total amount of your capital spend driven by this failure mode.',
                                    value: this.inputs.capitalCostDueToWeldFailures,
                                    disabled: true,
                                    type: 'currency'
                                },
                                {
                                    id: 'capitalCostDueToWear',
                                    label: 'Total Cost of Capital Replacements due to Wear',
                                    title: 'The total amount of your capital spend driven by this failure mode.',
                                    value: this.inputs.capitalCostDueToWear,
                                    disabled: true,
                                    type: 'currency'
                                },
                                {
                                    id: 'capitalCostDueToOtherFailureModes',
                                    label: 'Total Cost of Capital Replacements due to Other Failure Modes',
                                    title: 'The total amount of your capital spend driven by this failure mode.',
                                    value: this.inputs.capitalCostDueToOtherFailureModes,
                                    disabled: true,
                                    type: 'currency'
                                }
                            ]
                        },
                        {
                            title: 'Capital Rail Replacement Plan',
                            fields: [
                                {
                                    id: 'TMRTangentNew',
                                    label: 'Tangent (New) Linear Rail Miles Replaced',
                                    title: 'how many linear rail miles of tangent (straight) track are planned to be replaced this year with new rail?',
                                    value: this.inputs.TMRTangentNew,
                                    disabled: false,
                                    type: 'int'
                                },
                                {
                                    id: 'TMRTangentRelay',
                                    label: 'Tangent (Used/Relay) Linear Rail Miles Replaced',
                                    title: 'how many linear rail miles of tangent (straight) track are planned to be replaced this year with used (relay) rail?  Used rail usually has a lower expected life, but a lower cost.',
                                    value: this.inputs.TMRTangentRelay,
                                    disabled: false,
                                    type: 'int'
                                },
                                {
                                    id: 'TMRCurve',
                                    label: 'Curve (New) Linear Rail Miles Replaced',
                                    title: 'How many linear rail miles of curved track are planned to be replaced this year with new rail?',
                                    value: this.inputs.TMRCurve,
                                    disabled: false,
                                    type: 'int'
                                },
                                {
                                    id: 'totalRailMilesReplace',
                                    label: 'Total Track Miles Replaced',
                                    title: 'The sum of your tangent, relay, and curved track miles replaced.  This does not include your turnout replacements.',
                                    value: this.inputs.totalRailMilesReplace,
                                    disabled: true,
                                    type: 'int'
                                },
                                {
                                    id: 'specialTrackWorkReplaced',
                                    label: 'Turnouts (New) Replaced',
                                    title: 'How many turnouts or special track work are planned to be replaced this year?',
                                    value: this.inputs.specialTrackWorkReplaced,
                                    disabled: false,
                                    type: 'int'
                                },
                                {
                                    id: 'specialTrackWorkReplacedFrog',
                                    label: 'Turnout Frogs Replaced',
                                    title: 'How many turnout frogs are planned to be replaced this year?',
                                    value: this.inputs.specialTrackWorkReplacedFrog,
                                    disabled: false,
                                    type: 'int'
                                },
                                {
                                    id: 'specialTrackWorkRehabCrossings',
                                    label: 'Crossings (New) Rehabilitated',
                                    title: 'How many crossings are planned to be rehab\'ed this year?',
                                    value: this.inputs.specialTrackWorkRehabCrossings,
                                    disabled: false,
                                    type: 'int'
                                },
                                {
                                    id: 'specialTrackWorkReplacedCrossings',
                                    label: 'Crossings (New) Replaced',
                                    title: 'How many crossings are planned to be replaced this year?',
                                    value: this.inputs.specialTrackWorkReplacedCrossings,
                                    disabled: false,
                                    type: 'int'
                                },
                                {
                                    id: 'specialTrackWorkReplacedSwitchPoint',
                                    label: 'Turnout Switch Points Replaced',
                                    title: 'How many turnout switch points are planned to be replaced this year?',
                                    value: this.inputs.specialTrackWorkReplacedSwitchPoint,
                                    disabled: false,
                                    type: 'int'
                                },
                            ]
                        },
                        {
                            title: 'Capital Cost',
                            fields: [
                                {
                                    id: 'TMPTangentNew',
                                    label: 'Tangent (New) Price per Linear Rail Mile',
                                    title: 'What is the average cost for replacing a mile of tangent track with new rail?  This cost should include all tangible costs involved in the rail material, labor required to install it, and other costs like shipping and transportation.  Often, this cost is higher than the cost of used/relay tangent rail and lower than the cost of replacing a curve.',
                                    value: this.inputs.TMPTangentNew,
                                    disabled: false,
                                    type: 'currency'
                                },
                                {
                                    id: 'TMPTangentRelay',
                                    label: 'Tangent (Used/Relay) Price per Linear Rail Mile',
                                    title: 'What is the average cost for replacing a mile of tangent track with used rail?  This cost should include all tangible costs involved in the rail material, labor required to install it, and other costs like shipping and transportation.  Often, this cost is lower than the cost of new tangent rail.  Many railroads do not install used/relay rail at all, and the input would be $0.',
                                    value: this.inputs.TMPTangentRelay,
                                    disabled: false,
                                    type: 'currency'
                                },
                                {
                                    id: 'TMPCurve',
                                    label: 'Curve (New) Price per Linear Rail Mile',
                                    title: 'What is the average cost for replacing a mile of curved track with new rail?  This cost should include all tangible costs involved in the rail material, labor required to install it, and other costs like shipping and transportation.  Often, this cost is higher than the cost of new tangent rail.',
                                    value: this.inputs.TMPCurve,
                                    disabled: false,
                                    type: 'currency'
                                },
                                {
                                    id: 'specialTrackWorkCost',
                                    label: 'Turnout (New) Price',
                                    title: 'What is the average cost for replacing a turnout? This cost should include all tangible costs involved in the rail material, labor required to install it, and other costs like shipping and transportation.  Often, this cost is lower than the costs of replacing a mile of track.',
                                    value: this.inputs.specialTrackWorkCost,
                                    disabled: false,
                                    type: 'currency'
                                },
                                {
                                    id: 'specialTrackWorkCostFrog',
                                    label: 'Turnout Frog (New) Price',
                                    title: 'What is the average cost for replacing a turnout frog?',
                                    value: this.inputs.specialTrackWorkCostFrog,
                                    disabled: false,
                                    type: 'currency'
                                },
                                {
                                    id: 'specialTrackWorkCostCrossingRehab',
                                    label: 'Crossing Rehab (New) Price',
                                    title: 'What is the average cost for rehabbing a crossing?',
                                    value: this.inputs.specialTrackWorkCostCrossingRehab,
                                    disabled: false,
                                    type: 'currency'
                                },
                                {
                                    id: 'specialTrackWorkCostCrossingReplacement',
                                    label: 'Crossing Replacement (New) Price',
                                    title: 'What is the average cost for replacing a crossing?',
                                    value: this.inputs.specialTrackWorkCostCrossingReplacement,
                                    disabled: false,
                                    type: 'currency'
                                },
                                {
                                    id: 'specialTrackWorkCostSwitchPoint',
                                    label: 'Turnout Switch Points (New) Price',
                                    title: 'What is the average cost for replacing a turnout switch point?',
                                    value: this.inputs.specialTrackWorkCostSwitchPoint,
                                    disabled: false,
                                    type: 'currency'
                                },
                            ]
                        },
                        {
                            title: 'Capital Corrective Spending by Root Cause',
                            fields: [
                                {
                                    id: 'tangentSurfaceFatigue',
                                    label: 'Ratio of Tangent Capital Replacement due to Surface Fatigue Defects',
                                    title: 'What ratio of the total "Tangent (New) Linear Rail Miles Replaced" and "Tangent (Used/Relay) Linear Rail Miles Replaced" are planned for replacement due to number of defects that were caused by surface fatigue cracks?  The definition of what defects are caused by surface fatigue cracks vary by railroad, but often include transverse detail defects (TDD)s, shelling spalling, and corrugation defect (SSCs) and associated defects.  ',
                                    value: this.inputs.tangentSurfaceFatigue,
                                    disabled: false,
                                    type: 'int'
                                },
                                {
                                    id: 'tangentSubsurfaceFatigue',
                                    label: 'Ratio of Tangent Capital Replacement due to Subsurface Fatigue Defects',
                                    title: 'What ratio of the total "Tangent (New) Linear Rail Miles Replaced" and "Tangent (Used/Relay) Linear Rail Miles Replaced" are planned for replacement due to number of defects that were caused by fatigue cracks that started in the subsurface of the rail?  The definition of what defects are caused by surface fatigue cracks vary by railroad, but often include defects that have an initiation point inside the rail head instead of on top of the rail head.  ',
                                    value: this.inputs.tangentSubsurfaceFatigue,
                                    disabled: false,
                                    type: 'int'
                                },
                                {
                                    id: 'tangentWelds',
                                    label: 'Ratio of Tangent Capital Replacement due to Weld Defects',
                                    title: 'What ratio of the total "Tangent (New) Linear Rail Miles Replaced" and "Tangent (Used/Relay) Linear Rail Miles Replaced" are planned for replacement due to number of defects that were caused weld damage, breaks, or dipped welds?  ',
                                    value: this.inputs.tangentWelds,
                                    disabled: false,
                                    type: 'int'
                                },
                                {
                                    id: 'tangentWear',
                                    label: 'Ratio of Tangent Capital Replacement due to Wear',
                                    title: 'What ratio of the total "Tangent (New) Linear Rail Miles Replaced" and "Tangent (Used/Relay) Linear Rail Miles Replaced" are planned for replacement due to approaching or hitting the condemning limit for top, side, or combined wear or due to defects where wear is associated?  Typically, tangents have lower wear ratio than curves.',
                                    value: this.inputs.tangentWear,
                                    disabled: false,
                                    type: 'int'
                                },
                                {
                                    id: 'tangentOther',
                                    label: 'Ratio of Tangent Capital Replacement due to Other Failure Modes',
                                    title: 'The total amount of your tangent replacements driven by this failure mode.',
                                    value: this.inputs.tangentOther,
                                    disabled: true,
                                    type: 'int'
                                },
                                {
                                    id: 'curveSurfaceFatigue',
                                    label: 'Ratio of Curve Capital Replacement due to Surface Fatigue Defects',
                                    title: 'What ratio of the total "Curve (New) Linear Rail Miles Replaced" are planned for replacement due to number of defects that were caused by surface fatigue cracks?  The definition of what defects are caused by surface fatigue cracks vary by railroad, but often include transverse detail defects (TDD)s, shelling spalling, and corrugation defect (SSCs) and associated defects.  ',
                                    value: this.inputs.curveSurfaceFatigue,
                                    disabled: false,
                                    type: 'int'
                                },
                                {
                                    id: 'curveSubsurfaceFatigue',
                                    label: 'Ratio of Curve Capital Replacement due to Subsurface Fatigue Defects',
                                    title: 'What ratio of the total "Curve (New) Linear Rail Miles Replaced" are planned for replacement due to number of defects that were caused by fatigue cracks that started in the subsurface of the rail?  The definition of what defects are caused by surface fatigue cracks vary by railroad, but often include defects that have an initiation point inside the rail head instead of on top of the rail head.  ',
                                    value: this.inputs.curveSubsurfaceFatigue,
                                    disabled: false,
                                    type: 'int'
                                },
                                {
                                    id: 'curveWelds',
                                    label: 'Ratio of Curve Capital Replacement due to Weld Defects',
                                    title: 'What ratio of the total "Curve (New) Linear Rail Miles Replaced" are planned for replacement due to number of defects that were caused weld damage, breaks, or dipped welds?  ',
                                    value: this.inputs.curveWelds,
                                    disabled: false,
                                    type: 'int'
                                },
                                {
                                    id: 'curveWear',
                                    label: 'Ratio of Curve Capital Replacement due to Wear',
                                    title: 'What ratio of the total "Curve (New) Linear Rail Miles Replaced" are planned for replacement due to approaching or hitting the condemning limit for top, side, or combined wear or due to defects where wear is associated?  Typically, tangents have lower wear ratio than curves.',
                                    value: this.inputs.curveWear,
                                    disabled: false,
                                    type: 'int'
                                },
                                {
                                    id: 'curveOther',
                                    label: 'Ratio of Curve Capital Replacement due to Other Failure Modes',
                                    title: 'The total amount of your curve replacements driven by this failure mode.',
                                    value: this.inputs.curveOther,
                                    disabled: true,
                                    type: 'int'
                                },
                                {
                                    id: 'specialSurfaceFatigue',
                                    label: 'Ratio of Turnout Capital Replacement due to Surface Fatigue Defects',
                                    title: 'What ratio of the total "Turnouts (New) Replaced" are planned for replacement due to number of defects that were caused by surface fatigue cracks?  The definition of what defects are caused by surface fatigue cracks vary by railroad, but often include transverse detail defects (TDD)s, shelling spalling, and corrugation defect (SSCs) and associated defects.  ',
                                    value: this.inputs.specialSurfaceFatigue,
                                    disabled: false,
                                    type: 'int'
                                },
                                {
                                    id: 'specialSubsurfaceFatigue',
                                    label: 'Ratio of Turnout Capital Replacement due to Subsurface Fatigue Defects',
                                    title: 'What ratio of the total "Turnouts (New) Replaced" are planned for replacement due to number of defects that were caused by fatigue cracks that started in the subsurface of the rail?  The definition of what defects are caused by surface fatigue cracks vary by railroad, but often include defects that have an initiation point inside the rail head instead of on top of the rail head.  ',
                                    value: this.inputs.specialSubsurfaceFatigue,
                                    disabled: false,
                                    type: 'int'
                                },
                                {
                                    id: 'specialWelds',
                                    label: 'Ratio of Turnout Capital Replacement due to Weld Defects',
                                    title: 'What ratio of the total "Turnouts (New) Replaced" are planned for replacement due to number of defects that were caused weld damage, breaks, or dipped welds?  ',
                                    value: this.inputs.specialWelds,
                                    disabled: false,
                                    type: 'int'
                                },
                                {
                                    id: 'specialWear',
                                    label: 'Ratio of Turnout Capital Replacement due to Wear',
                                    title: 'What ratio of the total "Turnouts (New) Replaced" are planned for replacement due to approaching or hitting the condemning limit for top, side, or combined wear or due to defects where wear is associated?  ',
                                    value: this.inputs.specialWear,
                                    disabled: false,
                                    type: 'int'
                                },
                                {
                                    id: 'specialOther',
                                    label: 'Ratio of Turnout Capital Replacement due to Other Failure Modes',
                                    title: 'The total amount of your turnout replacements driven by this failure mode.',
                                    value: this.inputs.specialOther,
                                    disabled: true,
                                    type: 'int'
                                }
                            ]
                        }
                    ]
                },
                {
                    title: 'Depreciation',
                    disabled: 'freecashflow',
                    hidden: false,
                    sections: [
                        {
                            title: 'Total Depreciation',
                            fields: [
                                {
                                    id: 'annualDepreciationSavings',
                                    label: 'Annual Depreciation Savings',
                                    title: 'What is the total savings per year achieved by reducing depreciation rate?',
                                    value: this.inputs.annualDepreciationSavings,
                                    disabled: true,
                                    views: ['calculation', 'comparison'],
                                    type: 'currency'
                                },
                                {
                                    id: 'totalRailDepreciation',
                                    label: 'Baseline Depreciation Cost',
                                    title: 'Your total depreciation cost per year across all of your rail assets',
                                    value: this.inputs.totalRailDepreciation,
                                    disabled: true,
                                    type: 'currency'
                                },
                                {
                                    id: 'depreciationCostPerRouteMile',
                                    label: 'Cost/Route Mile',
                                    title: '',
                                    value: this.inputs.depreciationCostPerRouteMile,
                                    disabled: true,
                                    views: [],
                                    hidden: true,
                                    type: 'int'
                                },
                                {
                                    id: 'depreciationCostDueToSurfaceFatigue',
                                    label: 'Baseline Depreciation Costs due to Surface Fatigue',
                                    title: 'The allocation is based on what ratio of your capital replacements are due to this failure mode.',
                                    value: this.inputs.depreciationCostDueToSurfaceFatigue,
                                    disabled: true,
                                    type: 'currency'
                                },
                                {
                                    id:
                                        'depreciationCostDueToSubsurfaceFatigue',
                                    label: 'Baseline Depreciation Costs due to Subsurface Fatigue',
                                    title: 'The allocation is based on what ratio of your capital replacements are due to this failure mode.',
                                    value: this.inputs.depreciationCostDueToSubsurfaceFatigue,
                                    disabled: true,
                                    type: 'currency'
                                },
                                {
                                    id: 'depreciationCostDueToWeldFailures',
                                    label: 'Baseline Depreciation Costs due to Weld Defects',
                                    title: 'The allocation is based on what ratio of your capital replacements are due to this failure mode.',
                                    value: this.inputs.depreciationCostDueToWeldFailures,
                                    disabled: true,
                                    type: 'currency'
                                },
                                {
                                    id: 'depreciationCostDueToWear',
                                    label: 'Baseline Depreciation Costs due to Wear',
                                    title: 'The allocation is based on what ratio of your capital replacements are due to this failure mode.',
                                    value: this.inputs.depreciationCostDueToWear,
                                    disabled: true,
                                    type: 'currency'
                                },
                                {
                                    id: 'depreciationCostDueToOtherFailureModes',
                                    label: 'Baseline Depreciation Costs due to Other Failure Modes',
                                    title: 'The allocation is based on what ratio of your capital replacements are due to this failure mode.',
                                    value: this.inputs.depreciationCostDueToOtherFailureModes,
                                    disabled: true,
                                    type: 'currency'
                                }
                            ]
                        },
                        {
                            title: 'Book Value',
                            fields: [
                                {
                                    id: 'bookValueDiscountMultipleCompareToCurrentPrices',
                                    label: 'Book Value Discount Multiple Compare to Current Prices',
                                    title: 'How much less expensive was average rail cost in the past, versus the average rail cost today?  Depreciation is calculated on the total cost of the "Book Value" of every single piece of rail in a system.  The "Book Value" is assigned based on the total cost of purchasing the rail material and installing it, at the time it was installed.  It\'s not unreasonable to assume that rail that was installed in 1970 would be lower cost (and therefore lower Book Value) than the cost of buying and installing rail today.  Check with your property accounting team on the total book value of their assets on the book and adjust this input appropriately. ',
                                    value: this.inputs
                                        .bookValueDiscountMultipleCompareToCurrentPrices,
                                    disabled: false,
                                    type: 'int'
                                },
                                {
                                    id: 'capitalCostTMPTangentNew',
                                    label: 'Total Book Value of Tangents',
                                    title: 'What is the book value (value in the accounting system) for all of your tangent track that is depreciated',
                                    value: this.inputs.capitalCostTMPTangentNew,
                                    disabled: true,
                                    type: 'currency'
                                },
                                {
                                    id: 'capitalCostTMPTangentCurve',
                                    label: 'Total Book Value of Curves',
                                    title: 'What is the book value (value in the accounting system) for all of your curved track that is depreciated',
                                    value: this.inputs
                                        .capitalCostTMPTangentCurve,
                                    disabled: true,
                                    type: 'currency'
                                },
                                {
                                    id: 'capitalCostTMRTangentRelay',
                                    label: 'Total Book Value of Turnouts',
                                    title: 'What is the book value (value in the accounting system) for all of your relay track that is depreciated',
                                    value: this.inputs
                                        .capitalCostTMRTangentRelay,
                                    disabled: true,
                                    type: 'currency'
                                },
                                {
                                    id: 'totalCapitalInvestedForAssets',
                                    label: 'Total Book Value of Rail Assets',
                                    title: 'The sum of your tangent, curve, and relay book values.',
                                    value: this.inputs
                                        .totalCapitalInvestedForAssets,
                                    disabled: true,
                                    type: 'currency'
                                }
                            ]
                        }
                    ]
                },
                {
                    title: 'Track Maintenance',
                    hidden: false,
                    sections: [
                        {
                            title: 'Summary of Total Track Maintenance Costs',
                            fields: [
                                {
                                    id: 'maintenanceExpenses',
                                    label: 'Total Cost of Track Maintenance',
                                    title: 'The total amount of operational cost to manage your rail defects with spot replacements, inspections, and joint bars.',
                                    value: this.inputs.maintenanceExpenses,
                                    disabled: true,
                                    type: 'currency'
                                },
                                {
                                    id: 'maintenanceTotalCostLabor',
                                    label: 'Total Labor Cost of Track Maintenance',
                                    title: 'The total amount of labor cost to manage your rail defects with spot replacements, inspections, and joint bars.',
                                    value: this.inputs
                                        .maintenanceTotalCostLabor,
                                    disabled: true,
                                    type: 'currency'
                                },
                                {
                                    id: 'maintenanceTotalCostMaterial',
                                    label: 'Total Material Cost of Track Maintenance',
                                    title: 'The total amount of material cost to manage your rail defects with spot replacements, inspections, and joint bars.',
                                    value: this.inputs
                                        .maintenanceTotalCostMaterial,
                                    disabled: true,
                                    type: 'currency'
                                },
                                {
                                    id: 'maintenanceCostDueToSurfaceFatigue',
                                    label: 'Total Maintenance Costs due to Surface Fatigue',
                                    title: 'The allocation is based on what ratio of your track maintenance interventions are due to this failure mode.',
                                    value: this.inputs
                                        .maintenanceCostDueToSurfaceFatigue,
                                    disabled: true,
                                    type: 'currency'
                                },
                                {
                                    id: 'maintenanceCostDueToSubsurfaceFatigue',
                                    label: 'Total Maintenance Costs due to Subsurface Fatigue',
                                    title: 'The allocation is based on what ratio of your track maintenance interventions are due to this failure mode.',
                                    value: this.inputs
                                        .maintenanceCostDueToSubsurfaceFatigue,
                                    disabled: true,
                                    type: 'currency'
                                },
                                {
                                    id: 'maintenanceCostDueToWeldFailures',
                                    label: 'Total Maintenance Costs due to Weld Damage',
                                    title: 'The allocation is based on what ratio of your track maintenance interventions are due to this failure mode.',
                                    value: this.inputs
                                        .maintenanceCostDueToWeldFailures,
                                    disabled: true,
                                    type: 'currency'
                                },
                                {
                                    id: 'maintenanceCostDueToWear',
                                    label: 'Total Maintenance Costs due to Wear ',
                                    title: 'The allocation is based on what ratio of your track maintenance interventions are due to this failure mode.',
                                    value: this.inputs.maintenanceCostDueToWear,
                                    disabled: true,
                                    type: 'currency'
                                },
                                {
                                    id: 'maintenanceCostDueToOtherFailureModes',
                                    label: 'Total Maintenance Costs due to Other Failure Modes',
                                    title: 'The allocation is based on what ratio of your track maintenance interventions are due to this failure mode.',
                                    value: this.inputs.maintenanceCostDueToOtherFailureModes,
                                    disabled: true,
                                    type: 'currency'
                                },
                                {
                                    id: 'totalMaintenanceExpensesTrackAccess',
                                    label: 'Track Access Cost for Maintenance',
                                    title: 'How much does it cost your railroad for track access to perform track maintenance?',
                                    value: this.inputs.totalMaintenanceExpensesTrackAccess,
                                    disabled: true,
                                    type: 'currency'
                                },
                            ]
                        },
                        {
                            title: 'Track Maintenance Plan',
                            fields: [
                                {
                                    id: 'totalSpotReplacements',
                                    label: 'Total Number of Spot Replacements Required',
                                    title: 'How many defects require the existing piece of track to be removed and replaced with a new section of track?  Usually, these spot replacements are done at the same time or shortly after the ultrasonic inspection.  These replacements are often quite short (39\') and are not part of the annual capital replacement plan.',
                                    value: this.inputs.totalSpotReplacements,
                                    disabled: false,
                                    type: 'int'
                                },
                                {
                                    id: 'maintenanceTotalSpotReplacementInFeet',
                                    label: 'Total Spot Replacement (ft)',
                                    title: '',
                                    value: this.inputs.maintenanceTotalSpotReplacementInFeet,
                                    disabled: true,
                                    type: 'int',
                                    hidden: true,
                                },
                                {
                                    id: 'maintenanceTotalSpotReplacementInMiles',
                                    label: 'Total Spot Replacement (miles)',
                                    title: '',
                                    value: this.inputs.maintenanceTotalSpotReplacementInMiles,
                                    disabled: true,
                                    type: 'int',
                                    hidden: true
                                },
                                {
                                    id: 'maintenanceCostPerSpotReplacement',
                                    label: 'Total Cost per Spot Replacement',
                                    title: 'This calculation includes the cost of the spot replacement material, welds, and labor',
                                    value: this.inputs.maintenanceCostPerSpotReplacement,
                                    disabled: true,
                                    type: 'currency'
                                },
                                {
                                    id: 'totalJointBarsApplied',
                                    label: 'Total Number of Joint Bar Applications Required',
                                    title: 'How many defects require the existing piece of track to have joint bars applied to each side of the defect to stabilize the rail and prevent risk of rail breaks? ',
                                    value: this.inputs.totalJointBarsApplied,
                                    disabled: false,
                                    type: 'int'
                                },
                                {
                                    id: 'maintenanceCostPerJointBarApplication',
                                    label: 'Total Cost per Joint Bar Application',
                                    title: 'This calculation includes the cost of the joint bars and labor',
                                    value: this.inputs.maintenanceCostPerJointBarApplication,
                                    disabled: true,
                                    type: 'currency'    
                                },
                                {
                                    id: 'visualInspectionsRequired',
                                    label: 'Total Number of Repeat Visual Inspections Required',
                                    title: 'How many defects require a track worker to return to that location and re-inspect the defect to determine if it changed over time?  The input should only include unplanned inspections.  If the track worker has a scheduled inspection plan, and the re-inspection does not require changing their plan, there would be no additional intervention.',
                                    value: this.inputs.visualInspectionsRequired,
                                    disabled: false,
                                    type: 'int'
                                },
                                {
                                    id: 'maintenanceCostPerVisualInspection',
                                    label: 'Total Cost per Repeat Visual Inspection',
                                    title: 'This calculation includes the cost of additional labor',
                                    value: this.inputs.maintenanceCostPerVisualInspection,
                                    disabled: true,
                                    type: 'currency'
                                },
                                {
                                    id: 'internalInspectionsRequired',
                                    label: 'Total Number of Repeat Ultrasonic Inspections Required',
                                    title: 'How many defects require the ultrasonic inspection vehicle to return to that location and re-inspect the defect to determine if it changed over time or was removed?  The input should only include unplanned inspections.  If the ultrasonic inspection team has a scheduled inspection plan, and the re-inspection does not require changing their plan, there would be no additional intervention.  Often, the repeat ultrasonic inspection will be required if the first inspection showed "NO TEST", and the team is required to send another inspection to that location after grinding.  ',
                                    value: this.inputs.internalInspectionsRequired,
                                    disabled: false,
                                    type: 'int'
                                },
                                {
                                    id: 'maintenanceCostPerInternalInspection',
                                    label: 'Total Cost per Repeat Ultrasonic Inspection',
                                    title: 'This calculation includes the cost of an unplanned visit from the ultrasonic inspection vehicle',
                                    value: this.inputs.maintenanceCostPerInternalInspection,
                                    disabled: true,
                                    type: 'currency'
                                },
                                {
                                    id: 'maintenanceTotalInterventions',
                                    label: 'Total Number of Interventions Required',
                                    title: 'The total number of times that a maintenance team needs to visit locations with track defects for maintenance.',
                                    value: this.inputs.maintenanceTotalInterventions,
                                    disabled: true,
                                    type: 'int'
                                },
                            ]
                        },
                        {
                            title: 'Track Maintenance Labor Costs',
                            fields: [
                                {
                                    id: 'perDiemCostPerDay',
                                    label: 'Per Diem Cost Per Day',
                                    title: '',
                                    value: this.inputs.perDiemCostPerDay,
                                    disabled: false,
                                    type: 'currency',
                                    hidden: true,
                                },
                                {
                                    id: 'numberOfTechniciansPerGang',
                                    label: 'Number of Laborers per Shift',
                                    title: 'How many track workers on average are required to perform a spot replacement, apply a joint bar, or inspect a defect visually?',
                                    value: this.inputs
                                        .numberOfTechniciansPerGang,
                                    disabled: false,
                                    type: 'int'
                                },
                                {
                                    id:
                                        'maintenanceTotalLaborCostPerDayPerGang',
                                    label: 'Total Labor Cost per Shift',
                                    title: 'The total amount of cost for the maintenance team to fulfill their shift including direct labor and overhead.',
                                    value: this.inputs
                                        .maintenanceTotalLaborCostPerDayPerGang,
                                    disabled: true,
                                    type: 'currency'
                                },
                                {
                                    id: 'timePerIntervention',
                                    label: 'Hours Required per Intervention',
                                    title: 'How many hours on average are required to perform a spot replacement, apply a joint bar, or inspect a defect visually once the team arrives at the location?',
                                    value: this.inputs.timePerIntervention,
                                    disabled: false,
                                    type: 'int'
                                },
                                {
                                    id: 'timePerMobilization',
                                    label: 'Hours Required per Mobilization',
                                    title: 'How many hours on average are required to get the team to the location where they will perform the spot replacement?',
                                    value: this.inputs.timePerMobilization,
                                    disabled: false,
                                    type: 'int'
                                },
                                {
                                    id: 'maintenanceTotalInterventionsPerDay',
                                    label: 'Total Interventions per Shift',
                                    title: 'The total number of average spot replacements or joint bar applications that a maintenance team can achieve in one shift.',
                                    value: this.inputs
                                        .maintenanceTotalInterventionsPerDay,
                                    disabled: true,
                                    type: 'int'
                                },
                                {
                                    id: 'maintenanceLaborCostPerIntervention',
                                    label: 'Total Labor Cost per Intervention',
                                    title: 'The total Labor Cost Per Shift divided by the total interventions per shift that can be performed.',
                                    value: this.inputs.maintenanceLaborCostPerIntervention,
                                    disabled: true,
                                    type: 'currency'
                                },
                                {
                                    id: 'maintenanceShiftsRequired',
                                    label: 'Shifts Required for Track Maintenance',
                                    title: 'How many shifts are required to perform the track maintenance required?',
                                    value: this.inputs.maintenanceShiftsRequired,
                                    disabled: true,
                                    type: 'int'
                                },
                                {
                                    id: 'maintenanceTrackAccess',
                                    label: 'Does Track Maintenance Require Track Access?',
                                    title: 'Does your track maintenance team require special track access that disrupts traffic, or can the work be performed between trains?',
                                    value: this.inputs.maintenanceTrackAccess,
                                    type: 'bool'
                                }
                            ]
                        },
                        {
                            title: 'Track Maintenance Material Costs',
                            fields: [
                                {
                                    id: 'averageLengthOfSpotReplacements',
                                    label:
                                        'Average Length of Spot Replacement Rail (ft)',
                                    title: 'How many feet of rail are removed to replace a defect, and how long is the rail string to replace that removed rail?  Usually this length is 39’ but could be lower based on the railroads average rail string length.',
                                        value: this.inputs
                                        .averageLengthOfSpotReplacements,
                                    disabled: false,
                                    type: 'int'
                                },
                                {
                                    id: 'spotReplacementCostPerFoot',
                                    label:
                                        'Average Cost of Spot Replacement Rail per Foot',
                                    title: 'How much does it cost per foot for that string of spot replacement rail?  Some railroads use new rail for spot replacements, and some use used or relay rail.  Relay rail may be free, or it may require some cost for storage and transportation.',
                                        value: this.inputs
                                        .spotReplacementCostPerFoot,
                                    disabled: false,
                                    type: 'currency'
                                },
                                {
                                    id: 'spotReplacementCost',
                                    label: 'Spot Replacement Cost ',
                                    title: '',
                                    value: this.inputs.spotReplacementCost,
                                    disabled: true,
                                    type: 'int',
                                    hidden: true,
                                },
                                {
                                    id: 'costPerWeld',
                                    label: 'Average Cost of Spot Replacement Welds',
                                    title: 'How much does it cost for weld kits to weld both sides (2) of a spot replacement?  If joint bars are used, how much do the 4 joint bars cost?',
                                    value: this.inputs.costPerWeld,
                                    disabled: false,
                                    type: 'currency'
                                },
                                {
                                    id: 'materialCostPerSpotReplacement',
                                    label: 'Total Material Cost of Spot Replacement',
                                    title: 'The average length of spot replacement string multiplied by the average cost per foot of spot replacement rail.',
                                    value: this.inputs
                                        .materialCostPerSpotReplacement,
                                    disabled: true,
                                    type: 'currency'
                                },
                                {
                                    id: 'totalSpotReplacementMaterialCost',
                                    label: 'Total Material Cost',
                                    title: '',
                                    value: this.inputs
                                        .totalSpotReplacementMaterialCost,
                                    disabled: true,
                                    type: 'currency',
                                    hidden: true,
                                },
                                {
                                    id: 'separateWeldTeam',
                                    label: 'Does Spot Replacement Require Separate Welding Team?',
                                    title: 'When your railroad does a spot replacement, do they weld the rail into place?  If welding is used, is that welding performed at the same time as the spot replacement rail is removed and replaced, or is a separate welding team sent later to perform the welding work?  If there is a separate team for welding, the input value is "True”, and it effectively adds more interventions required to send that second team to the same spot replacement to do welding.',
                                    value: this.inputs.separateWeldTeam,
                                    disabled: false,
                                    type: 'bool'
                                },
                                {
                                    id: 'separateGrindTeam',
                                    label: 'Does Spot Replacement Require Separate Grinding Team?',
                                    title: 'When your railroad does a spot replacement, do they perform hand grinding to match the profiles of the spot replacement to the rail around it?  If hand welding is used, is that hand grinding performed at the same time as the spot replacement rail is removed and replaced, or is a separate team sent later to perform the grinding work?  If there is a separate team for hand grinding, the input value is "True”, and it effectively adds more interventions required to send that second team to the same spot replacement.',
                                    value: this.inputs.separateGrindTeam,
                                    disabled: false,
                                    type: 'bool'
                                },
                                {
                                    id: 'jointBarCost',
                                    label: 'Average Cost of Joint Bars per Application',
                                    title: 'What is the cost for your railroad to buy joint bars, and how many are applied each time?  For example, if the joint bar is $50 and 4 joint bars are applied around a defect for each Joint Bar Application, the input would be $100.',
                                    value: this.inputs.jointBarCost,
                                    disabled: false,
                                    type: 'currency'
                                },
                                {
                                    id: 'totalJointBarCost',
                                    label: 'Total Joint Bar Cost',
                                    title: '',
                                    value: this.inputs.totalJointBarCost,
                                    disabled: true,
                                    type: 'int',
                                    hidden: true,
                                },
                                {
                                    id: 'supportVehicleCostPerHour',
                                    label: 'Support Vehicle Cost Per Hour',
                                    title: '',
                                    value: this.inputs.supportVehicleCostPerHour,
                                    disabled: false,
                                    type: 'int',
                                    hidden: true,
                                },
                                {
                                    id: 'specialTrackWorkReplacedFrogWeld',
                                    label: 'Average Cost of Frog Repair Weld',
                                    title: 'how many turnout frogs are planned to be weld repaired this year?',
                                    value: this.inputs.specialTrackWorkReplacedFrogWeld,
                                    disabled: false,
                                    type: 'currency',
                                    hidden: true,
                                },
                            ]
                        },
                        {
                            title: 'Track Maintenance Corrective Spending by Root Cause',
                            fields: [
                                {
                                    id: 'surfaceFatigue',
                                    label: 'Ratio of Track Maintenance Interventions due to Surface Fatigue Defects',
                                    title: 'What ratio of the total defects were caused by surface fatigue cracks?  The definition of what defects are caused by surface fatigue cracks vary by railroad, but often include transverse detail defects (TDD)s, shelling spalling, and corrugation defect (SSCs) and associated defects.  ',
                                    value: this.inputs.surfaceFatigue,
                                    disabled: false,
                                    type: 'int'
                                },
                                {
                                    id: 'subsurfaceFatigue',
                                    label: 'Ratio of Track Maintenance Interventions due to Subsurface Fatigue Defects',
                                    title: 'What ratio of the total defects that were caused by fatigue cracks that started in the subsurface of the rail?  The definition of what defects are caused by surface fatigue cracks vary by railroad, but often include defects that have an initiation point inside the rail head instead of on top of the rail head.  ',
                                    value: this.inputs.subsurfaceFatigue,
                                    disabled: false,
                                    type: 'int'
                                },
                                {
                                    id: 'welds',
                                    label: 'Ratio of Track Maintenance Interventions due to Weld Defects',
                                    title: 'What ratio of defects that were caused weld damage, breaks, or dipped welds?  ',
                                    value: this.inputs.welds,
                                    disabled: false,
                                    type: 'int'
                                },
                                {
                                    id: 'wear',
                                    label: 'Ratio of Track Maintenance Interventions due to Wear',
                                    title: 'What ratio of defects are caused by the rail head wearing incorrectly?  ',
                                    value: this.inputs.wear,
                                    disabled: false,
                                    type: 'int'
                                },
                                {
                                    id: 'other',
                                    label: 'Ratio of Track Maintenance Interventions due to Other Failure Modes',
                                    title: 'What ratio of defects are caused by the other failure modes?',
                                    value: this.inputs.other,
                                    disabled: true,
                                    type: 'int'
                                }
                            ]
                        }
                    ]
                },
                {
                    title: 'Grinding',
                    hidden: false,
                    sections: [ 
                        {
                            title: 'Summary of Total Grinding Costs',
                            fields: [
                                {
                                    id: 'allGrindingTotalCost',
                                    label: 'Total Cost of Grinding',
                                    title: 'What is the total cost of production and S&C grinding?',
                                    value: this.inputs.allGrindingTotalCost,
                                    disabled: true,
                                    type: 'currency'
                                },
                                {
                                    id: 'productionGrindingTotalCost',
                                    label: 'Total Cost of Production Grinding',
                                    title: 'What is the total cost of production grinding?',
                                    value: this.inputs.productionGrindingTotalCost,
                                    disabled: true,
                                    type: 'currency'
                                },
                                {
                                    id: 'grindingEquipmentTotalCostAll',
                                    label: 'Total Equipment Cost of Grinding',
                                    title: 'The total cost paid to contractor and depreciation for grinding services.',
                                    value: this.inputs.grindingEquipmentTotalCostAll,
                                    disabled: true,
                                    type: 'currency'
                                },
                                {
                                    id: 'grindingLaborCostTotalAll',
                                    label: 'Total Labor Cost of Grinding',
                                    title: 'The total cost paid for grinding labor not covered by the contractor.',
                                    value: this.inputs.grindingLaborCostTotalAll,
                                    disabled: true,
                                    type: 'currency'
                                },
                                {
                                    id: 'grindingTotalInspectionCosts',
                                    label: 'Total Cost of Pre-Grind Inspections',
                                    title: 'The total cost of pre-grind inspections.',
                                    value: this.inputs.grindingTotalInspectionCosts,
                                    disabled: true,
                                    type: 'currency'
                                },
                                {
                                    id: 'grindingMaterialCostTotalAll',
                                    label: 'Total Material Cost of Grinding',
                                    title: 'The total cost paid for grinding materials not covered by the contractor.',
                                    value: this.inputs.grindingMaterialCostTotalAll,
                                    disabled: true,
                                    type: 'currency'
                                },
                                {
                                    id: 'totalProductionGrindingExpensesTrackAccess',
                                    label: 'Track Access Cost for Production Grinding',
                                    title: 'How much does it cost your railroad for track access to perform production grinding?',
                                    value: this.inputs.totalProductionGrindingExpensesTrackAccess,
                                    type: 'currency',
                                    disabled: true,
                                },
                            ]
                        },
                        {
                            title: 'Grinding Plan',
                            fields: [
                                {
                                    id: 'preventiveGrindingTrackMiles',
                                    label: 'Total Preventive Track Miles Ground',
                                    title: 'How many miles of preventive production grinding are expected to be performed on tangent (straight) track within the grinding plan?  The definition of tangent track should be the same as your definition under the "General Assumptions" tab for "Tangent Track - Ratio of Network."  Railroads define preventive grinding differently, but it often is defined as either grinding where you do only one pass (to improve the profile and remove surface cracks) or is grinding that is not specifically trying to remove a defect at that location.',
                                    value: this.inputs.preventiveGrindingTrackMiles,
                                    disabled: false,
                                    type: 'int'
                                },
                                {
                                    id: 'correctiveGrindingTrackMiles',
                                    label: 'Total Corrective Track Miles Ground',
                                    title: 'How many miles of corrective production grinding as expected to be performed on the entire network / scope in your grinding plan?  Railroads define corrective grinding differently, but it often is defined as either grinding where you do more than one pass (to improve the profile and remove surface cracks) or grinding that is planned specifically to remove a defect at that location.',
                                    value: this.inputs.correctiveGrindingTrackMiles,
                                    disabled: false,
                                    type: 'int'
                                },
                                {
                                    id: 'grindingTotalPassMiles',
                                    label: 'Total Production Pass Miles Ground',
                                    title: 'The total pass miles of your network that are planned for grinding. This represents the preventive track miles multiplied by the average number of preventive passes, and the correct track miles multiplied by the average number of corrective passes.',
                                    value: this.inputs.grindingTotalPassMiles,
                                    disabled: true,
                                    type: 'int'
                                },
                                {
                                    id: 'correctiveGrinding',
                                    label: 'All (Corrective) Track Miles Ground',
                                    title: 'How many miles of corrective grinding as expected to be performed on the entire network / scope in your grinding plan?  Railroads define corrective grinding differently, but it often is defined as either grinding where you do more than one pass (to improve the profile and remove surface cracks) or grinding that is planned specifically to remove a defect at that location.',
                                    value: this.inputs.correctiveGrinding,
                                    disabled: false,
                                    hidden: true,
                                    type: 'int'
                                },
                                {
                                    id: 'grindingTrackAccess',
                                    label: 'Does Grinding Require Track Access?',
                                    title: 'Does your grinding require special track access that disrupts traffic, or can the work be performed between trains?',
                                    value: this.inputs.grindingTrackAccess,
                                    type: 'bool'
                                },
                            ]
                        },
                        {
                            title: 'Production Grinding Equipment Cost',
                            fields: [
                                {
                                    id: 'leaseCostPerShift',
                                    label: 'Lease Cost per Production Shift',
                                    title: 'How much do you pay your grinding provider on average per shift of production grinding?',
                                    value: this.inputs.leaseCostPerShift,
                                    disabled: false,
                                    type: 'currency'
                                },
                                {
                                    id: 'leaseCostPerPassMile',
                                    label: 'Lease Cost per Production Pass Mile Ground',
                                    title: '',
                                    value: this.inputs.leaseCostPerPassMile,
                                    disabled: false,
                                    type: 'currency'
                                },
                                {
                                    id: 'grindingEquipmentTotalCost',
                                    label: 'Total Equipment Cost of Production Grinding',
                                    title: 'The total cost paid to contractor and depreciation for production grinding services.',
                                    value: this.inputs.grindingEquipmentTotalCost,
                                    disabled: true,
                                    type: 'currency'
                                },
                                {
                                    id: 'numberOfOwnedGrinders',
                                    label: 'Number of Production Grinders Owned',
                                    title: 'How many production grinders do you own?',
                                    value: this.inputs.numberOfOwnedGrinders,
                                    disabled: false,
                                    type: 'int'
                                },
                                {
                                    id: 'ratioShiftsOfOwnedGrinders',
                                    label: 'Ratio of Shifts from Leased Production Grinding Services',
                                    title: 'How many of your grinding shifts are covered by owned grinders?',
                                    value: this.inputs.ratioShiftsOfOwnedGrinders,
                                    disabled: false,
                                    type: 'int'
                                },
                                {
                                    id: 'ratioPassMilesOfOwnedGrinders',
                                    label: 'Ratio of Pass Miles from Leased Production Grinding Services',
                                    title: 'How many of your grinding pass miles are covered by owned grinders?',
                                    value: this.inputs.ratioPassMilesOfOwnedGrinders,
                                    disabled: false,
                                    type: 'int'
                                },
                                {
                                    id: 'capitalCostPerGrinder',
                                    label: 'Capital Cost per Production Grinder',
                                    title: 'How much does it cost to buy a new production grinder with all costs included?',
                                    value: this.inputs.capitalCostPerGrinder,
                                    disabled: false,
                                    type: 'currency'
                                },
                                {
                                    id: 'GrinderUsefulLife',
                                    label: 'Useful Life per Production Grinder (Years)',
                                    title: 'How many years do you expect to be able to run the grinder effectively until you need to replace it? ',
                                    value: this.inputs.GrinderUsefulLife,
                                    disabled: false,
                                    type: 'int'
                                },
                                {
                                    id: 'depreciationPerGrinder',
                                    label: 'Depreciation Cost per Grinder per Year',
                                    title: 'How much depreciation is incurred per year by owning the production grinders?',
                                    value: this.inputs.depreciationPerGrinder,
                                    disabled: true,
                                    type: 'currency'
                                },
                                {
                                    id: 'leaseCostPerTravelDay',
                                    label: 'Lease Cost per Production Travel Day',
                                    title: 'How much do you pay your grinding provider on average per shift of production grinding?',
                                    value: this.inputs.leaseCostPerTravelDay,
                                    disabled: false,
                                    type: 'currency'
                                },
                            ]
                        },
                        {
                            title: 'Labor Costs of Grinding',
                            fields: [
                                {
                                    id: 'grindingLaborersPerShift',
                                    label: 'Number of Laborers per Production Shift',
                                    title: 'How many total people from your railroad are required per shift to support the production grinding efforts? These support staff could include supervisors, pilots, fire suppression teams, among other roles.  Any labor provided by the contractor is not included in this calculation.',
                                    value: this.inputs.grindingLaborersPerShift,
                                    disabled: false,
                                    type: 'int'
                                },
                                {
                                    id: 'grindingOvertimeHours',
                                    label: 'Number of Overtime Hours Required per Production Shift',
                                    title: 'How many additional hours per production shift of labor are required on average ABOVE the "Hours of Labor per Shift" calculation from the General Assumptions tab.',
                                    value: this.inputs.grindingOvertimeHours,
                                    disabled: false,
                                    type: 'int'
                                },
                                {
                                    id: 'maintenanceDaysPerShifts',
                                    label: 'Ratio of Grinder Maintenance Shifts Required to Grinding Shifts',
                                    title: 'How many shifts of maintenance does the grinder require for each shift of grinding provided?',
                                    value: this.inputs.maintenanceDaysPerShifts,
                                    disabled: false,
                                    type: 'int'
                                },
                                {
                                    id: 'totalMaintenanceShifts',
                                    label: 'Total Grinder Maintenance Shifts Required',
                                    title: 'The total number of shifts required for grinding maintenance.',
                                    value: this.inputs.totalMaintenanceShifts,
                                    disabled: true,
                                    type: 'int'
                                },
                                {
                                    id: 'maintenanceLaborCostPercentComparedToLabor',
                                    label: 'Ratio of Grinder Maintenance Labor Cost Compared to Grinding Labor Cost',
                                    title: 'How much does grinding maintenance labor compare to the cost of the team who performs the grinding?',
                                    value: this.inputs.maintenanceLaborCostPercentComparedToLabor,
                                    disabled: false,
                                    type: 'int'
                                },
                                {
                                    id: 'laborCostPerMaintenanceDay',
                                    label: 'Total Grinder Maintenance Labor Cost per Shift',
                                    title: 'How much does grinding maintenance cost per shift?',
                                    value: this.inputs.laborCostPerMaintenanceDay,
                                    disabled: true,
                                    type: 'currency'
                                },
                                {
                                    id: 'grindingLaborCostTotal',
                                    label: 'Total Labor Cost of Production Grinding',
                                    title: 'What is the total labor costly of S&C grinding?',
                                    value: this.inputs.grindingLaborCostTotal,
                                    disabled: true,
                                    type: 'currency'
                                },
                            ]
                        },
                        {
                            title: 'Grinding Efficiency',
                            fields: [
                                {
                                    id: 'averageGrindingSpeed',
                                    label: 'Average Production Grinding Speed',
                                    title: 'How quickly (in distance per hour) can the production grinder move while it is performing grinding?',
                                    value: this.inputs.averageGrindingSpeed,
                                    disabled: false,
                                    type: 'int'
                                },
                                {
                                    id: 'grinderPassesPreventive',
                                    label: 'Average Number of Passes (Preventive)',
                                    title: 'How many passes on average are planned when a production grinder is performing preventive grinding?',
                                    value: this.inputs.grinderPassesPreventive,
                                    disabled: false,
                                    type: 'int'
                                },
                                {
                                    id: 'grinderSpeedPreventive',
                                    label: 'Average Track Miles Ground per Hour (Preventive)',
                                    title: 'The average speed that grinder can perform at while performing preventive grinding.',
                                    value: this.inputs.grinderSpeedPreventive,
                                    disabled: true,
                                    type: 'int'
                                },
                                {
                                    id: 'grinderPassesCorrective',
                                    label: 'Average Number of Passes (Corrective)',
                                    title: 'The average speed that grinder can perform at while performing corrective grinding.',
                                    value: this.inputs.grinderPassesCorrective,
                                    disabled: false,
                                    type: 'int'
                                },
                                {
                                    id: 'grinderSpeedCorrective',
                                    label: 'Average Track Miles Ground per Hour (Corrective)',
                                    title: 'The average speed that grinder can perform at while performing corrective grinding.',
                                    value: this.inputs.grinderSpeedCorrective,
                                    disabled: true,
                                    type: 'int'
                                },
                                {
                                    id: 'grindingShiftHours',
                                    label: 'Hours per Production Grinding Shift',
                                    title: 'How many hours are required for standard production grinding shift?',
                                    value: this.inputs.grindingShiftHours,
                                    disabled: false,
                                    type: 'int',
                                },
                                {
                                    id: 'grindingTrainDelayHours',
                                    label: 'Production Shift Hours Used by Transportation (Train Delay)',
                                    title: 'How many hours are unavailable for production grinding per shift because the track is used for passing trains?  Often, the grinding team is required to spend a lot of time changing tracks or going to a siding in order to allow trains to be pass, so that they are not slowing down traffic.',
                                    value: this.inputs.grindingTrainDelayHours,
                                    disabled: false,
                                    type: 'int'
                                },
                                {
                                    id: 'grindingMobilizationHours',
                                    label: 'Production Shift Hours Used by Mobilization',
                                    title: 'How many hours are unavailable for production grinding per shift because they are traveling to the location where they will perform grinding for that shift, and the time required for them to move back to the location where they will \'tie up\' at the end of the shift and leave the grinder?',
                                    value: this.inputs.grindingMobilizationHours,
                                    disabled: false,
                                    type: 'int'
                                },
                                {
                                    id: 'grindingOtherDelayHours',
                                    label: 'Production Shift Hours Used by Other Delays',
                                    title: 'How many hours are unavailable for production grinding per shift due to other delays?',
                                    value: this.inputs.grindingOtherDelayHours,
                                    disabled: false,
                                    type: 'int'
                                },
                                {
                                    id: 'averagePerShiftSparkTime',
                                    label: 'Spark Time Hours per Shift',
                                    title: 'The total amount of time per shift when grinding is actually performed.  This represents the Shift Hours Available for Grinding subtracted by hours of delays due to mobilization and other downtime.',
                                    value: this.inputs.averagePerShiftSparkTime,
                                    disabled: true,
                                    type: 'int'
                                },
                                {
                                    id: 'grindTimePerCorrectivePAndC',
                                    label: 'Spark Time Hours Required for Plan (Corrective)',
                                    title: 'The total number of spark time hours required to execute your corrective grind plan.',
                                    value: this.inputs.grindTimePerCorrectivePAndC,
                                    disabled: true,
                                    hidden: true,
                                    type: 'int'
                                },
                                {
                                    id: 'customerDelayMachineDelayContractorDelay',
                                    label: 'Shift Hours Used by Other Delays',
                                    title: 'How many hours are unavailable for grinding per shift due to other delays?',
                                    value: this.inputs.customerDelayMachineDelayContractorDelay,
                                    disabled: false,
                                    hidden: true,
                                    type: 'int'
                                },
                                {
                                    id: 'grindingShiftsRequired',
                                    label: 'Total Shifts Required  for Production Grinding',
                                    title: 'The total number of production shifts required to achieve your grind plan\'s required spark time. This represents the required preventive and corrective spark time divided by the spark time hours per shift.',
                                    value: this.inputs.grindingShiftsRequired,
                                    disabled: true,
                                    type: 'int'
                                },
                                {
                                    id: 'grindingTravelDays',
                                    label: 'Total Production Travel Days',
                                    title: 'How many days are the production grinders unavaliable due to travel?',
                                    value: this.inputs.grindingTravelDays,
                                    disabled: false,
                                    type: 'int'
                                },
                                {
                                    id: 'grindingShiftsRequiredTotal',
                                    label: 'Total Shifts Required to Execute Production Grind Plan',
                                    title: 'What are the total number of shifts required to perform this production grind plan (counting travel days)?',
                                    value: this.inputs.grindingShiftsRequiredTotal,
                                    disabled: true,
                                    type: 'int'
                                },
                            ]
                        },
                        {
                            title: 'Material Costs of Grinding',
                            fields: [
                                {
                                    id: 'grindingMaterialCostPerShift',
                                    label: 'Material Cost per Production Shift',
                                    title: 'What is the total cost per shift of all materials that are fixed cost no matter how many miles of production grinding as performed each shift? Water for fire suppression may be one example, since the fire suppression needs to happen even if the grinding only performs one mile of grinding.',
                                    value: this.inputs.grindingMaterialCostPerShift,
                                    disabled: false,
                                    type: 'currency'
                                },
                                {
                                    id: 'grindingMaterialCostPerPassMile',
                                    label: 'Material Cost per Production Pass Mile Ground',
                                    title: 'What is the total cost per shift of all materials that variable depending on the number of pass miles per production shift?',
                                    value: this.inputs.grindingMaterialCostPerPassMile,
                                    disabled: false,
                                    type: 'currency'
                                },
                                {
                                    id: 'grindingMaterialCostPerMaintenanceShift',
                                    label: 'Material Cost per Maintenance Shift',
                                    title: 'What is the total cost per shift of all materials that are fixed cost no matter how many miles of production grinding as performed each shift? Water for fire suppression may be one example, since the fire suppression needs to happen even if the grinding only performs one mile of grinding.',
                                    value: this.inputs.grindingMaterialCostPerMaintenanceShift,
                                    disabled: false,
                                    type: 'currency'
                                },
                                {
                                    id: 'grindingMaterialCostTotal',
                                    label: 'Total Production Grinding Material Cost',
                                    title: 'What is the total material cost for production grinding?',
                                    value: this.inputs.grindingMaterialCostTotal,
                                    disabled: true,
                                    type: 'currency'
                                },
                                {
                                    id: 'energySavingsPerGrindMile',
                                    label: 'Energy / Fuel Savings per Preventive Grinding Mile',
                                    title: 'What is the expected savings of fuel economy or energy efficiency, in dollars, from performing a track mile of grinding?',
                                    value: this.inputs.energySavingsPerGrindMile,
                                    disabled: false,
                                    type: 'currency'
                                },
                                {
                                    id: 'energySavingsGrindingTotal',
                                    label: 'Total Energy / Fuel Savings from Preventive Grinding',
                                    title: 'What is the total expected savings of fuel economy or energy efficiency, in dollars, from the grinding program?',
                                    value: this.inputs.energySavingsGrindingTotal,
                                    disabled: true,
                                    type: 'currency'
                                },
                            ]
                        },
                        {
                            title: 'Pre-Grind Inspection Cost',
                            fields: [
                                {
                                    id: 'inspectionCostPerWorkDay',
                                    label: 'Pre-Grind Inspection Cost per Shift',
                                    title: 'How much does it cost for pre-grind inspection contract per shift on average?',
                                    value: this.inputs.inspectionCostPerWorkDay,
                                    disabled: false,
                                    type: 'currency'
                                },
                                {
                                    id: 'inspectionsPerGrinder',
                                    label: 'Ratio of Pre-Grind Inspection Shifts to Grinding Shifts',
                                    title: 'How many shifts of pre-grinding inspection are required for each shift of grinding?  For example, if you do one pre-grind inspection before every shift of grinding, the input would be 1.  If you perform pre-grinding inspection once a week before 5 shifts of grinding, the input would be 0.2.  If you perform 10 shifts of pre-grind inspection in total per year, and your "Total Shifts Required to Execute Grind Plan" calculation under the grinding tab is equal to 100, the input would be 0.1.',
                                    value: this.inputs.inspectionsPerGrinder,
                                    disabled: false,
                                    type: 'int'
                                }
                            ]
                        }
                    ]
                },
                {
                    title: 'S&C Grinding',
                    hidden: false,
                    sections: [
                        {
                            title: 'Summary of Total S&C Grinding Costs',
                            fields: [
                                {
                                    id: 'SCgrindingTotalCost',
                                    label: 'Total Cost of S&C Grinding',
                                    title: 'What is the total cost of S&C grinding?',
                                    value: this.inputs.SCgrindingTotalCost,
                                    disabled: true,
                                    type: 'currency'
                                },
                                {
                                    id: 'totalSCGrindingExpensesTrackAccess',
                                    label: 'Track Access Cost for S&C Grinding',
                                    title: 'How much does it cost your railroad for track access to perform switch and crossing grinding?',
                                    value: this.inputs.totalSCGrindingExpensesTrackAccess,
                                    type: 'currency',
                                    disabled: true,
                                },
                            ],
                        },
                        {
                            title: 'S&C Equipment Cost',
                            fields: [
                                {
                                    id: 'leaseCostPerSCShift',
                                    label: 'Lease Cost per S&C Shift',
                                    title: 'How much do you pay your grinding provider on average per shift of S&C grinding?',
                                    value: this.inputs.leaseCostPerSCShift,
                                    disabled: false,
                                    type: 'currency'
                                },
                                {
                                    id: 'leaseCostPerSCSparkHour',
                                    label: 'Lease Cost per S&C Spark Hour Ground',
                                    title: 'How much do you pay your grinding provider on average per pass mile of S&C grinding that they complete?',
                                    value: this.inputs.leaseCostPerSCSparkHour,
                                    disabled: false,
                                    type: 'currency'
                                },
                                {
                                    id: 'leaseCostPerSCTravelDay',
                                    label: 'Lease Cost per S&C Travel Day',
                                    title: 'How much do you pay your grinding provider on average per travel day of S&C grinding?',
                                    value: this.inputs.leaseCostPerSCTravelDay,
                                    disabled: false,
                                    type: 'currency'
                                },
                                {
                                    id: 'SCgrindingEquipmentTotalCost',
                                    label: 'Total S&C Grinding Equipment Cost',
                                    title: 'How much in total does it cost to lease or own your S&C grinders?',
                                    value: this.inputs.SCgrindingEquipmentTotalCost,
                                    disabled: true,
                                    type: 'currency'
                                },
                                {
                                    id: 'ratioShiftsOfOwnedSCGrinders',
                                    label: 'Ratio of Shifts from Leased S&C Grinding Services',
                                    title: 'How many of your S&C grinding shifts are covered by owned grinders?',
                                    value: this.inputs.ratioShiftsOfOwnedSCGrinders,
                                    disabled: false,
                                    type: 'int'
                                },
                                {
                                    id: 'ratioPassMilesOfOwnedSCGrinders',
                                    label: 'Ratio of Spark Hours from Leased S&C Grinding Services',
                                    title: 'How many of your S&C grinding hours are covered by owned grinders?',
                                    value: this.inputs.ratioPassMilesOfOwnedSCGrinders,
                                    disabled: false,
                                    type: 'int'
                                },
                                {
                                    id: 'numberOfOwnedSCGrinders',
                                    label: 'Number of S&C Grinders Owned',
                                    title: 'How many S&C grinders do you own?',
                                    value: this.inputs.numberOfOwnedSCGrinders,
                                    disabled: false,
                                    type: 'int'
                                },
                                {
                                    id: 'capitalCostPerSCGrinder',
                                    label: 'Capital Cost per S&C Grinder',
                                    title: 'How much does it cost to buy a new S&C grinder with all costs included?',
                                    value: this.inputs.capitalCostPerSCGrinder,
                                    disabled: false,
                                    type: 'currency'
                                },
                                {
                                    id: 'SCGrinderUsefulLife',
                                    label: 'Useful Life per S&C Grinder (Years)',
                                    title: 'How many years do you expect to be able to run the S&C grinder effectively until you need to replace it?',
                                    value: this.inputs.SCGrinderUsefulLife,
                                    disabled: false,
                                    type: 'int'
                                },
                                {
                                    id: 'depreciationPerSCGrinder',
                                    label: 'Depreciation Cost per S&C Grinder per Year',
                                    title: 'How much depreciation is incurred per year by owning the S&C grinders?',
                                    value: this.inputs.depreciationPerSCGrinder,
                                    disabled: true,
                                    type: 'currency'
                                },
                            ],
                        },
                        {
                            title: 'S&C Grinding Efficiency',
                            fields: [
                                {
                                    id: 'SCgrinderPassesCorrective',
                                    label: 'Average Number of Passes (S&C)',
                                    title: 'How many passes on average are planned when a S&C grinder is performing grinding?',
                                    value: this.inputs.SCgrinderPassesCorrective,
                                    type: 'int'
                                },
                                {
                                    id: 'SCgrindingSparkMinutesSwitch',
                                    label: 'S&C Spark Minutes Required per Switch Pass',
                                    title: 'How many operating minutes are required pass S&C grinding pass on a switch?',
                                    value: this.inputs.SCgrindingSparkMinutesSwitch,
                                    type: 'int'
                                },
                                {
                                    id: 'SCgrindingSparkMinutesCrossing',
                                    label: 'S&C Spark Minutes Required per Crossing Pass',
                                    title: 'How many operating minutes are required pass S&C grinding pass on a crossing?',
                                    value: this.inputs.SCgrindingSparkMinutesCrossing,
                                    type: 'int'
                                },
                                {
                                    id: 'SCgrindingSparkMinutesHotBox',
                                    label: 'S&C Spark Minutes Required per Hot Box Detector Pass',
                                    title: 'How many operating minutes are required pass S&C grinding pass on a hot box detector?',
                                    value: this.inputs.SCgrindingSparkMinutesHotBox,
                                    type: 'int'
                                },
                                {
                                    id: 'SCgrindingSparkMinutesWayside',
                                    label: 'S&C Spark Minutes Required per Wayside Lubricators Pass',
                                    title: 'How many operating minutes are required pass S&C grinding pass on a wayside system?',
                                    value: this.inputs.SCgrindingSparkMinutesWayside,
                                    type: 'int'
                                },
                                {
                                    id: 'SCgrindingSparkMinutesMisc',
                                    label: 'S&C Spark Minutes Required per Miscellaneous Special Track Ground Pass',
                                    title: 'How many operating minutes are required pass S&C grinding pass on other special track?',
                                    value: this.inputs.SCgrindingSparkMinutesMisc,
                                    type: 'int'
                                },
                                {
                                    id: 'SCgrindingSparkMinutesCurve',
                                    label: 'S&C Spark Minutes Required for Curve Pass',
                                    title: 'How many operating minutes are required pass S&C grinding pass on a curve?',
                                    value: this.inputs.SCgrindingSparkMinutesCurve,
                                    type: 'int'
                                },
                                {
                                    id: 'SCgrindingSparkMinutesTangent',
                                    label: 'S&C Spark Minutes Required for Tangent Pass',
                                    title: 'How many operating minutes are required pass S&C grinding pass on a tangent segment?',
                                    value: this.inputs.SCgrindingSparkMinutesTangent,
                                    type: 'int'
                                },
                                {
                                    id: 'SCgrindingEfficiencyRatio',
                                    label: 'Ratio of S&C Operating Minutes to Spark Minutes',
                                    title: 'What percentage of S&C operating minutes are spark time?',
                                    value: this.inputs.SCgrindingEfficiencyRatio,
                                    type: 'int'
                                },
                                {
                                    id: 'SCGrindingSparkHours',
                                    label: 'Spark Hours Required for S&C Plan',
                                    title: 'What are the total number of spark hours required to perform this S&C grind plan (without counting travel days)?',
                                    value: this.inputs.SCGrindingSparkHours,
                                    disabled: true,
                                    type: 'int'
                                },
                                {
                                    id: 'SCgrindingShiftHours',
                                    label: 'Hours per S&C Grinding Shift',
                                    title: 'How many hours are required for standard S&C grinding shift?',
                                    value: this.inputs.SCgrindingShiftHours,
                                    type: 'int'
                                },
                                {
                                    id: 'SCgrindingTrainDelayHours',
                                    label: 'S&C Shift Hours Used by Transportation (Train Delay)',
                                    title: 'How many hours are unavailable for S&C grinding per shift because the track is used for passing trains?  Often, the grinding team is required to spend a lot of time changing tracks or going to a siding in order to allow trains to be pass, so that they are not slowing down traffic.',
                                    value: this.inputs.SCgrindingTrainDelayHours,
                                    type: 'int'
                                },
                                {
                                    id: 'SCgrindingMobilizationHours',
                                    label: 'S&C Shift Hours Used by Mobilization',
                                    title: 'How many hours are unavailable for S&C grinding per shift because they are traveling to the location where they will perform grinding for that shift, and the time required for them to move back to the location where they will \'tie up\' at the end of the shift and leave the grinder?',
                                    value: this.inputs.SCgrindingMobilizationHours,
                                    type: 'int'
                                },
                                {
                                    id: 'SCgrindingOtherDelayHours',
                                    label: 'S&C Shift Hours Used by Other Delays',
                                    title: 'How many hours are unavailable for S&C grinding per shift due to other delays?',
                                    value: this.inputs.SCgrindingOtherDelayHours,
                                    type: 'int'
                                },
                                {
                                    id: 'SCSparkTimeperShift',
                                    label: 'S&C Grinding Spark Time Hours per Shift',
                                    title: 'What is the total spark time that is achieved per S&C grinding shift?',
                                    value: this.inputs.SCSparkTimeperShift,
                                    disabled: true,
                                    type: 'int'
                                },
                                {
                                    id: 'SCgrindingShiftsRequired',
                                    label: 'Total Shifts Required for S&C Grinding',
                                    title: 'What are the total number of shifts required to perform this S&C grind plan (without counting travel days)?',
                                    value: this.inputs.SCgrindingShiftsRequired,
                                    disabled: true,
                                    type: 'int'
                                },
                                {
                                    id: 'SCgrindingTravelDays',
                                    label: 'Total S&C Travel Days',
                                    title: 'How many days are the S&C grinders unavailable due to travel?',
                                    value: this.inputs.SCgrindingTravelDays,
                                    type: 'int'
                                },
                                {
                                    id: 'SCgrindingShiftsRequiredTotal',
                                    label: 'Total Shifts Required to Execute S&C Grind Plan',
                                    title: 'What are the total number of shifts required to perform this S&C grind plan (counting travel days)?',
                                    value: this.inputs.SCgrindingShiftsRequiredTotal,
                                    disabled: true,
                                    type: 'int'
                                },
                            ],
                        },
                        {
                            title: 'S&C Grinding Plan',
                            fields: [
                                {
                                    id: 'SCGrindingSwitches',
                                    label: 'Total Switches Ground',
                                    title: 'How many switches are expected to be ground?',
                                    value: this.inputs.SCGrindingSwitches,
                                    disabled: false,
                                    type: 'int'
                                },
                                {
                                    id: 'SCGrindingSwitchesUnitsPer',
                                    label: 'Average Number of Units Ground per Switch',
                                    title: 'How many units are expected to be ground on average per switch?',
                                    value: this.inputs.SCGrindingSwitchesUnitsPer,
                                    disabled: false,
                                    type: 'int'
                                },
                                {
                                    id: 'SCGrindingSwitchesUnitsTotal',
                                    label: 'Total Switch Units Ground',
                                    title: 'How many total switch units are expected to be ground?',
                                    value: this.inputs.SCGrindingSwitchesUnitsTotal,
                                    disabled: true,
                                    type: 'int'
                                },
                                {
                                    id: 'SCGrindingCrossings',
                                    label: 'Total Crossings Ground',
                                    title: 'How many road crossings are expected to be ground?',
                                    value: this.inputs.SCGrindingCrossings,
                                    disabled: false,
                                    type: 'int'
                                },
                                {
                                    id: 'SCGrindingHotBox',
                                    label: 'Total Hot Box / AEI Detectors Ground',
                                    title: 'How many hot box detectors are expected to be ground?',
                                    value: this.inputs.SCGrindingHotBox,
                                    disabled: false,
                                    type: 'int'
                                },
                                {
                                    id: 'SCGrindingWayside',
                                    label: 'Total Lubricators Ground',
                                    title: 'How many wayside lubricators or other systems are expected to be ground?',
                                    value: this.inputs.SCGrindingWayside,
                                    disabled: false,
                                    type: 'int'
                                },
                                {
                                    id: 'SCGrindingMisc',
                                    label: 'Total Miscellaneous Special Track Ground',
                                    title: 'How many other track work are expected to be ground?',
                                    value: this.inputs.SCGrindingMisc,
                                    disabled: false,
                                    type: 'int'
                                },
                                {
                                    id: 'SCGrindingCurves',
                                    label: 'Total S&C Curves Ground',
                                    title: 'How many curves work are expected to be ground with S&C grinder?',
                                    value: this.inputs.SCGrindingCurves,
                                    disabled: false,
                                    type: 'int'
                                },
                                {
                                    id: 'SCGrindingTangents',
                                    label: 'Total S&C Tangents Ground',
                                    title: 'How many tangents work are expected to be ground with S&C grinder?',
                                    value: this.inputs.SCGrindingTangents,
                                    disabled: false,
                                    type: 'int'
                                },
                                {
                                    id: 'SCGrindingUnitsTotal',
                                    label: 'Total S&C Units Ground',
                                    title: 'How many units in total are expected to be ground with S&C grinder?',
                                    value: this.inputs.SCGrindingUnitsTotal,
                                    disabled: true,
                                    type: 'int'
                                },
                                {
                                    id: 'SCGrindingPassesTotal',
                                    label: 'Total S&C Passes Ground',
                                    title: 'How many passes in total are expected to be ground with S&C grinder?',
                                    value: this.inputs.SCGrindingPassesTotal,
                                    disabled: true,
                                    type: 'int'
                                },
                            ],
                        },
                        {
                            title: 'S&C Labor Costs of Grinding',
                            fields: [
                                {
                                    id: 'SCgrindingLaborersPerShift',
                                    label: 'Number of Laborers per S&C Shift',
                                    title: 'How many total people from your railroad are required per shift to support the S&C grinding efforts? These support staff could include supervisors, pilots, fire suppression teams, among other roles.  Any labor provided by the contractor is not included in this calculation.',
                                    value: this.inputs.SCgrindingLaborersPerShift,
                                    disabled: false,
                                    type: 'int'
                                },
                                {
                                    id: 'SCgrindingOvertimeHours',
                                    label: 'Number of Overtime Hours Required per S&C Shift',
                                    title: 'How many additional hours per S&C shift of labor are required on average ABOVE the "Hours of Labor per Shift" calculation from the General Assumptions tab.',
                                    value: this.inputs.SCgrindingOvertimeHours,
                                    disabled: false,
                                    type: 'int'
                                },
                                {
                                    id: 'SCgrindingLaborCostTotal',
                                    label: 'Total Labor Cost of S&C Grinding',
                                    title: 'What is the total labor costly of S&C grinding?',
                                    value: this.inputs.SCgrindingLaborCostTotal,
                                    disabled: true,
                                    type: 'currency'
                                },
                            ],
                        },          
                        {
                            title: 'S&C Material Costs of Grinding',
                            fields: [
                                {
                                    id: 'grindingMaterialCostPerSCShift',
                                    label: 'Material Cost per S&C Shift',
                                    title: 'What is the total cost per shift of all materials that are fixed cost no matter how many miles of production grinding as performed each shift? Water for fire suppression may be one example, since the fire suppression needs to happen even if the grinding only performs one mile of grinding.',
                                    value: this.inputs.grindingMaterialCostPerSCShift,
                                    disabled: false,
                                    type: 'currency'
                                },
                                {
                                    id: 'SCgrindingMaterialCostTotal',
                                    label: 'Total S&C Grinding Material Cost',
                                    title: 'What is the total material cost for S&C grinding?',
                                    value: this.inputs.SCgrindingMaterialCostTotal,
                                    disabled: true,
                                    type: 'currency'
                                },
                            ],
                        },
                    ]
                },
                {
                    title: 'Friction Management',
                    hidden: false,
                    sections: [
                        {
                            title: 'Summary of Total Friction Management Costs',
                            fields: [
                                {
                                    id: 'totCostFrictionManagement',
                                    label: 'Total Cost of Friction Management',
                                    title: 'The total cost of all friction management.',
                                    value: this.inputs.totCostFrictionManagement,
                                    disabled: true,
                                    type: 'currency'
                                },
                                {
                                    id: 'GFlubricationExpenses',
                                    label: 'Total Cost of Gauge Face Friction Management',
                                    title: 'The total cost required to operate and maintain gauge face friction modifier wayside systems.',
                                    value: this.inputs.GFlubricationExpenses,
                                    disabled: true,
                                    type: 'currency'
                                },
                                {
                                    id: 'TORlubricationExpenses',
                                    label: 'Total Cost of Top of Rail Friction Management',
                                    title: 'The total cost required to operate and maintain top of rail friction modifier wayside systems.',
                                    value: this.inputs.TORlubricationExpenses,
                                    disabled: true,
                                    type: 'currency'
                                },
                                {
                                    id: 'capitalDepreciationLubricationExpenses',
                                    label: 'Total Depreciation Cost of Friction Management',
                                    title: 'The total cost of depreciation due to capital invested in all friction modifier wayside systems.',
                                    value: this.inputs.capitalDepreciationLubricationExpenses,
                                    disabled: true,
                                    type: 'currency'
                                },
                                {
                                    id: 'laborLubricationExpenses',
                                    label: 'Total Labor Cost of Friction Management',
                                    title: 'The total cost of labor due to inspections, maintenance, re-filling, and other interventions required by the friction modifier wayside systems.',
                                    value: this.inputs.laborLubricationExpenses,
                                    disabled: true,
                                    type: 'currency'
                                },
                                {
                                    id: 'lubricationExpenses',
                                    label: 'Total Material Cost of Friction Management',
                                    title: 'The total cost of friction modifier product and parts for maintenance.',
                                    value: this.inputs.lubricationExpenses,
                                    disabled: true,
                                    type: 'currency'
                                },
                                {
                                    id: 'totalFrictionManagementExpensesTrackAccess',
                                    label: 'Track Access Cost for Friction Management',
                                    title: 'How much does it cost your railroad for track access to perform friction management maintenance?',
                                    value: this.inputs.totalFrictionManagementExpensesTrackAccess,
                                    type: 'currency',
                                    disabled: true,
                                },
                                {
                                    id: 'materialLubricationExpenses',
                                    label: 'Total Material Cost of Gauge Face Friction Management',
                                    title: 'The total cost of gauge face friction modifier product and parts for maintenance.',
                                    value: this.inputs.materialLubricationExpenses,
                                    disabled: true,
                                    type: 'currency'
                                },
                                {
                                    id: 'TORmaterialLubricationExpenses',
                                    label: 'Total Material Cost of Top of Rail Friction Management',
                                    title: 'The total cost of top of rail friction modifier product and parts for maintenance.',
                                    value: this.inputs.TORmaterialLubricationExpenses,
                                    disabled: true,
                                    type: 'currency'
                                },
                                {
                                    id: 'TORmaterialOnboardLubricationExpenses',
                                    label: 'Total Material Cost of Onboard Friction Management',
                                    title: 'The total cost of onboard friction modifier product and parts for maintenance.',
                                    value: this.inputs.TORmaterialOnboardLubricationExpenses,
                                    type: 'int',
                                    disabled: true,
                                }
                            ]
                        },
                        {
                            title: 'Friction Management Plan',
                            fields: [
                                {
                                    id: 'totalGFWaysideLubricators',
                                    label: 'Total Gauge Face Friction Modifier Wayside Applicators',
                                    title: 'How many total gauge face wayside systems do you have installed in track, or are planning to install in track?',
                                    value: this.inputs.totalGFWaysideLubricators,
                                    disabled: false,
                                    type: 'int'
                                },
                                {
                                    id: 'totalTORWaysideLubricators',
                                    label: 'Total Top of Rail Friction Modifier Wayside Applicators',
                                    title: 'How many total top of rail wayside systems do you have installed in track, or are planning to install in track?',
                                    value: this.inputs.totalTORWaysideLubricators,
                                    disabled: false,
                                    type: 'int'
                                },
                                {
                                    id: 'totalWaysideLubricators',
                                    label: 'Total Friction Modifier Wayside Applicators',
                                    title: 'How many total wayside systems do you have installed in track, or are planning to install in track?',
                                    value: this.inputs.totalWaysideLubricators,
                                    disabled: true,
                                    type: 'int'
                                },
                                {
                                    id: 'totalTOROnboardLubricators',
                                    label: 'Total Friction Modifier Onboard Applicators',
                                    title: 'How many total onboard systems do you have installed on trains, or are planning to install on trains?',
                                    value: this.inputs.totalTOROnboardLubricators,
                                    disabled: false,
                                    type: 'int'
                                },
                            ]
                        },
                        {
                            title: 'Friction Management Efficiency',
                            fields: [
                                {
                                    id: 'waysideLubricationTotalSystemUptime',
                                    label: 'Average Ratio of Wayside Applicators Operational',
                                    title: 'What ratio of your friction management wayside systems are operating over the entire year? Wayside systems may not be operational due to maintenance issues or lack of lubrication in the filling tank. If half of your wayside friction management systems are operational and producing lubrication, on average, over a given year, this input would be 50%.',
                                    value: this.inputs.waysideLubricationTotalSystemUptime,
                                    disabled: false,
                                    type: 'int'
                                },
                                {
                                    id: 'waysideLubricationTotalSystemEffectiveness',
                                    label: 'Average Ratio of Wayside Applicator Nozzles Operational',
                                    title: 'What ratio of your friction management total maximum output of lubricant can it achieve, OR how many of the application nozzles are operational per wayside system? For example, if your wayside friction management system should be able to produce 100 gallons of lubricant per year at 100% availability, but it only produces 90 gallons, this input would be 90%. Other ways that railroads track this is if there are 10 nozzles that apply friction management to the rail, and 8 of them are not operating due to maintenance problems, the input here would be 20%.',
                                    value: this.inputs.waysideLubricationTotalSystemEffectiveness,
                                    disabled: false,
                                    type: 'int'
                                },
                                {
                                    id: 'onboardLubricationTotalSystemUptime',
                                    label: 'Average Ratio of Onboard Applicators Operational',
                                    title: 'What ratio of your friction management onboard systems are operating over the entire year?  Onboard systems may not be operational due to not refilling the sticks. If half of your onboard friction management systems are operational and producing lubrication, on average, over a given year, this input would be 50%.',
                                    value: this.inputs.onboardLubricationTotalSystemUptime,
                                    disabled: false,
                                    type: 'int'
                                },
                            ]
                        },
                        {
                            title: 'Depreciation Costs of Wayside Friction Management',
                            fields: [
                                {
                                    id: 'pricePerWaysideLubricator',
                                    label: 'Capital Cost per Friction Modifier Wayside Applicator',
                                    title: 'How much does it cost for the wayside system hardware?',
                                    value: this.inputs.pricePerWaysideLubricator,
                                    disabled: false,
                                    type: 'currency'
                                },
                                {
                                    id: 'installationCostPerWaysideLubricator',
                                    label: 'Installation Cost per Friction Modifier Wayside Applicator',
                                    title: 'How much does it cost to install the wayside system hardware to your railroad?',
                                    value: this.inputs.installationCostPerWaysideLubricator,
                                    disabled: false,
                                    type: 'currency'
                                },
                                {
                                    id: 'totalCostPerWaysideLubricator',
                                    label: 'Total Cost per Friction Modifier Wayside Applicator',
                                    title: 'The total cost of purchasing and installing a friction modifier wayside system.',
                                    value: this.inputs.totalCostPerWaysideLubricator,
                                    disabled: true,
                                    type: 'currency'
                                },
                                {
                                    id: 'totalUsefulLifeWayside',
                                    label: 'Useful Life per Friction Modifier Wayside Applicator (Years)',
                                    title: 'How many years do you expect that the friction management wayside system will continue to run before it requires complete replacement or overhaul?',
                                    value: this.inputs.totalUsefulLifeWayside,
                                    disabled: false,
                                    type: 'int'
                                },
                                {
                                    id: 'lubricationDepreciationPerUnitPerYear',
                                    label: 'Total Depreciation Cost per Friction Modifier Applicator per Year',
                                    title: 'The total cost of depreciation due to capital invested in a single friction modifier wayside systems.',
                                    value: this.inputs.lubricationDepreciationPerUnitPerYear,
                                    disabled: true,
                                    type: 'currency'
                                }
                            ]
                        },
                        {
                            title: 'Depreciation Costs of Onboard Friction Management',
                            fields: [
                                {
                                    id: 'pricePerOnboardLubricator',
                                    label: 'Capital Cost per Friction Modifier Onboard Applicator',
                                    title: 'How much does it cost for the onboard system hardware?',
                                    value: this.inputs.pricePerOnboardLubricator,
                                    disabled: false,
                                    type: 'currency'
                                },
                                {
                                    id: 'installationCostPerOnboardLubricator',
                                    label: 'Installation Cost per Friction Modifier Onboard Applicator',
                                    title: 'How much does it cost to install the onboard system hardware to your trains?',
                                    value: this.inputs.installationCostPerOnboardLubricator,
                                    disabled: false,
                                    type: 'currency'
                                },
                                {
                                    id: 'totalUsefulLifeOnboard',
                                    label: 'Useful Life per Friction Modifier Onboard Applicator (Years)',
                                    title: 'How many years do you expect that the friction management onboard system will continue to run before it requires complete replacement or overhaul?',
                                    value: this.inputs.totalUsefulLifeOnboard,
                                    disabled: false,
                                    type: 'int'
                                },
                                {
                                    id: 'lubricationOnboardDepreciationPerUnitPerYear',
                                    label: 'Total Depreciation Cost per Friction Modifier Onboard Applicator per Year',
                                    title: 'The total cost of depreciation due to capital invested in a single friction modifier onboard systems.',
                                    value: this.inputs.lubricationOnboardDepreciationPerUnitPerYear,
                                    disabled: true,
                                    type: 'currency'
                                },
                                {
                                    id: 'totalCostPerOnboardLubricator',
                                    label: 'Total Cost per Friction Modifier Onboard Applicator',
                                    title: 'The total cost of purchasing and installing a friction modifier onboard system.',
                                    value: this.inputs.totalCostPerOnboardLubricator,
                                    disabled: true,
                                    type: 'currency'
                                },
                            ]
                        },
                        {
                            title: 'Friction Management Maintenance Plan',
                            fields: [
                                {
                                    id: 'waysideTotalInterventionsPerMonth',
                                    label: 'Total Wayside Applicator Maintenance Interventions per Month',
                                    title: 'What are the average number of visits to a wayside system per month for inspect, repair, tank filling, data collection or other interventions? Planned interventions (i.e. planned inspections) and condition-based or unplanned interventions (such as inspections or repairs) should be included.',
                                    value: this.inputs.waysideTotalInterventionsPerMonth,
                                    disabled: false,
                                    type: 'int'
                                },
                                {
                                    id: 'waysideTotalInterventionsPerYear',
                                    label: 'Total Maintenance Interventions per Year',
                                    title: 'The total number of interventions by maintenance teams due to inspections, maintenance, re-filling, and other interventions required by all friction modifier wayside systems.',
                                    value: this.inputs.waysideTotalInterventionsPerYear,
                                    disabled: true,
                                    type: 'int'
                                },
                                {
                                    id: 'waysideTotalShiftDaysRequired',
                                    label: 'Total Shifts Required for Maintenance Per Year',
                                    title: 'The total number of shifts required by maintenance teams due to inspections, maintenance, re-filling, and other interventions required by all friction modifier wayside systems.  The calculation is the number of total interventions required divided by the interventions per shift.',
                                    value: this.inputs.waysideTotalShiftDaysRequired,
                                    disabled: true,
                                    type: 'int'
                                },
                                {
                                    id: 'waysideMaintenanceCycle',
                                    label: 'Number of Part Replacement Interventions per Wayside Applicator per Year',
                                    title: 'How many times do parts need to be installed into the wayside system each year for repairs and maintenance?',
                                    value: this.inputs.waysideMaintenanceCycle,
                                    disabled: false,
                                    type: 'int'
                                },
                                {
                                    id: 'onboardMaintenanceCost',
                                    label: 'Parts Cost per Onboard Maintenance Intervention',
                                    title: 'What is the average cost of the parts required for each intervention shown in the "Number of Part Replacement Interventions per Onboard Applicator per Year"?',
                                    value: this.inputs.onboardMaintenanceCost,
                                    disabled: false,
                                    type: 'currency'
                                },
                                {
                                    id: 'onboardMaintenanceCycle',
                                    label: 'Number of Part Replacement Interventions per Onboard Applicator per Year',
                                    title: 'How many times do parts need to be installed into the onboard system each year for repairs and maintenance?',
                                    value: this.inputs.onboardMaintenanceCycle,
                                    disabled: false,
                                    type: 'int'
                                },
                                {
                                    id: 'onboardMaintenanceCostPerYearPerUnit',
                                    label: 'Total Parts Cost per Year per Onboard Applicator',
                                    title: 'The average cost of maintenance parts required to operate the friction modifier onboard system per year.',
                                    value: this.inputs.onboardMaintenanceCostPerYearPerUnit,
                                    disabled: true,
                                    type: 'currency'
                                },
                            ]
                        },
                        {
                            title: 'Labor Costs of Friction Management Maintenance',
                            fields: [
                                {
                                    id: 'waysidePerDiemCostPerDay',
                                    label: 'Per Diem Cost Per Day',
                                    title: '',
                                    value: this.inputs.waysidePerDiemCostPerDay,
                                    disabled: false,
                                    type: 'int',
                                    hidden: true,
                                },
                                {
                                    id: 'waysideLaborCostPerPersonPerDay',
                                    label: 'Labor Cost per Person/Day',
                                    title: '',
                                    value: this.inputs
                                        .waysideLaborCostPerPersonPerDay,
                                    disabled: true,
                                    type: 'int',
                                    hidden: true,
                                },
                                {
                                    id: 'waysideNumberOfTechniciansPerGang',
                                    label: 'Number of Laborers per Shift',
                                    title: 'What are the average number of track workers required for each inspection, repair, tank filling, data collection event or other interventions?  Planned interventions (i.e. planned inspections) and condition-based or unplanned interventions (such as inspections or repairs) should be included.',
                                    value: this.inputs
                                        .waysideNumberOfTechniciansPerGang,
                                    disabled: false,
                                    type: 'int'
                                },
                                {
                                    id: 'waysideTotalLaborCostPerDayPerGang',
                                    label: 'Total Labor Cost per Shift',
                                    title: 'The average cost of direct labor per full shift of friction modifier wayside system maintenance.',
                                    value: this.inputs
                                        .waysideTotalLaborCostPerDayPerGang,
                                    disabled: true,
                                    type: 'currency'
                                },
                                {
                                    id: 'waysideTimePerIntervention',
                                    label: 'Hours Required per Intervention',
                                    title: 'What are the average hours required to perform each inspection, repair, tank filling, data collection event or other interventions?  Planned interventions (i.e. planned inspections) and condition-based or unplanned interventions (such as inspections or repairs) should be included.',
                                    value: this.inputs
                                        .waysideTimePerIntervention,
                                    disabled: false,
                                    type: 'int'
                                },
                                {
                                    id: 'waysideTimePerMobilization',
                                    label: 'Hours Required per Mobilization',
                                    title: 'What are the average hours required to travel to the location of the wayside system to perform each inspection, repair, tank filling, data collection event or other interventions?  Planned interventions (i.e. planned inspections) and condition-based or unplanned interventions (such as inspections or repairs) should be included.',
                                    value: this.inputs
                                        .waysideTimePerMobilization,
                                    disabled: false,
                                    type: 'int'
                                },
                                {
                                    id: 'waysideTotalInterventionsPerDay',
                                    label: 'Total Interventions per Shift',
                                    title: 'The average number of friction modifier wayside system interventions (maintenance, inspections, re-filling, etc.) that can be performed in a single shift.',
                                    value: this.inputs
                                        .waysideTotalInterventionsPerDay,
                                    disabled: true,
                                    type: 'int'
                                },
                                {
                                    id: 'waysideTotalLaborCost',
                                    label: 'Total Labor Cost',
                                    title: '',
                                    value: this.inputs.waysideTotalLaborCost,
                                    disabled: true,
                                    type: 'int',
                                    hidden: true,
                                },
                                {
                                    id: 'waysideLaborCostPerIntervention',
                                    label: 'Total Labor Cost per Intervention',
                                    title: 'The average cost of direct labor per intervention.',
                                    value: this.inputs.waysideLaborCostPerIntervention,
                                    disabled: true,
                                    type: 'currency'
                                },
                                {
                                    id: 'frictionManagementTrackAccess',
                                    label: 'Does Friction Management Maintenance Require Track Access?',
                                    title: 'Does your friction management maintenance team require special track access that disrupts traffic, or can the work be performed between trains?',
                                    value: this.inputs.frictionManagementTrackAccess,
                                    type: 'bool'
                                }
                            ]
                        },
                        {
                            title: 'Consumable Cost of Friction Management',
                            fields: [
                                {
                                    id: 'waysideConsumableCost',
                                    label: 'Gauge Face Friction Modifier Cost per Unit',
                                    title: 'How many units (liters, gallons, buckets, etc.) of gauge face friction modifier should be applied from the wayside system per year IF the wayside system is running at 100% efficiency and 100% availability? The unit type does not matter, but needs to be consistent with the unit in Gauge Face Friction Modifier Cost per Unit.',
                                    value: this.inputs.waysideConsumableCost,
                                    disabled: false,
                                    type: 'currency'
                                },
                                {
                                    id: 'waysideApplicationRate',
                                    label: 'Gauge Face Friction Modifier Application Rate (units) per Year',
                                    title: 'How many units (liters, gallons, buckets, etc.) of gauge face friction modifier should be applied from the wayside system per year IF the wayside system is running at 100% efficiency and 100% availability? The unit type does not matter, but needs to be consistent with the unit in Gauge Face Friction Modifier Cost per Unit.',
                                    value: this.inputs.waysideApplicationRate,
                                    disabled: false,
                                    type: 'int'
                                },
                                {
                                    id: 'waysideConsumableCostTotal',
                                    label: 'Gauge Face Friction Modifier Cost per Wayside Applicator',
                                    title: 'The total cost of the gauge face friction modifier product required per year at current capacity and availability.',
                                    value: this.inputs.waysideConsumableCostTotal,
                                    disabled: true,
                                    type: 'currency'
                                },
                                {
                                    id: 'waysideTORConsumableCost',
                                    label: 'Top of Rail Friction Modifier Cost per Unit',
                                    title: 'How many units (liters, gallons, buckets, etc.) of top of rail friction modifier should be applied from the wayside system per year IF the wayside system is running at 100% efficiency and 100% availability? The unit type does not matter, but needs to be consistent with the unit in Top of Rail Friction Modifier Cost per Unit.',
                                    value: this.inputs.waysideTORConsumableCost,
                                    disabled: false,
                                    type: 'int'
                                },
                                {
                                    id: 'waysideTORApplicationRate',
                                    label: 'Top of Rail Friction Modifier Application Rate (units) per Year',
                                    title: 'How many units (liters, gallons, buckets, etc.) of top of rail friction modifier should be applied from the wayside system per year IF the wayside system is running at 100% efficiency and 100% availability? The unit type does not matter, but needs to be consistent with the unit in Top of Rail Friction Modifier Cost per Unit.',
                                    value: this.inputs.waysideTORApplicationRate,
                                    disabled: false,
                                    type: 'int'
                                },
                                {
                                    id: 'waysideTORConsumableCostTotal',
                                    label: 'Top of Rail Friction Modifier Cost per Wayside Applicator',
                                    title: 'The total cost of the top of rail friction modifier product required per year at current capacity and availability.',
                                    value: this.inputs.waysideTORConsumableCostTotal,
                                    disabled: true,
                                    type: 'currency'
                                },
                                {
                                    id: 'onboardTORConsumableCost',
                                    label: 'Onboard Top of Rail Friction Modifier Cost per Unit',
                                    title: 'How many units of top of rail friction modifier should be applied from the onboard system per year IF the onboard system is running at 100% efficiency and 100% availability? The unit type does not matter, but needs to be consistent with the unit in Onboard Top of Rail Friction Modifier Cost per Unit.',
                                    value: this.inputs.onboardTORConsumableCost,
                                    disabled: false,
                                    type: 'int'
                                },
                                {
                                    id: 'onboardTORApplicationRate',
                                    label: 'Onboard Top of Rail Friction Modifier Application Rate (units) per Year',
                                    title: 'How many units of onboard top of rail friction modifier should be applied from the onboard system per year IF the onboard system is running at 100% efficiency and 100% availability? The unit type does not matter, but needs to be consistent with the unit in Onboard Top of Rail Friction Modifier Cost per Unit.',
                                    value: this.inputs.onboardTORApplicationRate,
                                    disabled: false,
                                    type: 'int'
                                },
                                {
                                    id: 'onboardTORConsumableCostTotal',
                                    label: 'Top of Rail Friction Modifier Cost per Year per Onboard System',
                                    title: 'The total cost of the onboard top of rail friction modifier product required per year at current capacity and availability.',
                                    value: this.inputs.onboardTORConsumableCostTotal,
                                    disabled: true,
                                    type: 'currency'
                                },
                                {
                                    id: 'waysideTotalMaintenanceCost',
                                    label: 'Total Maintenance Cost',
                                    title: '',
                                    value: this.inputs.waysideTotalMaintenanceCost,
                                    disabled: true,
                                    type: 'currency',
                                    hidden: true,
                                },
                                {
                                    id: 'waysideMaintenanceCost',
                                    label: 'Parts Cost per Wayside Maintenance Intervention',
                                    title: 'What is the average cost of the parts required for each intervention shown in the "Number of Part Replacement Interventions per Wayside Applicator per Year"?',
                                    value: this.inputs.waysideMaintenanceCost,
                                    disabled: false,
                                    type: 'currency'
                                },
                                {
                                    id: 'waysideMaintenanceCostPerYearPerUnit',
                                    label: 'Total Parts Cost per Year per Wayside Applicator',
                                    title: 'The average cost of maintenance parts required to operate the friction modifier wayside system per year.',
                                    value: this.inputs.waysideMaintenanceCostPerYearPerUnit,
                                    disabled: true,
                                    type: 'currency'
                                },
                            ]
                        }
                    ]
                },
                {
                    title: 'Inspections',
                    hidden: false,
                    sections: [
                        {
                            title: 'Summary of Total Inspection Costs',
                            fields: [
                                {
                                    id: 'inspectionFreeCashFlow',
                                    label: 'Total Cost of Inspections',
                                    title: 'The total cost of ultrasonic and pre-grind inspections.',
                                    value: this.inputs.inspectionFreeCashFlow,
                                    disabled: true,
                                    type: 'currency'
                                },
                                {
                                    id: 'ultrasoundTotalInspectionCost',
                                    label: 'Total Cost of Ultrasonic Inspections',
                                    title: 'The total cost of ultrasonic inspections.',
                                    value: this.inputs
                                        .ultrasoundTotalInspectionCost,
                                    disabled: true,
                                    type: 'currency'
                                },
                            ]
                        },
                        {
                            title: 'Total OPEX',
                            hidden: true,
                            fields: [
                                {
                                    id: 'inspectionExpenses',
                                    label: 'Total Cost',
                                    title: '',
                                    value: this.inputs.inspectionExpenses,
                                    disabled: true,
                                    hidden: true,
                                    type: 'int'
                                }
                            ]
                        },
                        {
                            title: 'Ultrasonic Inspection Plan',
                            fields: [
                                {
                                    id: 'inspectionsPerYear',
                                    label: 'Inspections per Year',
                                    title: 'How many times does each piece of track require ultrasonic inspection per year?  If every piece of rail is inspected once, this input would be 1. If every piece of rail is inspected every other year, this input would be 0.5.  If every piece of rail is inspected monthly, this input would be 12.  If only half of the railroad is inspected every year, this input would be 0.5.',
                                    value: this.inputs.inspectionsPerYear,
                                    disabled: false,
                                    type: 'int'
                                },
                                {
                                    id: 'totalRouteMilesInspected',
                                    label: 'Total Route Miles Inspected',
                                    title: '',
                                    value: this.inputs.totalRouteMilesInspected,
                                    disabled: true,
                                    type: 'int',
                                    hidden: true,
                                },
                                {
                                    id: 'routeMilesInspectedPerDay',
                                    label: 'Total Track Miles Inspected Per Shift',
                                    title: 'How many miles of ultrasonic inspection can be completed per shift, on average?  ',
                                    value: this.inputs
                                        .routeMilesInspectedPerDay,
                                    disabled: false,
                                    type: 'int'
                                },
                                {
                                    id: 'totalInspectionDays',
                                    label: 'Total Inspection Shifts Required',
                                    title: 'The total number of ultrasonic inspection shifts required.',
                                    value: this.inputs.totalInspectionDays,
                                    disabled: true,
                                    type: 'int'
                                },
                                {
                                    id: 'inspectionCostPerDay',
                                    label: 'Ultrasonic Inspection Cost per Shift',
                                    title: 'How much does it cost for ultrasonic inspection contract per shift on average?',
                                    value: this.inputs.inspectionCostPerDay,
                                    disabled: false,
                                    type: 'currency'
                                },
                            ]
                        },
                    ]
                },
                {
                    title: 'Wheels',
                    hidden: false,
                    sections: [
                        {
                            title: 'Summary of Total Wheel Costs',
                            hidden: false,
                            fields: [
                                {
                                    id: 'wheelsTotal',
                                    label: 'Total Cost of Wheels',
                                    title: 'What is the total cost of maintaining and replacing wheels?',
                                    value: this.inputs.wheelsTotal,
                                    disabled: true,
                                    hidden: false,
                                    type: 'int'
                                },
                                {
                                    id: 'wheelsInspectiontotal',
                                    label: 'Total Cost of Wheel Inspections',
                                    title: 'What is the total cost of inspecting wheels?',
                                    value: this.inputs.wheelsInspectiontotal,
                                    disabled: true,
                                    hidden: false,
                                    type: 'int'
                                },
                                {
                                    id: 'wheelsRetruingtotal',
                                    label: 'Total Cost of Wheel Retruing',
                                    title: 'What is the total cost of retruing wheels?',
                                    value: this.inputs.wheelsRetruingtotal,
                                    disabled: true,
                                    hidden: false,
                                    type: 'int'
                                },
                                {
                                    id: 'wheelsRefurbishmenttotal',
                                    label: 'Total Cost of Wheel Refurbishment',
                                    title: 'What is the total cost of refurbishing wheels?',
                                    value: this.inputs.wheelsRefurbishmenttotal,
                                    disabled: true,
                                    hidden: false,
                                    type: 'int'
                                },
                                {
                                    id: 'wheelsReplacementtotal',
                                    label: 'Total Cost of Wheel Replacement',
                                    title: 'What is the total cost of refurbishing wheels?',
                                    value: this.inputs.wheelsReplacementtotal,
                                    disabled: true,
                                    hidden: false,
                                    type: 'int'
                                },
                                {
                                    id: 'wheelsTotallaborCost',
                                    label: 'Total Labor Cost of Wheels',
                                    title: 'What is the total labor cost of wheel maintenance and replacement?',
                                    value: this.inputs.wheelsTotallaborCost,
                                    disabled: true,
                                    hidden: false,
                                    type: 'int'
                                },
                                {
                                    id: 'wheelsTotalmaterialCost',
                                    label: 'Total Material Cost of Wheels',
                                    title: 'What is the total material cost of wheel maintainence and replacement?',
                                    value: this.inputs.wheelsTotalmaterialCost,
                                    disabled: true,
                                    hidden: false,
                                    type: 'int'
                                },
                                {
                                    id: 'wheelsTotaldwellTime',
                                    label: 'Total Dwell Time of Wheels',
                                    title: 'What is the total dwell time for wheel maintenance and replacement?',
                                    value: this.inputs.wheelsTotaldwellTime,
                                    disabled: true,
                                    hidden: false,
                                    type: 'int'
                                },
                                {
                                    id: 'wheelsTotaldownTime',
                                    label: 'Total  Downtime of Wheels',
                                    title: 'What is the total  downtime for wheel maintenance and replacement?',
                                    value: this.inputs.wheelsTotaldownTime,
                                    disabled: true,
                                    hidden: false,
                                    type: 'int'
                                },
                                {
                                    id: 'wheelsTotalLaborMobilizationTime',
                                    label: 'Total Wheel Labor Mobilization Hours',
                                    title: 'What is the total mobilization time for wheel maintenance and replacement?',
                                    value: this.inputs.wheelsTotalLaborMobilizationTime,
                                    disabled: true,
                                    hidden: false,
                                    type: 'int'
                                },
                                {
                                    id: 'wheelsTotalLaborMobilizationRatio',
                                    label: 'Total Wheel Labor Mobilization Ratio',
                                    title: 'What is the total mobilization time percentage for wheel maintenance and replacement?',
                                    value: this.inputs.wheelsTotalLaborMobilizationRatio,
                                    disabled: true,
                                    hidden: false,
                                    type: 'int'
                                },
                            ]
                        },
                        {
                            title: 'Summary of Rail Fleet',
                            hidden: false,
                            fields: [
                                {
                                    id: 'totalRailCars',
                                    label: 'Total Rail Cars',
                                    title: 'How many total rail cars are operational on the network?',
                                    value: this.inputs.totalRailcars,
                                    disabled: false,
                                    hidden: false,
                                    type: 'int'
                                },
                                {
                                    id: 'trucksPerrailCar',
                                    label: 'Trucks per Rail Car',
                                    title: 'How many trucks/bogies are on each rail car?',
                                    value: this.inputs.trucksPerrailCar,
                                    disabled: false,
                                    hidden: false,
                                    type: 'int'
                                },
                                {
                                    id: 'axlesperTruck',
                                    label: 'Axles per Truck',
                                    title: 'How many axles are on each truck/bogie?',
                                    value: this.inputs.axlesperTruck,
                                    disabled: false,
                                    hidden: false,
                                    type: 'int'
                                },
                                {
                                    id: 'wheelsPeraxle',
                                    label: 'Wheel per Axle',
                                    title: 'How many wheels are on each axle?',
                                    value: this.inputs.wheelsPeraxle,
                                    disabled: false,
                                    hidden: false,
                                    type: 'int'
                                },
                                {
                                    id: 'totalWheels',
                                    label: 'Total Wheels',
                                    title: 'How many total wheels are in the rail car fleet?',
                                    value: this.inputs.totalWheels,
                                    disabled: true,
                                    hidden: false,
                                    type: 'int'
                                },
                                {
                                    id: 'OperatingUnitsPerYear',
                                    label: 'Operational Units per Year',
                                    title: 'How many operational units (i.e. miles of operation, hours of operation, etc.) are used by each rail car per year?',
                                    value: this.inputs.OperatingUnitsPerYear,
                                    disabled: false,
                                    hidden: false,
                                    type: 'int'
                                },
                            ]
                        },
                        {
                            title: 'Wheel Inspections',
                            hidden: false,
                            fields: [
                                {
                                    id: 'inspectionOperatingUnits',
                                    label: 'Inspection Interval (Hub Miles)',
                                    title: 'How many operating units are between each rail inspection?',
                                    value: this.inputs.inspectionOperatingUnits,
                                    disabled: false,
                                    hidden: false,
                                    type: 'int'
                                },
                                {
                                    id: 'inspectionsPerPeriod',
                                    label: 'Inspections',
                                    title: 'How many total inspections are performed on the fleet each year?',
                                    value: this.inputs.inspectionsPerPeriod,
                                    disabled: true,
                                    hidden: false,
                                    type: 'int'
                                },
                                {
                                    id: 'wheelInspectionsHoursPerShift',
                                    label: 'Shift Hours for Inspection',
                                    title: 'How many labor hours are required to inspect a wheel?',
                                    value: this.inputs.wheelInspectionsHoursPerShift,
                                    disabled: false,
                                    hidden: false,
                                    type: 'int'
                                },
                                {
                                    id: 'wheelInspectionsPerShift',
                                    label: 'Wheels Inspected Per Shift',
                                    title: 'How many wheel inspections can be completed per shift?',
                                    value: this.inputs.wheelInspectionsPerShift,
                                    disabled: false,
                                    hidden: false,
                                    type: 'int'
                                },
                                {
                                    id: 'totalWheelinspectionHours',
                                    label: 'Total Inspection Hours per Fleet',
                                    title: 'How many labor hours are required to inspect all wheels?',
                                    value: this.inputs.totalWheelinspectionHours,
                                    disabled: true,
                                    hidden: false,
                                    type: 'int'
                                },
                                {
                                    id: 'totalWheelinspectionShifts',
                                    label: 'Total Inspection Shifts',
                                    title: 'How many labor shifts are required to inspect all wheels?',
                                    value: this.inputs.totalWheelinspectionShifts,
                                    disabled: true,
                                    hidden: false,
                                    type: 'int'
                                },
                                {
                                    id: 'totalWheelinspectionCostPer',
                                    label: 'Total Inspection Cost per Wheel',
                                    title: 'How much labor cost is required to inspect a wheel?',
                                    value: this.inputs.totalWheelinspectionCostPer,
                                    disabled: true,
                                    hidden: false,
                                    type: 'int'
                                },
                            ]
                        },
                        {
                            title: 'Wheel Maintenance Strategy',
                            hidden: false,
                            fields: [
                                {
                                    id: 'RetruingOperatingPercentage',
                                    label: 'Mean Percentage of Returing / Wheel',
                                    title: 'What percentage of wheels are retrued?',
                                    value: this.inputs.RetruingOperatingPercentage,
                                    disabled: true,
                                    hidden: false,
                                    type: 'int'
                                },
                                {
                                    id: 'RetruingOperatingUnits',
                                    label: 'Mean Operating Units Between Retruing',
                                    title: 'How many units are between returing on average per wheel?',
                                    value: this.inputs.RetruingOperatingUnits,
                                    disabled: false,
                                    hidden: false,
                                    type: 'int'
                                },
                                {
                                    id: 'RetruingNumber',
                                    label: 'Mean Number of Retruing / Wheel',
                                    title: 'How many wheels are retrued?',
                                    value: this.inputs.RetruingNumber,
                                    disabled: true,
                                    hidden: false,
                                    type: 'int'
                                },
                                {
                                    id: 'RetruingBeforeRefurbishment',
                                    label: 'Number of Wheel Retruing before Refurbishment',
                                    title: 'How many times can a wheel be retrued before it needs to be refurbished?',
                                    value: this.inputs.RetruingBeforeRefurbishment,
                                    disabled: false,
                                    hidden: false,
                                    type: 'int'
                                },
                                {
                                    id: 'RefurbishmentOperatingUnits',
                                    label: 'Mean Time Between Wheel Refurbishment',
                                    title: 'How many units are between refurbishments on average per wheel?',
                                    value: this.inputs.RefurbishmentOperatingUnits,
                                    disabled: true,
                                    hidden: false,
                                    type: 'int'
                                },
                                {
                                    id: 'RefurbishmentOperatingPercentage',
                                    label: 'Mean Percentage of Refurbishment / Wheel',
                                    title: 'What percentage of wheels are refurbished?',
                                    value: this.inputs.RefurbishmentOperatingPercentage,
                                    disabled: true,
                                    hidden: false,
                                    type: 'int'
                                },
                                {
                                    id: 'RefurbishmentBeforeReplacement',
                                    label: 'Number of Wheel Refurbishments before Replacement',
                                    title: 'How many times can a wheel be refurbished before it needs to be replaced?',
                                    value: this.inputs.RefurbishmentBeforeReplacement,
                                    disabled: false,
                                    hidden: false,
                                    type: 'int'
                                },
                                {
                                    id: 'ReplacementOperatingUnits',
                                    label: 'Mean Time Between Wheel Replacement',
                                    title: 'How many units are between replacements on average per wheel?',
                                    value: this.inputs.ReplacementOperatingUnits,
                                    disabled: true,
                                    hidden: false,
                                    type: 'int'
                                },
                            ]
                        },
                        {
                            title: 'Wheel Retruing',
                            hidden: false,
                            fields: [
                                {
                                    id: 'WheelsRetruedPerShift',
                                    label: 'Wheels Retrued per Shift',
                                    title: 'How many wheel retruings can be completed per shift?',
                                    value: this.inputs.WheelsRetruedPerShift,
                                    disabled: false,
                                    hidden: false,
                                    type: 'int'
                                },
                                {
                                    id: 'totalWheelretrueingShifts',
                                    label: 'Total Retruing Shift Days Required',
                                    title: 'How many labor shifts are required to retrue all wheels?',
                                    value: this.inputs.totalWheelretrueingShifts,
                                    disabled: true,
                                    hidden: false,
                                    type: 'int'
                                },
                                {
                                    id: 'HoursPerShim',
                                    label: 'Shift Hours to Shim/Readjust Truck',
                                    title: 'How many labor hours are required to shim a wheel?',
                                    value: this.inputs.HoursPerShim,
                                    disabled: false,
                                    hidden: false,
                                    type: 'int'
                                },
                                {
                                    id: 'ShimTotal',
                                    label: 'Total Cost to Shim/Readjust Truck',
                                    title: 'How many labor hours are required to shim all wheels?',
                                    value: this.inputs.ShimTotal,
                                    disabled: true,
                                    hidden: false,
                                    type: 'int'
                                },
                                {
                                    id: 'wheelMobillizationTimeRetruing',
                                    label: 'Retruing Mobilization Time per Shift',
                                    title: 'How many mobilization hours are required to retrue wheels per shift?',
                                    value: this.inputs.wheelMobillizationTimeRetruing,
                                    disabled: false,
                                    hidden: false,
                                    type: 'int'
                                },
                                {
                                    id: 'wheelMobillizationLaborPercentage',
                                    label: 'Retruing Mobilization Labor Percentage',
                                    title: 'What percentage of retruing shift hours are required for mobilization?',
                                    value: this.inputs.wheelMobillizationLaborPercentage,
                                    disabled: false,
                                    hidden: false,
                                    type: 'int'
                                },
                                {
                                    id: 'wheelMobillizationCostRetruing',
                                    label: 'Retruing Cost per Shift of Mobilization',
                                    title: 'How much mobilization cost is required to retrue all wheels?',
                                    value: this.inputs.wheelMobillizationCostRetruing,
                                    disabled: true,
                                    hidden: false,
                                    type: 'int'
                                },
                                {
                                    id: 'wheelMobillizationCostRetruingPer',
                                    label: 'Total Retruing Cost per Wheel',
                                    title: 'How much mobilization cost is required to retrue all wheel per shift?',
                                    value: this.inputs.wheelMobillizationCostRetruingPer,
                                    disabled: true,
                                    hidden: false,
                                    type: 'int'
                                },
                            ]
                        },
                        {
                            title: 'Wheel Replacement',
                            hidden: false,
                            fields: [
                                {
                                    id: 'wheelDetruckTimeReplacement',
                                    label: 'Shift Hours to De-Truck',
                                    title: 'How many labor hours are required to detruck a wheel?',
                                    value: this.inputs.wheelDetruckTimeReplacement,
                                    disabled: false,
                                    hidden: false,
                                    type: 'int'
                                },
                                {
                                    id: 'wheelDisassembleTimeReplacement',
                                    label: 'Shift Hours to Disassemble + Work Scope',
                                    title: 'How many labor hours are required to disassemble a wheel?',
                                    value: this.inputs.wheelDisassembleTimeReplacement,
                                    disabled: false,
                                    hidden: false,
                                    type: 'int'
                                },
                                {
                                    id: 'wheelWorkScopeTimeReplacement',
                                    label: 'Shift Hours to Complete Work Scope',
                                    title: 'How many labor hours are required to reassemble a wheel?',
                                    value: this.inputs.wheelWorkScopeTimeReplacement,
                                    disabled: false,
                                    hidden: false,
                                    type: 'int'
                                },
                                {
                                    id: 'wheelRetruckTimeReplacement',
                                    label: 'Shift Hours to Re-Truck',
                                    title: 'How many labor hours are required to retruck a wheel?',
                                    value: this.inputs.wheelRetruckTimeReplacement,
                                    disabled: false,
                                    hidden: false,
                                    type: 'int'
                                },
                                {
                                    id: 'wheelRefurbishmentHoursPerShift',
                                    label: 'Total Shift Hours per Refurbishment',
                                    title: 'How many labor hours are required to refurbish a wheel?',
                                    value: this.inputs.wheelRefurbishmentHoursPerShift,
                                    disabled: true,
                                    hidden: false,
                                    type: 'int'
                                },
                                {
                                    id: 'wheelRefurbishmentLaborPerShift',
                                    label: 'Total Labor Cost per Refurbishment',
                                    title: 'How much labor cost is required to refurbish all wheels?',
                                    value: this.inputs.wheelRefurbishmentLaborPerShift,
                                    disabled: true,
                                    hidden: false,
                                    type: 'int'
                                },
                                {
                                    id: 'wheelMobillizationTimeRefurbishment',
                                    label: 'Mobilization Time per Refurbishment',
                                    title: 'How many mobilization hours are required to refurbish wheels per shift?',
                                    value: this.inputs.wheelMobillizationTimeRefurbishment,
                                    disabled: false,
                                    hidden: false,
                                    type: 'int'
                                },
                                {
                                    id: 'wheelMobillizationTimeRefurbishmentPercentage',
                                    label: 'Mobilization Labor Percentage',
                                    title: 'What percentage of refurbishment shift hours are required for mobilization?',
                                    value: this.inputs.wheelMobillizationTimeRefurbishmentPercentage,
                                    disabled: false,
                                    hidden: false,
                                    type: 'int'
                                },
                                {
                                    id: 'wheelMobillizationCostTotalRefurbishment',
                                    label: 'Total Mobilization Cost per Refurbishment',
                                    title: 'How much mobilization cost is required to refurbish all wheels?',
                                    value: this.inputs.wheelMobillizationCostTotalRefurbishment,
                                    disabled: true,
                                    hidden: false,
                                    type: 'int'
                                },
                                {
                                    id: 'WheelsRefurbishment',
                                    label: 'Refurbishments',
                                    title: 'How many wheels are refurbished?',
                                    value: this.inputs.WheelsRefurbishment,
                                    disabled: true,
                                    hidden: false,
                                    type: 'int'
                                },
                                {
                                    id: 'WheelsReplacement',
                                    label: 'Replacements',
                                    title: 'How many wheels are replaced?',
                                    value: this.inputs.WheelsReplacement,
                                    disabled: true,
                                    hidden: false,
                                    type: 'int'
                                },
                                {
                                    id: 'WheelsRefurbishmentPerShift',
                                    label: 'Refurbished Wheels per Shift',
                                    title: 'How many wheels are replaced?',
                                    value: this.inputs.WheelsRefurbishmentPerShift,
                                    disabled: false,
                                    hidden: false,
                                    type: 'int'
                                },
                                {
                                    id: 'WheelsRefurbishmentLaborCost',
                                    label: 'Total Refurbishment Labor Cost',
                                    title: 'How much labor cost is required to refurbish all wheels?',
                                    value: this.inputs.WheelsRefurbishmentLaborCost,
                                    disabled: true,
                                    hidden: false,
                                    type: 'int'
                                },
                                {
                                    id: 'WheelsRefurbishmentMobilizationCost',
                                    label: 'Total Refurbishment Mobilization Cost per Year',
                                    title: 'How much mobilization cost is required to refurbish all wheels?',
                                    value: this.inputs.WheelsRefurbishmentMobilizationCost,
                                    disabled: true,
                                    hidden: false,
                                    type: 'int'
                                },
                                {
                                    id: 'WheelsReplacementLaborCost',
                                    label: 'Total Replacement Labor Cost per Year',
                                    title: 'How much labor cost is required to replace all wheels?',
                                    value: this.inputs.WheelsReplacementLaborCost,
                                    disabled: true,
                                    hidden: false,
                                    type: 'int'
                                },
                                {
                                    id: 'WheelsReplacementMobilizationCost',
                                    label: 'Total Replacement Mobilization Cost per Year',
                                    title: 'How much mobilization cost is required to replace all wheels?',
                                    value: this.inputs.WheelsReplacementMobilizationCost,
                                    disabled: true,
                                    hidden: false,
                                    type: 'int'
                                },
                                {
                                    id: 'WheelsRefurbishmentMaterialCostPer',
                                    label: 'Material Cost of Wheel Refurbishment',
                                    title: 'How much material cost is required to refurbish a wheel?',
                                    value: this.inputs.WheelsRefurbishmentMaterialCostPer,
                                    disabled: false,
                                    hidden: false,
                                    type: 'int'
                                },
                                {
                                    id: 'WheelsReplacementMaterialCostPer',
                                    label: 'Material Cost of Wheel Replacement',
                                    title: 'How much material cost is required to replace a wheel?',
                                    value: this.inputs.WheelsReplacementMaterialCostPer,
                                    disabled: false,
                                    hidden: false,
                                    type: 'int'
                                },
                                {
                                    id: 'WheelsRefurbishmentMaterialCost',
                                    label: 'Total Cost of Refurbishment Material',
                                    title: 'How much material cost is required to refurbish all wheels?',
                                    value: this.inputs.WheelsRefurbishmentMaterialCost,
                                    disabled: true,
                                    hidden: false,
                                    type: 'int'
                                },
                                {
                                    id: 'WheelsReplacementMaterialCost',
                                    label: 'Total Cost of Replacement Material',
                                    title: 'How much material cost is required to replace all wheels?',
                                    value: this.inputs.WheelsReplacementMaterialCost,
                                    disabled: true,
                                    hidden: false,
                                    type: 'int'
                                },
                            ]
                        },
                    ]
                }
            ]
        };
    }
}
