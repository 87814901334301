export default {
    state: {
        currentProjectId: null,
        wearProfiles: {}
    },
    mutations: {
        setProjectId(state, newId = null) {
            if(newId && state.currentProjectId != newId) {
                state.currentProjectId = newId;
                state.wearProfiles = {};
            }
        },
        setWearProfile(state, {id = '', left = null, right = null}) {
            if( !(id in state.wearProfiles) ) {
                state.wearProfiles[id] = {
                    left: {},
                    right: {}
                };
            }

            if(left) {
                const hasPreview = state.wearProfiles[id].left.baseline || false;
                if(hasPreview && left.profiles) {
                    state.wearProfiles[id].left['profiles'] = left.profiles;
                }
                else {
                    state.wearProfiles[id].left = left;
                }
            }
            if(right) {
                const hasPreview = state.wearProfiles[id].right.baseline || false;
                if(hasPreview && right.profiles) {
                    state.wearProfiles[id].right['profiles'] = right.profiles;
                }
                else {
                    state.wearProfiles[id].right = right;
                }
            }
        }
    },
    getters: {
        getWearProfile: state => id => {
            return (id in state.wearProfiles) ? state.wearProfiles[id] : null;
        }
    },
    actions: {
        /**
         * GET Preview Data, one of each profile, baseline, pressure, and progression
         * @param {Object} payload 
         *      {
         *          projectId {String}
         *          scenarioId {String}
         *          wearId {String}
         *          side {String}
         *      }
         * @returns {Object} {baseline, profile, progression}
         */
         async getWearPreview(context, payload = {}) {
            const {projectId = null, scenarioId = null, wearId = null, side = null} = payload;
            try {
                if(!projectId || !scenarioId || !wearId || !side) throw new Error('Missing required parameters');

                // Logic to save Wear Profile Data for current Project
                context.commit('setProjectId', projectId);
                const profile = context.getters.getWearProfile(wearId);
                if(profile) {
                    return profile[side];
                }

                const customerId = context.rootGetters.getCurrentCustomerId;
                const api = context.rootState.railService.railApi;
                const {data = {}} = await api.get(
                    `/customers/${customerId}/projects/${projectId}/scenarios/${scenarioId}/wear/${wearId}/preview/${side}`
                );
                context.commit('setWearProfile', {id: wearId, [side]: data});
                return data;
            }
            catch(err) {
                console.log('Rail Service API Error: ', err);
                return {}
            }
        },
        /**
         * GET Retrieves wear data from s3 for left side
         * @param {Object} payload the query parameters to get a project's economic data
         *     {
         *         projectId {String}
         *         scenarioId {String}
         *         wearId {String}
         *     }
         * @returns {Object} profiles
         */
         async getScenarioWearLeft(context, payload = {}) {
            const {projectId = null, scenarioId = null, wearId = null} = payload;
            try {
                if(!projectId || !scenarioId || !wearId) throw new Error('Missing required parameters');

                const profile = context.getters.getWearProfile(wearId);
                if(profile && profile.left.profiles) {
                    return {profiles: profile.left.profiles};
                }

                const customerId = context.rootGetters.getCurrentCustomerId;
                const api = context.rootState.railService.railApi;
                const {data = {}} = await api.get(`/customers/${customerId}/projects/${projectId}/scenarios/${scenarioId}/wear/${wearId}/left`);
                context.commit('setWearProfile', {id: wearId, left: data});
                return data;
            }
            catch(err) {
                console.log('Rail Service API Error: Failed to get scenario left side wear data', err);
                throw new Error(err);
            }
        },
        /**
         * GET Retrieves wear data from s3 for right side
         * @param {Object} payload the query parameters to get a project's economic data
         *     {
         *         projectId {String}
         *         scenarioId {String}
         *         wearId {String}
         *     }
         * @returns {Object} profiles
         */
         async getScenarioWearRight(context, payload = {}) {
            const {projectId = null, scenarioId = null, wearId = null} = payload;
            try {
                if(!projectId || !scenarioId || !wearId) throw new Error('Missing required parameters');

                const profile = context.getters.getWearProfile(wearId);
                if(profile && profile.right.profiles) {
                    return {profiles: profile.right.profiles};
                }

                const customerId = context.rootGetters.getCurrentCustomerId;
                const api = context.rootState.railService.railApi;
                const {data = {}} = await api.get(`/customers/${customerId}/projects/${projectId}/scenarios/${scenarioId}/wear/${wearId}/right`);
                context.commit('setWearProfile', {id: wearId, right: data});
                return data;
            }
            catch(err) {
                console.log('Rail Service API Error: Failed to get scenario right side wear data', err);
                throw new Error(err);
            }
        },
        /**
         * GET Wear Pre-Signed URL
         * @param {Object} payload
         *     {
         *         projectId {String}
         *         scenarioId {String}
         *         contentType {String}
         *     }
         * @returns {Object} pre signed url that is used to upload a wear model
         *      {
         *          fileId {String}
         *          preSignedUrl {String}
         *      }
         */
         async getWearPreSignedURL(context, payload = {}) {
            const {projectId = null, scenarioId = null, contentType = null} = payload;
            try {
                if(!projectId || !scenarioId || !contentType) throw new Error('Missing required parameters');

                const customerId = context.rootGetters.getCurrentCustomerId;
                const api = context.rootState.railService.railApi;
                const {data = {}} = await api.post(
                    `/customers/${customerId}/projects/${projectId}/scenarios/${scenarioId}/wear`,
                    {contentType}
                );
                return data;
            }
            catch(err) {
                console.log('Rail Service API Error: Failed to get pre-signed url for wear upload', err);
                throw new Error(err);
            }
        },
        /**
         * PUT Update Life Estimate
         * @param {Object} payload
         *      {
         *          projectId {String} 
         *          scenarioId {String} 
         *          wearLife {Number} 
         *      }
         * @returns {Number} wearLife
         */
         async updateLifeEstimate(context, payload = {}) {
            const {projectId = null, scenarioId = null, wearLife = null} = payload;
            try {
                if(!projectId || !scenarioId || !wearLife) throw new Error('Missing required parameters');

                const customerId = context.rootGetters.getCurrentCustomerId;
                const api = context.rootState.railService.railApi;
                const {data = {}} = await api.put(
                    `/customers/${customerId}/projects/${projectId}/scenarios/${scenarioId}/wear`,
                    {wearLife}
                );
                return data;
            }
            catch(err) {
                console.log('Rail Service API Error: Failed to updated life estimate', err);
                throw new Error(err);
            }
        },
        /**
         * DELETE wear data for a specific scenario
         * @param {Object} payload 
         *      { 
         *          projectId {String}
         *          scenarioId {String}
         *      }
         * @returns {String} message
         */
         async deleteScenarioWearData(context, payload = {}) {
            const {projectId = null, scenarioId = null} = payload;

            try {
                if(!projectId || !scenarioId) throw new Error('Missing required parameter');

                const customerId = context.rootGetters.getCurrentCustomerId;
                const api = context.rootState.railService.railApi;
                const {data = {}} = await api.delete(
                    `/customers/${customerId}/projects/${projectId}/scenarios/${scenarioId}/wear`
                );
                return data;
            }
            catch(err) {
                console.log('Rail Service API Error: Failed to delete scenario wear data', err);
                throw new Error(err);
            }
        },
    }
}