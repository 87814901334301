// 3rd Party Libraries
import Auth from "@aws-amplify/auth";

// Local imports
import { MODULE_INFO } from "@/config/constants";
import userApiModule from "./userManagementApi";

export default {
    modules: {
        userManagementApi: userApiModule
    },
    state: {
        cognitoUser: null,
        userApi: null,
        userAttributes: {},
        customerId: null
    },
    mutations: {
        cognitoUser(state, user) {
            state.cognitoUser = user;
        },
        userApi(state, api) {
            state.userApi = api;
        },
        userAttributes(state, attributes) {
            state.userAttributes = attributes;
        },
        currentCustomerId(state, customerId) {
            state.customerId = customerId;
        }
    },
    actions: {
        /**
         * Initializes Cognito User and populates state with user session
         */
         async initUserSession(context) {
            const isConfigured = (Object.values(Auth.configure())).length > 0;
            if(isConfigured) {
                try {
                    // Get current user Session
                    const user = await Auth.currentAuthenticatedUser({bypassCache: true});
                    context.commit('cognitoUser', user);

                    // Create Axios API
                    const baseURL =  process.env.VUE_APP_UM_API_BASE || 'http://localhost:8080';
                    const axiosApi = await context.dispatch('makeAPI', baseURL, {root: true});
                    context.commit('userApi', axiosApi);

                    // Get User Attributes
                    const attributes = await Auth.userAttributes(user);
                    context.commit('userAttributes', attributes);

                    // Set current CustomerId
                    const {auth} = context.getters.getUserAttributes;
                    if(auth.customerId) {
                        context.commit('currentCustomerId', auth.customerId);
                    }
                    else if(auth.role === 'loram') {
                        context.commit('currentCustomerId', '453c2512-fea2-5904-9951-c55a8e672050');
                    }
                } catch(err) {
                    console.log('Initialization Failed:', err);
                    throw new Error(err);
                }
            }
        },
        /**
         * Refreshes cognitoUser by getting current authenticated user
         */
        async refreshCognitoUser(context) {
            const isConfigured = (Object.values(Auth.configure())).length > 0;
            if(isConfigured) {
                try {
                    // Get current user Session
                    const user = await Auth.currentAuthenticatedUser({bypassCache: true});
                    context.commit('cognitoUser', user);

                    // Create Axios API
                    const baseURL =  process.env.VUE_APP_UM_API_BASE || 'http://localhost:8080';
                    const axiosApi = await context.dispatch('makeAPI', baseURL, {root: true});
                    context.commit('userApi', axiosApi);

                    // Get User Attributes
                    const attributes = await Auth.userAttributes(user);
                    context.commit('userAttributes', attributes);
                } catch(err) {
                    console.log('Refresh CognitoUser Failed:', err);
                    throw new Error(err);
                }
            }
        },
        /**
         * Used to switch customers by setting new customer id
         * @param {String} newCustomerId uuid of the selected customer
         */
         setCustomerId(context, newCustomerId) {
            const {auth: {role = 'basic', customerId = null}} = context.getters.getUserAttributes;
            if(newCustomerId && (role === 'loram' || newCustomerId === customerId)) {
                context.commit('currentCustomerId', newCustomerId);
            }
            else {
                console.log('Error: Unable to set customer id');
                throw new Error('Error: Unable to set customer id')
            }
        },
        /**
        * Authenticates login credentials and signs in user 
        * @param {Object} payload of user credentials 
        *     loginInfo: { 
        *         username: String
        *         password: String
        *     }
        * @return {boolean} when successful
        */
        async userPwdLogin(context, payload) {
            const { username = null, password = null } = payload.loginInfo;
            if (username && password) {
                let user;
                try {
                    user = await Auth.signIn(username, password);
                    context.commit('cognitoUser', user);
                    return true;
                } catch (err) {
                    console.log(err);
                    throw new Error('Failed to authenticate username and password');
                }
            }
            else {
                return false;
            }
        },
        /**
        *  Updates User's password
        *  @param {Object} payload new password
        *      { password: String }
        *  @return {boolean} when successful
        */
         async userNewPassword({ state }, payload) {
            const newPassword = payload.password || null;
            try {
                if(!newPassword) throw new Error('Missing valid password');
        
                const attributes = state.cognitoUser && state.cognitoUser.challengeParam
                    ? state.cognitoUser.challengeParam.requiredAttributes : {};

                await Auth.completeNewPassword(
                    state.cognitoUser,
                    newPassword,
                    attributes
                );
                return true;
            } catch(err) {
                console.log(err);
                throw new Error('Failed to set new password');
            }
        },
        /** 
        * Send a confirmation code to users email
        * @param {Object} payload the username (email) to reset password for
        *     { username: String }
        * @returns {Boolean} true if successful
        */
         async userForgotPassword(context, payload) {
            try {
                const username = payload.username;
                await Auth.forgotPassword(username);
                return true;
            } catch(err) {
                console.log(err);
                throw new Error(err);
            }
        },
        /**
        *  Authenticates User MFA token
        *  @param {Object} payload of MFA token to authenticate 
        *      loginInfo: {
        *          mfaToken: String
        *          mfaType: String (MFA Type e.g. SMS_MFA, SOFTWARE_TOKEN_MFA)
        *      }
        *  @return {boolean} when successful
        */
        async userMFALogin({ commit, state }, payload) {
            const { mfaToken = null, mfaType = null } = payload.loginInfo;
            if(state.cognitoUser) {
                try {
                    await Auth.confirmSignIn(
                        state.cognitoUser,
                        mfaToken,
                        mfaType
                    );
                    return true;
                } catch(err) {
                    console.log(err);
                    throw new Error('Failed to authenticate MFA token');
                } 
            }
            else {
                return false;
            }
        },
        /**
        *  Verifies user MFA Token
        *  @param {Object} payload the token to verify
        *      { token: String }
        *  @return {boolean} when successful
        */
        async userVerifyMFA({ state }, payload) {
            const token = payload.token;
            try {
                if(!token) throw new Error('Invalid Token');
                await Auth.verifyTotpToken(state.cognitoUser, token);
                return true;
            } catch(err) {
                console.log(err);
                throw new Error('Failed to verify MFA token');
            }
        },
        /**
        * Sets up Time-based one-time password (TOTP)
        * @returns {String} authorization code from Amazon Cognito
        */
        async userSetupTOTP({ state }) {
            try {
                const code = await Auth.setupTOTP(state.cognitoUser);
                return code;
            } catch(err) {
                console.log(err);
                throw new Error('Failed to setup TOTP');
            }
        },
        /**
        * Sets up Preferred MFA Method
        * @returns {Boolean} true if successful
        */
        async userSetPreferredMFA({ state }) {
            try {
                if(!state.cognitoUser) throw new Error('Invalid User, unable to set preferred MFA method.');
                await Auth.setPreferredMFA(state.cognitoUser, "TOTP");
                return true;
            } catch(err) {
                console.log(err);
                throw new Error('Failed to set preferred MFA method');
            }
        },
        /**
         * Updates Cognito user profile Auth.configure()
         * @param {Object} payload attributes to update
         *     { attribute_key: attribute_value }
         * @returns {Boolean} true if successful
         */
        async updateUserProfile(context, payload = {}) {
            const {givenName = null, familyName = null, title = null, country = null} = payload;
            const current = context.getters.getUserAttributes;
            try {
                const params = {
                    given_name: givenName || current.given_name,
                    family_name: familyName || current.family_name,
                    'custom:meta': JSON.stringify(
                        Object.assign(current.meta, {
                            title: title || current.meta.title || '',
                            country: country || current.meta.country || '',
                            modified: new Date().toISOString()
                        })
                    )
                };
                // Update AWS Cognito user attributes
                await Auth.updateUserAttributes(context.state.cognitoUser, params);

                // Update local VueX copy of the cognitoUser
                const attributes = await Auth.userAttributes(context.state.cognitoUser);
                context.commit('userAttributes', attributes);

                return true;
            }
            catch(err) {
                console.log(err);
                throw new Error(err);
            }
        }
    },
    getters: {
        /**
         * Gets the current Cognito User
         * @returns {Object} CognitoUser session
         */
        getCognitoUser: state => {
            return state.cognitoUser;
        },
        /**
         * Gets users attributes
         * @returns {Object} CognitoUser attributes
         */
        getUserAttributes: state => {
            const attributes = state.userAttributes;
            if(Object.values(attributes).length === 0) return {};

            const response = {};
            attributes.forEach(a => {
                if(a.Name == 'custom:meta') {
                    response.meta = JSON.parse(a.Value);
                }
                else if(a.Name == 'custom:auth') {
                    response.auth = JSON.parse(a.Value);
                }
                else {
                    response[a.Name] = a.Value;
                }
            });
            return response;
        },
        /**
         * Get current customerId
         * @returns {String} customerId
        */
        getCurrentCustomerId: state => {
            return state.customerId;
        },
        /**
         * Get last authentication time from accessToken
         * @returns {String} date string of time of authenticaiton
        */
        getUserAuthTime: state => {
            // Set the Authentication time of token
            try {
                const {signInUserSession = null} = state.cognitoUser;
                if(signInUserSession) {
                    if(signInUserSession.accessToken) {
                        const {payload = null} = signInUserSession.accessToken;
                        if(payload) {
                            return new Date(payload.auth_time*1000);
                        }
                    }           
                }
                return '';
            }
            catch(err) {
                return '';
            }
        },
        /**
         * Returns a list of modules user has access to
         * @returns {Array} list of modules
         */
        getUserModules: state => {
            const authModules = ['dash'];
            const allModules = Object.keys(MODULE_INFO);

            if(Object.values(state.userAttributes).length == 0) return authModules;

            state.userAttributes.forEach(attr => {
                if(attr.Name === 'custom:auth') {
                    let {modules = null, role = 'basic'} = JSON.parse(attr.Value);
                    if(modules) {
                        modules.forEach(m => {
                            if(!authModules.includes(m)) {
                                authModules.push(m);
                            }
                        });
                    }

                    if(role === 'loram') {
                        allModules.forEach(m => {
                            if(!authModules.includes(m)) {
                                authModules.push(m);
                            }
                        });
                    }
                }
            });
            
            return authModules;            
        },
        /** 
        * Returns boolean, true user is single sign on, false user is using pw
        */
       getSingleSignOn: state => {
            let isSSO = false;
            if(Object.values(state.userAttributes).length == 0) return isSSO;

            state.userAttributes.forEach(attr => {
                let {Name} = attr;
                if(Name === 'identities') {
                    isSSO = true;
                }
            });

            return isSSO;
       },
    }
}