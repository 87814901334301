import Vue from "vue";
import Vuex from "vuex";

// 3rd Party Libraries
import Auth from "@aws-amplify/auth";
import axios from "axios";

// Third party libraries
import createPersistedState from "vuex-persistedstate";
import SecureLS from "secure-ls";

// Vuex Modules
import userModule from "./modules/userManagement";
import railService from "./modules/railService";
import exchangeService from "./modules/exchangeService";

Vue.use(Vuex);

const ls = new SecureLS({ isCompression: false });
const debugging = process.env.VUE_APP_DEBUGGING;

export default new Vuex.Store({
    plugins: [
        createPersistedState({
            key: 'loram.technologies.digitaltwin',
            paths: ['userManagement.customerId'],
            storage: {
                getItem: key => ls.get(key),
                setItem: (key, value) => ls.set(key, value),
                removeItem: key => ls.remove(key)
            }
        })
    ],
    modules: {
        userManagement: userModule,
        railService: railService,
        exchangeService: exchangeService
    },
    state: {
        browserWindow: {
            width: 0,
            height: 0
        }
    },
    mutations: {
        browserWindow(state, browserWindow) {
            state.browserWindow.width = browserWindow.outerWidth;
            state.browserWindow.height = browserWindow.outerHeight;
        }
    },
    actions: {
        /**
         * Configures Axios to use token and interceptors for service
         * @param {String} baseURL url to configure axios to use as a base
         * @returns {Object} configured axios api
         */
        makeAPI(context, baseURL) {
            let api = axios.create({ baseURL });
            api.interceptors.request.use(config =>
                Auth.currentSession().then(resp => {
                    config.headers.Authorization = `Bearer ${resp.idToken.jwtToken}`;
                    return Promise.resolve(config);
                })
            );
            api.interceptors.response.use(
                resp => context.dispatch('apiSuccessResponse', resp),
                err => context.dispatch('apiFailedResponse', err)
            );
            return api;
        },
        /**
         * Handles Successful Response of API Service
         * @param {Object} response returned from service
         */
        apiSuccessResponse(context, response) {
            if(debugging) {
                console.log('Interceptor Success', response);
            }
            return response;
        },
        /**
         * Handles Failure Response of API Service
         * @param {Object} error error returned from service
         */
        apiFailedResponse(context, error) {
            if(debugging) {
                console.log('Interceptor Failure', error);
            }
            return Promise.reject(error);
        },
        /**
         * Sets browser window size
         * @param {Object} windowEvent
         */
        setBrowserWindow(context, windowEvent) {
            if(windowEvent.outerWidth && windowEvent.outerHeight) {
                context.commit('browserWindow', windowEvent);
            }
        }
    },
    getters: {
        /**
         * Returns Base URL
         * @returns {String} baseURL
         */
        getBaseURL: state => {
            return process.env.BASE_URL || 'http://localhost:8080';
        },
        /**
         * Browser Window Size
         * @returns {Object} browser window size
         */
        getBrowserDimensions: state => {
            return state.browserWindow;
        }
    }
});