<template>
  <div id="app">
    <router-view />
    <b-modal
      id="nist-modal"
      ok-only
      centered
      :visible="($route && $route.params 
        && 'showNistWarning' in $route.params 
        && $route.params.showNistWarning) || showNist"
      no-close-on-backdrop
      no-close-on-esc
    >
      <div class="d-flex flex-column">
        WARNING:
        <p>
          This information system may host technical data whose export is
          restricted by the Arms Export Control Act (Title 22, U.S.C., Sec 2751,
          et. seq.) or the Export Administrative Act of 1979, as amended, Title
          50, U.S.C., App. 2401 et. seq.. Violations of these export laws are
          subject to severe criminal penalties. Contact your export compliance
          officer or system security officer if you have any questions about
          proper handling of this type of data. Continued use of this site
          constitutes acknowledgment that you have read and understand this
          warning.
        </p>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {Hub} from '@aws-amplify/core';

import { mapGetters } from 'vuex';

export default {
    name: "app",
    data() {
      return {
        showNist: false,
      }
    },
    computed: {
        ...mapGetters(['getCurrentCustomerId','getCognitoUser','getCurrentCustomerId','getUserAttributes'])
    },
    watch: {
      async getUserAttributes() {
        this.showNist = await this.$store.dispatch('getNistCompliance');
      }
    },
    async created() {
        if(window) {
          window.addEventListener('resize', this.eventResize);
          this.$store.dispatch('setBrowserWindow', window);
        }

        // True if user has a customerId assigned  but cognitoUser is no longer valid
        if(this.getCurrentCustomerId && !this.getCognitoUser) {
            this.$store.dispatch('refreshCognitoUser');
            this.$store.dispatch('initRailService');
            this.$store.dispatch('initDataExchangeAgreementService');
            this.$store.dispatch('initDataShareService');
        }

        // Amplify listen for sing in and out events to handle VueX store
        Hub.listen("auth", ({ payload: { event, data } }) => {
            switch(event) {
                case 'signIn':
                    // Initialize user management module
                    this.$store.dispatch('initUserSession');
                    this.$store.dispatch('initRailService');
                    this.$store.dispatch('initDataExchangeAgreementService');
                    this.$store.dispatch('initDataShareService');
                    break;
                case 'signOut':
                    // Remove VueX store in local storage
                    localStorage.removeItem('loram.technologies.digitaltwin');
                    break;
            }
        });
    },
    methods: {
        eventResize(event) {
            this.$store.dispatch('setBrowserWindow', event.target);
        },
    }
};
</script>

<style src="@/styles/main.scss" lang="scss"></style>
<style src="@dash/styles/dashboard.scss" lang="scss"></style>
